import React, { Fragment, useContext, useEffect } from 'react';
import PageContext from '../../config/pageContext';
import AuthContext from '../../context/auth/authContext';
import VisState from '../../context/vis/VisState';
import VisDash from '../vis/VisDash';

const Vis = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.handleSetPageSidebar(false);
    pageContext.handleSetPageHeader(true);
    // eslint-disable-next-line
    return () => {
      pageContext.handleSetPageSidebar(true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <VisState>
        <VisDash user={user} />
      </VisState>
    </Fragment>
  );
};

export default Vis;
