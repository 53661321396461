import React, { Fragment, useContext, useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import VisContext from "../../context/vis/visContext";
import VisThemes from "./VisThemes";
import VisIndicators from "./VisIndicators";
import VisChartMap from "./VisChartMap";
import VisChartMapPlus from "./VisChartMapPlus";
import VisChartGrouping2 from "./VisChartGrouping2";
import canadian from "../../assets/img/logos/Canadian_Logo_white.png";
import menasa from "../../assets/img/logos/Dubai_WCCD_white.png";
import dubaiCouncilLogo from "../../assets/img/logos/Dubai_Council_Logo.png";
import wccd from "../../assets/img/logos/WCCD_Horizontal_White.png";
import { renderThemeColour, renderThemeIcon } from "../../utils/renderIcon";
import Select from "react-select";
import VisFilter from "./new/VisFilter";

const VisDash = ({ user }) => {
    const visContext = useContext(VisContext);
    const [logos, setLogos] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState();
    const [visualisationType, setVisualisationType] = useState();
    const [iso, setIso] = useState(2);
    const [isoOptions, setIsoOptions] = useState([]);
    const [selectedCity, setSelectedCity] = useState();
    const [selectedCityYears, setSelectedCityYears] = useState([]);
    const [filteredYearOverYearData, setFilteredYearOverYearData] = useState([]);
    const [userLoaded, setUserLoaded] = useState(false);

    const [grouping, setGrouping] = useState();
    const [groupData, setGroupData] = useState({});
    const [filteredCities, setFilteredCities] = useState([]);

    const {
      getThemes,
      getIndicators,
      getIndicatorsByTheme,
      getCities,
      setCities,
      setIndicator,
      selectGrouping,
      groupSelection,
      themes,
      themeIndicators,
      indicatorCities,
      getYearOverYearData,
      yearOverYearData,
      currIndicator,
      loading,
    } = visContext;

    const visualisationTypeOptions = () => {
      let options = [];
      if (user.comparison_vis_enabled || user.role === 1) {
        options.push({ value: 1, label: "City Comparison" });
      }

      if (user.yoy_vis_enabled || user.role === 1) {
        options.push({ value: 2, label: "Year Over Year Trends" });
      }

      return options;
    };

    const isSingleCity = ![1, 7].includes(user.role);

    useEffect(() => {
      getThemes();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (!userLoaded && user.id) {
        setUserLoaded(true);

        if (!user.yoy_vis_enabled && user.comparison_vis_enabled && user.role !== 1) {
          setVisualisationType(1);
        }

        const options = [{ value: 2, label: "ISO 37120" }];
        for (let i = 3; i <= 4; i++) {
          if (user.role === 1 || (user.vis_additional_iso || []).includes(i)) {
            options.push({
              value: i, label: "ISO 3712" + (i === 3 ? "2" : "3"),
            });
          }
        }
        setIsoOptions(options);
      }

      // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
      if (user && iso === 2) {
        switch (user.vis) {
          case 1:
            setLogos([{ image: wccd, width: 300 }]);
            break;
          case 2:
            setLogos([
              { image: canadian, width: 150 },
              { image: wccd, width: 300 },
            ]);
            break;
          case 3:
            setLogos([
              { image: menasa, width: 100 },
              { image: wccd, width: 300 },
              { image: dubaiCouncilLogo, width: 150 },
            ]);
            break;
          default:
            break;
        }
      } else {
        setLogos([{ image: wccd, width: 300 }]);
      }

      // eslint-disable-next-line
    }, [iso]);

    useEffect(() => {
      if (selectedCityYears && selectedCityYears.length) {
        const filtered = yearOverYearData.reduce((acc, value) => {
          if (selectedCityYears.includes(value.year)) {
            acc.push(value);
          }
          return acc;
        }, []);
        setFilteredYearOverYearData(filtered);
      } else {
        setFilteredYearOverYearData([]);
      }
    }, [selectedCityYears, yearOverYearData]);

    const getCityYears = () => {
      const years = [];
      if (yearOverYearData && yearOverYearData.length > 0) {
        yearOverYearData.forEach((dataEntry) => {
          years.push({
            value: dataEntry.year,
            label: dataEntry.year.toString(),
          });
        });
      }
      return years;
    };

    const setVisTypeSelection = (value) => {
      setVisualisationType(value);
      setSelectedCity();
      setSelectedCityYears();
      setFilteredYearOverYearData([]);
      setCities(null);

      setIndicator({
        indicator_id: 0,
        indicator_name: "",
        indicator_vtype: "",
        iso_section: null,
        theme: null,
      });

      if (value === 1) {
        getIndicators(selectedTheme.id, iso);
      } else if (value === 2) {
        getIndicatorsByTheme(selectedTheme.id, iso);
      }
    };

    useEffect(() => {
      if (visualisationType) {
        setVisTypeSelection(visualisationType);
      }
      // eslint-disable-next-line
    }, [selectedTheme, iso]);

    useEffect(() => {
      if (visualisationType === 2) {
        if (isSingleCity && user.cities) {
          getYearOverYearData(currIndicator.indicator_id, user.cities[0], currIndicator.question_id);
        } else if (selectedCity?.id) {
          getYearOverYearData(currIndicator.indicator_id, selectedCity.id, currIndicator.question_id);
        }
      }

      // eslint-disable-next-line
    }, [currIndicator]);

    useEffect(() => {
      if (visualisationType === 2
        && selectedCity && indicatorCities
        && !indicatorCities.some(({ id }) => selectedCity.id === id)) {
        setSelectedCity();
        setSelectedCityYears([]);
      }

      // eslint-disable-next-line
    }, [indicatorCities]);

    const logosRow =
      <div
        className="d-flex flex-row justify-content-around flex-wrap"
        style={{ maxWidth: "80%", margin: "0 auto" }}>
        {logos.map(({ image, width }, index) =>
          <div style={{ margin: "auto 0" }} key={index}>
            <img src={image} alt="" style={{ width, margin: "10px 0" }}/>
          </div>)}
      </div>;

    const getSelectedIsoName = () =>
      (isoOptions.find(({ value }) => value === iso) || {}).label || "ISO 37120";

    // unauthorized user
    if (user && user.vis <= 0) return <Redirect to="/landing"/>;

    return (
      <Fragment>
        <div className="landing-cover">
          <div
            className="landing-cover-image"
            style={{
              backgroundColor: "#191839",
            }}
          />
          <div className="landing-cover-bg"/>
        </div>
        <VisThemes
          themes={themes}
          setSelectedTheme={setSelectedTheme}
          filteredCities={filteredCities}
          setIndicator={setIndicator}
        />

        <div className="row b-t-1-ed p-t-40 p-b-25">
          {selectedTheme && selectedTheme.id !== 0 ? (
            <Fragment>
              <div className="col-lg-2 col-md-3">
                {isoOptions.length > 1 && (
                  <div className="text-left text-black bold p-b-20">
                    <div className="text-white">Select ISO:</div>
                    <Select
                      defaultValue={{ value: 2, label: "ISO 37120" }}
                      onChange={(value) => setIso(value.value)}
                      options={isoOptions}
                      name="iso"
                    />
                  </div>
                )}
                {(user.yoy_vis_enabled || user.comparison_vis_enabled || user.role === 1) && (
                  <div className="text-left text-black bold p-b-20">
                    <div className="text-white">Select visualisation type:</div>
                    <Select
                      onChange={(value) => setVisTypeSelection(value.value)}
                      options={visualisationTypeOptions()}
                      name="visualisationType"
                    />
                  </div>
                )}
                {themeIndicators.length && visualisationType && iso ? (
                  <div>
                    <div>
                      <VisIndicators
                        theme={selectedTheme}
                        themeIndicators={themeIndicators}
                        getCities={getCities}
                        setIndicator={setIndicator}
                        currIndicator={currIndicator}
                        filteredCities={filteredCities}
                        selectGrouping={selectGrouping}
                        setGrouping={setGrouping}
                        groupSelection={groupSelection}
                        setCities={setCities}
                        user={user}
                      />
                    </div>
                    {currIndicator.indicator_id && indicatorCities && visualisationType === 1 ? (
                      <>
                        {(iso === 2 && (user.vis === 2 || user.vis === 3)) && (
                          <div className="p-b-15 text-white">
                            <input
                              type="checkbox"
                              onChange={() => {
                                if (grouping === "global_context") {
                                  setGrouping(null);
                                } else {
                                  setGrouping("global_context");
                                }

                                setFilteredCities(indicatorCities);
                                setGroupData({});
                              }}
                              checked={grouping === "global_context"}
                            />
                            {user.vis === 2 ? " View Canadian Cities in a Global Context"
                              : " View MENASA Cities in a Global Context"}
                          </div>
                        )}
                        {grouping !== "global_context" && (
                          <VisFilter
                            noSecondary={!(user.role === 1) && !user.multiple_filters_vis_enabled}
                            cities={indicatorCities}
                            onSelectCities={(cities, grouping, groupData) => {
                              setFilteredCities(cities);
                              setGrouping(grouping);
                              setGroupData(groupData);
                            }}
                          />
                        )}
                      </>
                    ) : indicatorCities && indicatorCities.length && visualisationType === 2 ? (
                      <div className="text-left text-black bold p-b-5">
                        {!isSingleCity && (
                          <Fragment>
                            <div className="text-white">Select a city:</div>
                            <Select
                              value={selectedCity || ""}
                              onChange={(value) => {
                                setSelectedCity(value);
                                setSelectedCityYears([]);
                                getYearOverYearData(currIndicator.indicator_id, value.id, currIndicator.question_id);
                              }}
                              options={indicatorCities}
                              name="cityOptions"
                              isSearchable
                            />
                          </Fragment>
                        )}
                        {(selectedCity || isSingleCity) && (
                          <div className="p-t-20">
                            <div className="text-white">Select year(s):</div>
                            <Select
                              value={(selectedCityYears || []).map((year) => ({
                                value: year,
                                label: String(year),
                              }))}
                              onChange={(options) => {
                                setSelectedCityYears((options || []).map(({ value }) => value));
                              }}
                              options={getCityYears()}
                              name="yearOptions"
                              isMulti
                            />
                          </div>
                        )}
                      </div>
                    ) : ""}
                  </div>
                ) : ""}
              </div>
              <div
                className={themeIndicators.length ? "col-lg-10 col-md-7" : "col"}>
                {(visualisationType === 1 && currIndicator.indicator_id && grouping === "city" && filteredCities?.length) ? (
                  <VisChartMap
                    isoName={getSelectedIsoName()}
                    visualisationType={visualisationType}
                    filteredCities={(filteredCities || []).map(c => ({ ...c }))}
                    indicatorCities={indicatorCities}
                    currIndicator={currIndicator}
                    loading={loading}
                    user={user}
                  />
                ) : visualisationType === 1 && currIndicator.indicator_id && grouping === "global_context" ? (
                  <VisChartMapPlus
                    isoName={getSelectedIsoName()}
                    filteredCities={(filteredCities || []).map(c => ({ ...c }))}
                    indicatorCities={indicatorCities}
                    currIndicator={currIndicator}
                    loading={loading}
                    user={user}
                  />
                ) : visualisationType === 1 && currIndicator.indicator_id && Object.keys(groupData || {}).length ? (
                  <VisChartGrouping2
                    isoName={getSelectedIsoName()}
                    filteredCities={(filteredCities || []).map(c => ({ ...c }))}
                    indicatorCities={indicatorCities}
                    currIndicator={currIndicator}
                    groupData={groupData}
                    loading={loading}
                    user={user}
                  />
                ) : visualisationType === 2 && (selectedCity || isSingleCity) && yearOverYearData && yearOverYearData.length > 0 ? (
                  <VisChartMap
                    isoName={getSelectedIsoName()}
                    visualisationType={visualisationType}
                    yearOverYearData={filteredYearOverYearData.length ? filteredYearOverYearData : yearOverYearData}
                    filteredCities={(filteredCities || []).map(c => ({ ...c }))}
                    indicatorCities={indicatorCities}
                    currIndicator={currIndicator}
                    loading={loading}
                    user={user}
                  />
                ) : (
                  <Fragment>
                    <div className="col-lg-12 text-white text-center">
                      <div className="row">
                        <div className="col-lg-12  center-block">
                          <div className="height-400 text-center">
                            <div className="f-s-40 f-w-500  p-t-40">
                            <span
                              className="text-uppercase"
                              style={{
                                color: renderThemeColour(selectedTheme.name),
                              }}
                            >
                              <i
                                className={`fa fa-${renderThemeIcon(
                                  selectedTheme.name,
                                )}`}
                                style={{
                                  color: renderThemeColour(
                                    selectedTheme.name,
                                  ),
                                }}
                              />{" "}
                              {selectedTheme.name}
                            </span>
                            </div>
                            <div className="f-s-30 f-w-500  p-t-40">
                            <span
                              style={{
                                color: "#fff",
                              }}
                            >
                              {currIndicator.indicator_name}
                            </span>
                            </div>
                            <div className="f-s-20 f-w-300 p-t-40 italic">
                              {!themeIndicators.length ?
                                "THIS DATA WILL BE VISUALIZED AS IT BECOMES AVAILABLE"
                                : !indicatorCities || !indicatorCities.length || !currIndicator.indicator_id
                                  ? "PLEASE SELECT AN INDICATOR FROM THE LEFT TO CONTINUE"
                                  : (visualisationType === 2 ? "PLEASE SELECT A CITY FROM THE LEFT TO CONTINUE"
                                    : "PLEASE SELECT A CITY OR PEER GROUP FROM THE LEFT TO CONTINUE")}
                            </div>
                          </div>
                        </div>
                      </div>
                      {logosRow}
                    </div>
                  </Fragment>
                )}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="col-lg-12 text-white text-center">
                <div className="row">
                  <div className="col-lg-12  center-block">
                    <div className="height-400 text-center">
                      <div className="f-s-40 f-w-500  p-t-40">
                        WELCOME TO THE VISUALIZATION PORTAL
                      </div>
                      <div className="f-s-20 f-w-300 p-t-40 italic">
                        PLEASE CHOOSE A THEME FROM THE TOP TO BEGIN
                      </div>
                    </div>
                  </div>
                </div>
                {logosRow}
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
;

export default withRouter(VisDash);
