import React, { useContext, useRef, useEffect } from 'react';
import ContinentContext from '../../context/continent/continentContext';

const ContinentFilter = () => {
  const continentContext = useContext(ContinentContext);
  const text = useRef('');
  const { filterContinents, clearFilter, filtered } = continentContext;

  useEffect(() => {
    if (filtered === null) {
      text.current.value = '';
    }
  });

  const onChange = e => {
    if (text.current.value !== '') {
      filterContinents(e.target.value);
    } else {
      clearFilter();
    }
  };

  return (
    <form>
      <input
        ref={text}
        type='text'
        placeholder='Filter continents...'
        onChange={onChange}
      />
    </form>
  );
};

export default ContinentFilter;
