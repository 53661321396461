import React, { useState } from 'react';
import {
  questionTypes,
  auditQuestionValueTypes
} from "../../utils/constants";

const getHeading = (qtype) => {
  return questionTypes.find(type => type.value === parseInt(qtype)).text;
};

const InputGroup = (
  {
    type,
    label,
    fieldName,
    question,
    setQuestion,
    setAlert,
    options = [], // [{text: '', value: ''}]
    parseJson = false,
    onDeleteQuestion, // to be ignored
    ...rest
  }
) => {
  let element;
  switch (type) {
    case "number":
      element = <input
        className='form-control'
        style={{ maxWidth: 100 }}
        type="number"
        value={question[fieldName] || ''}
        onChange={(e) => {
          setQuestion({
            ...question,
            [`${fieldName}`]: e.target.value || null,
          });
        }}
        {...rest}
      />;
      break;
    case "textarea":
      if (parseJson) {
        element = <textarea
          className='form-control'
          rows={5}
          defaultValue={question[fieldName] ? JSON.stringify(question[fieldName]) : ''}
          onBlur={(e) => {
            let { value } = e.target;
            try {
              value = JSON.parse(value);
            } catch (e) {
              setAlert("Invalid JSON string in the \"" + label + "\" field:" + e.message, "danger", 10000);
              return;
            }

            setQuestion({
              ...question,
              [`${fieldName}`]: value || null,
            });
          }}
          {...rest}
        />;
      } else {
        element = <textarea
          className='form-control'
          rows={3}
          value={question[fieldName] || ''}
          onChange={(e) => {
            setQuestion({
              ...question,
              [`${fieldName}`]: e.target.value || null,
            });
          }}
          {...rest}
        />;
      }
      break;
    case "dropdown":
      element = <select
        className='form-control'
        value={question[fieldName] || ''}
        onChange={(e) => {
          setQuestion({
            ...question,
            [`${fieldName}`]: e.target.value || null,
          });
        }}
        {...rest}
      >
        {options.map(({ value, text }, i) =>
          <option key={i} value={value}>
            {text}
          </option>
        )}
      </select>;
      break;
    case "checkbox":
      element = <input
        style={{ maxWidth: 20 }}
        className='form-control'
        type="checkbox"
        checked={question[fieldName] || false}
        onChange={() => {
          setQuestion({
            ...question,
            [`${fieldName}`]: !question[fieldName],
          });
        }}
        {...rest}
      />;
      break;
    default:
      element = <input
        className='form-control'
        type={type}
        value={question[fieldName] || ''}
        onChange={(e) => {
          setQuestion({
            ...question,
            [`${fieldName}`]: e.target.value || null,
          });
        }}
        {...rest}
      />;
  }

  return <div className='input-group m-b-15 width-600'>
    <span className='m-t-10 width-150'>{label + ":"}</span>
    {element}
  </div>;
};

const IsoQuestionItem = (props) => {
  const { question, onDeleteQuestion } = props;
  const [showMore, setShowMore] = useState(false);

  return (
    <div>
      <hr />
      <span className='font-weight-bold m-t-10 width-150 text-black'>
          {getHeading(question.qtype)}
        <span className='font-weight-lighter f-s-10'> ({question.id})</span>
      </span>

      <InputGroup
        type="dropdown"
        fieldName="qtype"
        label="Question type"
        options={questionTypes}
        {...props}
      />

      <InputGroup
        type="textarea"
        fieldName="code"
        label="Code"
        rows={1}
        {...props}
      />

      <InputGroup
        type="textarea"
        fieldName="text"
        label="Text"
        {...props}
      />

      <InputGroup
        type="textarea"
        fieldName="requirements"
        label="Hover text (i)"
        {...props}
      />

      {question.qtype === 5 ? ( // Audit question only
        <InputGroup
          type="dropdown"
          options={[{ value: "", text: "--" }, ...auditQuestionValueTypes]}
          fieldName="vtype"
          label="Value type"
          {...props}
        />
      ) : (
        <InputGroup
          type="textarea"
          rows={1}
          fieldName="vtype"
          label="Value type"
          {...props}
        />
      )}

      <InputGroup
        type="number"
        fieldName="sort_order"
        label="Sort order"
        {...props}
      />

      <InputGroup
        type="checkbox"
        fieldName="is_visible"
        label="Is visible"
        {...props}
      />

      {showMore && (
        <>
          <InputGroup
            type="textarea"
            fieldName="info"
            label="Info"
            {...props}
          />

          <InputGroup
            type="textarea"
            fieldName="other_info"
            label="Other info"
            {...props}
          />

          <InputGroup
            type="number"
            fieldName="max_value"
            label="Max value"
            {...props}
          />

          <InputGroup
            type="textarea"
            fieldName="max_text"
            label="Max value text"
            {...props}
          />

          <InputGroup
            type="number"
            fieldName="min_value"
            label="Min value"
            {...props}
          />

          <InputGroup
            type="textarea"
            fieldName="min_text"
            label="Min value text"
            {...props}
          />

          <InputGroup
            type="number"
            fieldName="average"
            label="Average"
            {...props}
          />

          <InputGroup
            type="number"
            fieldName="stdev"
            label="stdev"
            {...props}
          />

          <InputGroup
            type="number"
            fieldName="parent_id"
            label="Parent id"
            {...props}
          />


          <InputGroup
            type="textarea"
            rows={1}
            fieldName="section"
            label="Section"
            {...props}
          />

          <InputGroup
            type="checkbox"
            fieldName="has_aggregate"
            label="Has aggregate"
            {...props}
          />

          <InputGroup
            type="checkbox"
            fieldName="is_aggregate"
            label="Is aggregate"
            {...props}
          />

          <InputGroup
            type="checkbox"
            fieldName="has_table_data"
            label="Has table data"
            {...props}
          />

          <InputGroup
            type="textarea"
            parseJson={true}
            fieldName="table_columns"
            label="Table columns (JSON)"
            rows={5}
            {...props}
          />

          <InputGroup
            type="textarea"
            fieldName="vis_chart_note"
            label="Visualization graph disclaimer"
            rows={3}
            {...props}
          />
        </>
      )}

      <div className="width-600">
        <button
          className='mb-4 btn btn-outline-dark'
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Hide extra fields" : "Show more fields"}
        </button>
        <button
          className='mb-4 btn btn-danger float-right'
          onClick={async () => {
            onDeleteQuestion(question);
          }}
        >
          Delete this question
        </button>
      </div>
    </div>
  );
};

export default IsoQuestionItem;
