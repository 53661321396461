import {
  ACCEPT_DISCLAIMER,
  AUTH_ERROR,
  CLEAR_ERRORS,
  GET_CITIES,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  USER_LOADED
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_CITIES:
      return {
        ...state,
        cities: action.payload
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: !!action.payload?.email,
        loading: false,
        user: action.payload,
        accepted_disclaimer: action.payload?.accepted_disclaimer
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        accepted_disclaimer: false,
        loading: false
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
    case REQUEST_SUCCESS:
    case REQUEST_FAIL:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: action.payload
      };
    case ACCEPT_DISCLAIMER:
      return {
        ...state,
        accepted_disclaimer: true
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
