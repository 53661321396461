import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const CityWorkbookFilter = ({ filterCities, clearFilter, filtered }) => {
  const text = useRef('');

  useEffect(() => {
    if (filtered === null) {
      text.current.value = '';
    }
  });

  const onChange = e => {
    if (text.current.value !== '') {
      filterCities(e.target.value);
    } else {
      clearFilter();
    }
  };

  return (
    <div
      className='mb-3 mr-1ba width-full'
      style={{ maxWidth: 500 }}
    >
      <form>
        <div className='input-group input-group-lg'>
          <input
            style={{
              // form-control-lg doesn't seem to inherit form-control border fixes
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }}
            className='form-control-lg b-1 flex-stretch'
            ref={text}
            type='text'
            placeholder='Type here to filter...'
            onChange={onChange}
          />

          <div className='input-group-append'>
            <button
              className='btn btn-default btn-lg b-0'
              onClick={() => clearFilter()}
            >
              <i className='fa fa-times'/>
            </button>
            <button className='btn btn-default btn-lg b-0'>
              <i className='fa fa-search'/>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
CityWorkbookFilter.propTypes = {
  filterCities: PropTypes.func,
  clearFilter: PropTypes.func,
  loading: PropTypes.bool
};
export default CityWorkbookFilter;
