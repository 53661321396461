import React, { useReducer } from 'react';
import axios from 'axios';
import { baseUrl } from '../../config/const';
import IsoContext from './isoContext';
import isoReducer from './isoReducer';
import { setAuthToken } from '../../utils/generalHelpers';
import renderISOAdminNav from '../../utils/renderISOAdminNav';
import {
  CREATE_QUESTION,
  DELETE_QUESTION,
  GET_CHILD_INDICATORS,
  GET_INDICATOR,
  GET_INDICATORS,
  GET_ISOS,
  GET_TYPES,
  ISO_ERROR,
  SET_ISO,
  UPDATE_INDICATOR,
  UPDATE_QUESTION,
} from '../types';

const IsoState = (props) => {
  const initialState = {
    isos: null,
    indicators: null,
    curr_iso: null,
    curr_ind: null,
    childIndicators: [], // child indicator for the curr_ind
    types: null,
    error: null,
  };

  const [state, dispatch] = useReducer(isoReducer, initialState);
  // Get a list of ISOs
  const getIsos = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/iso/iso/`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_ISOS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ISO_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Get a list of Indicators per Iso
  const getIsoIndicators = async (isoId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/iso/iso-theme/?iso=${isoId}`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_INDICATORS,
        payload: renderISOAdminNav(res.data),
      });
    } catch (err) {
      dispatch({
        type: ISO_ERROR,
        payload: err.response.data,
      });
    }
  };

  const setIso = async (isoId) => {
    dispatch({
      type: SET_ISO,
      payload: isoId,
    });
  };

  const getIndicator = async (indicatorId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/iso/iso-indicator-question/${indicatorId}`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_INDICATOR,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ISO_ERROR,
        payload: err.response.data,
      });
    }

    // query any child indicators
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/indicator-children/?parent_id=${indicatorId}`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_CHILD_INDICATORS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ISO_ERROR,
        payload: err.response.data,
      });
    }
  };

  const getUnitList = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/iso/indicatorvaluetype/`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_TYPES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ISO_ERROR,
        payload: err.response.data,
      });
    }
  };

  /**
   * Update (PATCH) an indicator object
   * @param indicator The indicator data object
   * @returns {Promise<boolean>} True if saved successfully
   */
  const updateIndicator = async (indicator) => {
    try {
      const res = await axios.patch(
        `${baseUrl}/api/iso/indicator/${indicator.id}/`,
        indicator,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: UPDATE_INDICATOR,
        payload: res.data,
      });
      return true;
    } catch (err) {
      dispatch({
        type: ISO_ERROR,
        payload: err.response.data,
      });
      return false;
    }
  };

  const updateQuestion = async (question) => {
    try {
      const res = await axios.patch(
        `${baseUrl}/api/iso/question/${question.id}/`,
        question,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: UPDATE_QUESTION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ISO_ERROR,
        payload: err.response.data,
      });
    }
  };

  /**
   * Create a new question
   * @param question The question data
   * @returns {Promise<boolean>} True if successful
   */
  const createQuestion = async (question) => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/iso/question/`,
        question,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: CREATE_QUESTION,
        payload: res.data,
      });
      return true;
    } catch (err) {
      dispatch({
        type: ISO_ERROR,
        payload: err.response.data,
      });
      return false;
    }
  };

  /**
   * Delete a question
   * @param id The question id
   * @returns {Promise<boolean>} True if successful
   */
  const deleteQuestion = async (id) => {
    try {
      await axios.delete(
        `${baseUrl}/api/iso/question/${id}/`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: DELETE_QUESTION,
        payload: { id },
      });
      return true;
    } catch (err) {
      dispatch({
        type: ISO_ERROR,
        payload: err.response.data,
      });
      return false;
    }
  };

  return (
    <IsoContext.Provider
      value={{
        isos: state.isos,
        indicators: state.indicators,
        curr_iso: state.curr_iso,
        curr_ind: state.curr_ind,
        childIndicators: state.childIndicators,
        types: state.types,
        error: state.error,
        getIsos,
        setIso,
        getIsoIndicators,
        getIndicator,
        getUnitList,
        updateIndicator,
        updateQuestion,
        createQuestion,
        deleteQuestion
      }}
    >
      {props.children}
    </IsoContext.Provider>
  );
};

export default IsoState;
