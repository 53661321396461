import React, { useReducer } from 'react';
import axios from 'axios';
import ContinentContext from './continentContext';
import continentReducer from './continentReducer';
import { setAuthToken } from '../../utils/generalHelpers';
import {
  GET_CONTINENTS,
  ADD_CONTINENT,
  DELETE_CONTINENT,
  SET_CURRENT,
  UPDATE_CONTINENT,
  FILTER_CONTINENTS,
  CLEAR_CONTINENTS,
  CLEAR_FILTER,
  CLEAR_CURRENT,
  CONTINENT_ERROR
} from '../types';

const ContinentState = props => {
  const initialState = {
    continents: null,
    current: null,
    filtered: null,
    error: null
  };

  const [state, dispatch] = useReducer(continentReducer, initialState);

  // Get Continents
  const getContinents = async () => {
    try {
      const res = await axios.get(
        '/api/location/continent/',
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_CONTINENTS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: CONTINENT_ERROR,
        payload: err.response.data
      });
    }
  };

  // Add Continent
  const addContinent = async continent => {
    try {
      const res = await axios.post(
        '/api/location/continent/',
        continent,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: ADD_CONTINENT,
        payload: res.data
      });
    } catch (err) {
      dispatch({ type: CONTINENT_ERROR, payload: err.response.data });
    }
  };

  // Delete Continent
  const deleteContinent = async id => {
    try {
      await axios.delete(
        `/api/location/continent/${id}/`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: DELETE_CONTINENT,
        payload: id
      });
    } catch (err) {
      dispatch({ type: CONTINENT_ERROR, payload: err.response.data });
    }
  };

  // Update Continent
  const updateContinent = async continent => {
    try {
      const res = await axios.put(
        `/api/location/continent/${continent.id}/`,
        continent,
        setAuthToken(localStorage.token)
      );
      dispatch({ type: UPDATE_CONTINENT, payload: res.data });
    } catch (err) {
      dispatch({ type: CONTINENT_ERROR, payload: err.response.data });
    }
  };

  // Clear Continents
  const clearContinents = () => {
    dispatch({ type: CLEAR_CONTINENTS });
  };

  // Set Current Continent
  const setCurrent = continent => {
    dispatch({ type: SET_CURRENT, payload: continent });
  };

  // Clear Curent Continent
  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  // Filter Continent
  const filterContinents = text => {
    dispatch({ type: FILTER_CONTINENTS, payload: text });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER });
  };

  return (
    <ContinentContext.Provider
      value={{
        continents: state.continents,
        current: state.current,
        filtered: state.filtered,
        error: state.error,
        getContinents,
        addContinent,
        deleteContinent,
        setCurrent,
        clearCurrent,
        updateContinent,
        filterContinents,
        clearFilter,
        clearContinents
      }}
    >
      {props.children}
    </ContinentContext.Provider>
  );
};

export default ContinentState;
