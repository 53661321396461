import React, { useContext, useEffect, Fragment } from 'react';
import PageContext from '../../config/pageContext';
import AuthContext from '../../context/auth/authContext';
import CityWorkbookState from '../../context/cityworkbook/CityWorkbookState';
import CityWorkbookDash from '../cityworkbook/CityWorkbookDash';

const CityWorkbooks = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);
  useEffect(() => {
    authContext.loadUser();
    pageContext.handleSetPageSidebar(false);
    // eslint-disable-next-line
    return () => {
      pageContext.handleSetPageSidebar(true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <CityWorkbookState>
        <CityWorkbookDash />
      </CityWorkbookState>
    </Fragment>
  );
};

export default CityWorkbooks;
