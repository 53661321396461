export const GET_CONTINENTS = 'GET_CONTINENTS';
export const ADD_CONTINENT = 'ADD_CONTINENT';
export const DELETE_CONTINENT = 'DELETE_CONTINENT';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const UPDATE_CONTINENT = 'UPDATE_CONTINENT';
export const FILTER_CONTINENTS = 'FILTER_CONTINENTS';
export const CLEAR_CONTINENTS = 'CLEAR_CONTINENTS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CONTINENT_ERROR = 'CONTINENT_ERROR';
export const SET_FILTER = 'SET_FILTER';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_ALERT = 'SET_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ACCEPT_DISCLAIMER = 'ACCEPT_DISCLAIMER';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAIL = 'REQUEST_FAIL';

export const GET_USERS = 'GET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const USER_ERROR = 'USER_ERROR';

export const GET_EXPORT = 'GET_EXPORT';

export const GET_WORKBOOKS = 'GET_WORKBOOKS';
export const ADD_WORKBOOK = 'ADD_WORKBOOK';
export const DELETE_WORKBOOK = 'DELETE_WORKBOOK';
export const SET_WORKBOOK = 'SET_WORKBOOK';
export const UPDATE_WORKBOOK = 'UPDATE_WORKBOOK';
export const FILTER_WORKBOOKS = 'FILTER_WORKBOOKS';
export const CLEAR_WORKBOOK = 'CLEAR_WORKBOOK';
export const WORKBOOK_ERROR = 'WORKBOOK_ERROR';
export const CLEAR_WORKBOOKS = 'CLEAR_WORKBOOKS';
export const GET_ISOS = 'GET_ISOS';
export const ADD_CERT = 'ADD_CERT';
export const SET_MODAL = 'SET_MODAL';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const COUNTRIES_ERROR = 'COUNTRIES_ERROR';
export const GET_CITIES = 'GET_CITIES';
export const FIND_CITY = 'FIND_CITY';
export const ADD_CITY = 'ADD_CITY';
export const FIND_REGION = 'FIND_REGION';
export const GET_CITY = 'GET_CITY';
export const CITY_ERROR = 'CITY_ERROR';

export const GET_ISOTHEME = 'GET_ISOTHEME';
export const ISOTHEME_ERROR = 'ISOTHEME_ERROR';

export const GET_THEME = 'GET_THEME';
export const THEME_ERROR = 'THEME_ERROR';

export const GET_INDICATOR = 'GET_INDICATOR';
export const INDICATOR_ERROR = 'INDICATOR_ERROR';
export const SET_INDICATORS_PARAMS = 'SET_INDICATORS_PARAMS';
export const GET_HEADER_PARAMS = 'GET_HEADER_PARAMS';
export const GET_CHILD_INDICATORS = 'GET_CHILD_INDICATORS';

export const GET_STATUS = 'GET_STATUS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const STATUS_ERROR = 'STATUS_ERROR';
export const SET_CURRENT_STATUS = 'SET_CURRENT_STATUS';

export const GET_VALUE_TYPE = 'GET_VALUE_TYPE';
export const UPDATE_VALUE = 'UPDATE_VALUE';
export const VALUE_ERROR = 'VALUE_ERROR';

export const SET_CURRENT_NUM = 'SET_CURRENT_NUM';
export const SET_CURRENT_DEN = 'SET_CURRENT_DEN';
export const SET_CALC_NUM = 'SET_CALC_NUM';
export const SET_CALC_DEN = 'SET_CALC_DEN';
export const SET_CURRENT_MAIN = 'SET_CURRENT_MAIN';
export const SET_VALUE_OBJECT = 'SET_VALUE_OBJECT';

export const SET_CURRENT_YESNO = 'SET_CURRENT_YESNO';
export const SET_CURRENT_YESNOP = 'SET_CURRENT_YESNOP';

export const SET_LOADING = 'SET_LOADING';
export const CLEAR_RESULTS = 'CLEAR_RESULTS';

export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT';
export const SET_UPLOADS = 'SET_UPLOADS';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';

export const GET_THEMES = 'GET_THEMES';
export const VIZ_ERROR = 'VIZ_ERROR';
export const GET_INDICATORS = 'GET_INDICATORS';
export const GET_PEER_INDICATORS = 'GET_PEER_INDICATORS';

export const SET_INDICATOR = 'SET_INDICATOR';
export const SET_CITIES = 'SET_CITIES';
export const SET_GROUPING = 'SET_GROUPING';
export const SET_GROUP_DATA = 'SET_GROUP_DATA';
export const SET_GROUP_SELECTION = 'SET_GROUP_SELECTION';
export const UPDATE_PARENT = 'UPDATE_PARENT';
export const GET_YEAR_OVER_YEAR = "GET_YEAR_OVER_YEAR";

export const ISO_ERROR = 'ISO_ERROR';
export const SET_ISO = 'SET_ISO';
export const GET_TYPES = 'GET_TYPES';
export const UPDATE_INDICATOR = 'UPDATE_INDICATOR';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const CREATE_QUESTION = 'CREATE_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
