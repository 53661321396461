import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import {
  getStatusColor,
  getStatusString,
  getSwitcherColor,
  isDisabled,
  isHidden,
  isSwitchDisabled,
  yearsList,
} from "../../../utils/generalHelpers";

const IndicatorMainValueStatus = (props) => {
  const {
    indicatorstatus,
    setCurrentMainValue,
    currentMainValue,
    workbookId,
    updateIndicatorStatus,
    updateValue,
    valueObject,
    user,
  } = props;

  let main_value = valueObject;
  if (currentMainValue) {
    main_value = currentMainValue;
  }
  const [mainState, setMainState] = useState(main_value);
  if (main_value.year !== mainState.year) {
    setMainState(main_value);
  }

  const [statusState, setStatusState] = useState(indicatorstatus);
  const [userState, setUserState] = useState(user);

  const { id, status, is_visualize, is_forced_tally } = statusState;
  const { role } = userState;
  const [alertState, setAlertState] = useState(false);
  const [dropdownStatus, setStatus] = useState(false);
  const toggleStatus = () => setStatus(!dropdownStatus);
  const [dropdownYear, setYear] = useState(false);
  const toggleYear = () => setYear(!dropdownYear);

  const onClickStatus = (role, curStat, newStatId) => {
    if (role === 4 && curStat === 1) {
      setAlertState(!alertState);
    } else {
      setStatusState({
        ...statusState,
        status: newStatId,
      });
      updateIndicatorStatus(
        {
          ...statusState,
          status: newStatId,
        },
        workbookId,
      );
    }
  };

  const onChange = e => {
    const statVal = e.target.value === "1" ? 0 : 1;
    setStatusState({ ...statusState, status: statVal });
    updateIndicatorStatus({ ...statusState, status: statVal }, workbookId);
  };

  const onClickYear = (year) => {
    setCurrentMainValue({ ...currentMainValue, year: year });
    updateValue({ ...currentMainValue, year: year });
  };

  const years = yearsList();

  const roleId = user.role;

  useEffect(() => {
    setStatusState(indicatorstatus);
    setUserState(user);
    // eslint-disable-next-line
  }, [indicatorstatus, user]);
  return (
    <Fragment>
      <div className="input-group">
        <div
          className="text-inverse d-flex justify-content-start flex-wrap width-full"
          style={{ gap: "1rem" }}
        >
          <div className="d-flex flex-grow-1 justify-content-start flex-wrap align-items-center">
            <div className="btn-group m-r-10">
              <div
                className="input-group-prepend btn-group width-200 fa-pull-left">
                <button
                  className={`btn bg-${getStatusColor(status)}-transparent-8 b-0`}
                  id="caret"
                >
                  {getStatusString(status)}
                </button>
                <UncontrolledButtonDropdown
                  isOpen={dropdownStatus}
                  toggle={toggleStatus}
                >
                  <DropdownToggle
                    caret
                    color="default"
                    className={`fa fa-stream text-inverse`}
                    disabled={isHidden(role, status, -1)}
                  />
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => onClickStatus(role, status, 1)}
                      hidden={isHidden(role, status, 1)}
                    >
                      <span className="text-blue-darker">Back to Draft</span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => onClickStatus(role, status, 2)}
                      hidden={isHidden(role, status, 2)}
                    >
                      <span
                        className="text-yellow-darker">Ready for Review</span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => onClickStatus(role, status, 3)}
                      hidden={isHidden(role, status, 3)}
                    >
                    <span className="text-orange-darker">
                      Ready for Verification
                    </span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => onClickStatus(role, status, 4)}
                      hidden={isHidden(role, status, 4)}
                    >
                      <span className="text-success">Mark as Verified</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div
                className="input-group-append btn-group width-100 fa-pull-left">
                <button
                  className="btn btn-default"
                  disabled={isDisabled(status, roleId)}
                >
                  {currentMainValue ? currentMainValue.year : ""}
                </button>
                <UncontrolledButtonDropdown
                  isOpen={dropdownYear}
                  toggle={() => toggleYear(!dropdownYear)}
                >
                  <DropdownToggle
                    caret
                    color="default"
                    className="fa fa-calendar"
                    disabled={isDisabled(status, roleId)}
                  />
                  <DropdownMenu>
                    {years.map(year => (
                      <DropdownItem onClick={() => onClickYear(year)}
                                    key={year}>
                        {year}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
            </div>
            {role === 1 && (
              <>
                <div>
                  <input
                    type="checkbox"
                    style={{ verticalAlign: "middle" }}
                    onChange={() => {
                      setStatusState({
                        ...statusState,
                        is_visualize: !is_visualize,
                      });
                      updateIndicatorStatus({
                        ...statusState,
                        is_visualize: !is_visualize,
                      }, workbookId);
                    }}
                    checked={is_visualize}
                  />
                  <span style={{ verticalAlign: "middle" }}> Visualize this indicator?</span>
                </div>
                {status === 0 && (
                  <div>
                    <input
                      type="checkbox"
                      style={{ verticalAlign: "middle" }}
                      onChange={() => {
                        setStatusState({
                          ...statusState,
                          is_forced_tally: !is_forced_tally,
                        });
                        updateIndicatorStatus({
                          ...statusState,
                          is_forced_tally: !is_forced_tally,
                        }, workbookId);
                      }}
                      checked={is_forced_tally}
                    />
                    <span style={{ verticalAlign: "middle" }}> Force tally this indicator?</span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="d-flex ">
            <div className="m-r-5" style={{ margin: "auto 0" }}>
              <span style={{ verticalAlign: "middle" }}>
                Reporting on this Indicator?
              </span>
            </div>
            <div
              className={`switcher switcher-${getSwitcherColor(status)}`}
              style={{ margin: "auto 0" }}
            >
              <input
                type="checkbox"
                name={`switcher_checkbox_2_${id}`}
                id={`switcher_checkbox_2_${id}`}
                checked={status !== 0}
                value={status !== 0 ? 1 : 0}
                onChange={onChange}
                disabled={isSwitchDisabled(status, roleId)}
              />
              <label htmlFor={`switcher_checkbox_2_${id}`} className="f-s-12" />
            </div>
          </div>
        </div>
      </div>
      {alertState && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="warning"
          cancelBtnText="No, not yet"
          cancelBtnBsStyle="default"
          title="Are you sure this indicator is ready for review?"
          onConfirm={() => {
            setStatusState({
              ...statusState,
              status: 2,
            });
            updateIndicatorStatus(
              {
                ...statusState,
                status: 2,
              },
              workbookId,
            );
            setAlertState(!alertState);
          }}
          onCancel={() => setAlertState(!alertState)}
        >
          Please make sure you have included all relevant source documentation
          to support this indicator values.
        </SweetAlert>
      )}
    </Fragment>
  );
};
IndicatorMainValueStatus.propTypes = {
  indicatorstatus: PropTypes.object,
  user: PropTypes.object,
  workbookId: PropTypes.string,
  updateIndicatorStatus: PropTypes.func,
  updateValue: PropTypes.func,
  valueObject: PropTypes.object,
  currentMainValue: PropTypes.object,
  setCurrentMainValue: PropTypes.func,
};
export default IndicatorMainValueStatus;
