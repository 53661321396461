const renderAttachments = indicatorObject => {
  const items = indicatorObject;
  const attachmentsObject = [];
  const pattern = /\.([0-9a-z]+)(?:[?#]|$)/i;

  const renderType = type => {
    switch (type) {
      case 'msg':
        return 'fa fa-file-alt fa-2x';
      case 'xlsx':
        return 'fa fa-file-excel fa-2x';
      case 'doc':
      case 'docx':
        return 'fa fa-file-word fa-2x';
      case 'pdf':
        return 'fa fa-file-pdf fa-2x';
      default:
        return 'fa fa-file fa-2x';
    }
  };

  const renderFileName = filePath => unescape(
    filePath
      .split('?')[0]
      .split('/')
      .pop()
  );

  for (let item of items) {
    for (let attachment of item.value[0].uploads) {
      const filePath = attachment.upload;
      const type = (attachment.upload.match(pattern) || ["", ""])[1];

      const attachmentItem = {
        id: attachment.id,
        code: item.code,
        date: attachment.uploaded_at,
        file: renderFileName(filePath),
        download: filePath,
        type,
        icon: renderType(type)
      };

      attachmentsObject.push(attachmentItem);
    }
  }
  return attachmentsObject;
};

export default renderAttachments;
