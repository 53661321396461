import React, { useContext, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PageContext from '../../config/pageContext';
import IsoState from './../../context/iso/IsoState';
import AuthContext from '../../context/auth/authContext';
import IsoDash from '../iso/IsoDash';

const Iso = (props) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const pageContext = useContext(PageContext);

  const isoId = props.match.params.isoId;

  useEffect(() => {
    authContext.loadUser();
    pageContext.handleSetPageSidebar(true);
    return () => {
      pageContext.handleSetPageSidebar(false);
    };
    // eslint-disable-next-line
  }, []);

  const { role } = user;

  // user is not loaded yet
  if (role === 6) return "";

  // unauthorized user
  if (role !== 1) return <Redirect to='/landing' />;

  return <IsoState>
    <IsoDash isoId={isoId} />
  </IsoState>;
};
export default withRouter(Iso);
