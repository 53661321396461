import React, { Fragment } from 'react';
import spinner from './spinner.gif';

const Spinner = () => (
  <Fragment>
    <div
      className='text-center height-450 valign-middle'
      alt='Loading...'
      style={{
        position: 'relative',
        top: '200px',
      }}
    >
      <img
        src={spinner}
        alt='Loading...'
        style={{ width: '100px', margin: 'auto', display: 'block' }}
      />
    </div>
  </Fragment>
);

export default Spinner;
