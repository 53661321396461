import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';
import { isDisabled } from '../../../utils/generalHelpers';

const FileUpload = (props) => {
  const {
    user,
    mainValueID,
    uploadAttachment,
    uploadPercentage,
    uploadCount,
    indicatorStatus,
  } = props;
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const onChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const onClick = (e) => {
    e.target.value = null;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!files.length) {
      return;
    }

    setUploading(true);
    setFiles([]);

    uploadAttachment({
      files,
      valueId: mainValueID
    }).finally(() => setUploading(false));
  };

  let status;
  if (indicatorStatus) {
    status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      status = indicatorStatus[0].status;
    }
  }

  const roleId = user.role;

  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <div className='custom-file mb-1'>
          <input
            type='file'
            multiple
            className='custom-file-input'
            id='customFile'
            onChange={onChange}
            onClick={onClick}
            disabled={isDisabled(status, roleId)}
          />
          <label className='custom-file-label' htmlFor='customFile'>
            {uploading ? "Uploading..."
              : !files.length ? "Select files to upload"
                : files.length === 1 ? files[0].name
                  : files.length + " files selected"}
          </label>
        </div>
        <div className={uploadPercentage === 0 ? 'hide' : 'mt-2 mb-3'}>
          <ProgressBar percentage={uploadPercentage} count={uploadCount} />
        </div>
        <input
          type='submit'
          value='Upload'
          className='btn btn-success btn-block mt-2'
          disabled={uploading || isDisabled(status, roleId) || !files.length}
        />
      </form>
    </Fragment>
  );
};

FileUpload.propTypes = {
  user: PropTypes.object,
  mainValueID: PropTypes.number,
  indicatorStatus: PropTypes.array,
  uploadAttachment: PropTypes.func,
  uploadPercentage: PropTypes.number,
  uploadCount: PropTypes.number,
};
export default FileUpload;
