import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import SidebarNavList from './SidebarNavList';

const SidebarNav = ({ isotheme, getIndicator }) => {
  const [menustate, setState] = useState({
    active: -1,
    clicked: 1,
  });
  const handleExpand = (e, i, match) => {
    e.preventDefault();
    if (menustate.clicked === -1 && match) {
      setState((menustate) => ({
        active: -1,
        clicked: 1,
      }));
    } else {
      setState((menustate) => ({
        active: menustate.active === i ? -1 : i,
        clicked: 1,
      }));
    }
  };
  return (
    <Fragment>
      {isotheme !== null ? (
        <ul className='nav'>
          <li className='nav-header'>Workbook Navigation</li>
          {isotheme.map((menu, i) => (
            <Route
              path={menu.path}
              exact={menu.exact}
              key={i}
              children={({ match }) => (
                <SidebarNavList
                  data={menu}
                  key={i}
                  expand={(e) => handleExpand(e, i, match)}
                  active={i === menustate.active}
                  clicked={menustate.clicked}
                  getIndicator={getIndicator}
                />
              )}
            />
          ))}
        </ul>
      ) : (
        <div>loading</div>
      )}
    </Fragment>
  );
};

SidebarNav.propTypes = {
  isotheme: PropTypes.array,
};

export default withRouter(SidebarNav);
