import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getWBStatusString } from '../../../utils/generalHelpers';

const IndicatorHeader = (props) => {
  const {
    indicatorLabel,
    themeLabel,
    indicatorType,
    cityObject,
    isoLabel,
    workbookObject,
    isoId,
    workbookId
  } = props;

  let indicatorTypeString;
  if (isoId <= 2) {
    indicatorTypeString = indicatorType === 1 ? " (Core" : indicatorType === 2 ? " (Supporting" : " (Profile";
    indicatorTypeString += " Indicator)";
  }

  return (
    <Fragment>
      <ol className='breadcrumb pull-right'>
        <li className='breadcrumb-item'>{cityObject ? cityObject.name : ''}</li>
        <li className='breadcrumb-item'>
          {workbookObject ? (
            <Link to={`/iso/${isoId}/workbook/${workbookId}`}>
              {isoLabel} / {workbookObject.year} /{' '}
              {getWBStatusString(workbookObject.status)}
            </Link>
          ) : (
            ''
          )}
        </li>
        <li className='breadcrumb-item active'>{themeLabel}</li>
      </ol>
      <h1 className='page-header'>
        {indicatorLabel}
        {indicatorTypeString && (
          <small>
            {indicatorTypeString}
          </small>
        )}
      </h1>
    </Fragment>
  );
};

IndicatorHeader.propTypes = {
  indicatorLabel: PropTypes.string,
  themeLabel: PropTypes.string,
  indicatorType: PropTypes.number,
  cityObject: PropTypes.object,
  isoLabel: PropTypes.string,
  workbookObject: PropTypes.object,
  isoId: PropTypes.string.isRequired,
  workbookId: PropTypes.string.isRequired
};

export default IndicatorHeader;
