import React from 'react';
import styled from 'styled-components';

const Line = styled.p`
  margin-bottom: 5px;
`;

const WorkbookPanelSummary = (props) => {
  const {
    name,
    region,
    country,
    isoLabel,
    year
  } = props;

  return (
    <div className='widget-chart-info'>
      <h4 className='widget-chart-info-title'>Workbook Summary</h4>
      <p className='widget-chart-info-desc' />
      <div className='text-white'>
        {/* <p>
          {name}, {region}, {country} - {isoLabel} - {year}
        </p> */}
        <Line>
          {"City: "}
          <span className='text-aqua-lighter font-weight-bold'>
            {name}, {region}, {country.name}
          </span>
        </Line>
        <Line>
          {"Edition: "}
          <span className='text-aqua-lighter font-weight-bold'>
            {isoLabel}
          </span>
        </Line>
        <Line>
          {"Reporting Year: "}
          <span className='text-aqua-lighter font-weight-bold'>{year}</span>
        </Line>
      </div>
    </div>
  );
};

export default WorkbookPanelSummary;
