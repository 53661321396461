const questionTypes = [
  {
    value: 1,
    text: "Main Value"
  },
  {
    value: 2,
    text: "Numerator"
  },
  {
    value: 3,
    text: "Denominator"
  },
  {
    value: 4,
    text: "Source"
  },
  {
    value: 5,
    text: "Audit Question"
  },
  {
    value: 6,
    text: "Not Applicable"
  },
  {
    value: 7,
    text: "Parent"
  },
  {
    value: 8,
    text: "Additional"
  }
];

const auditQuestionValueTypes = [
  {
    value: "yes/no",
    text: "yes/no"
  },
  {
    value: "yes/no/partially",
    text: "yes/no/partially"
  },
  {
    value: "text",
    text: "text"
  },
  {
    value: "number",
    text: "number"
  },
  {
    value: "percent",
    text: "percent"
  },
  {
    value: "year",
    text: "year"
  }
];

const workbookStatuses = [
  { // 0
    name: "Not Applicable",
    dotColor: "warning",
    textClass: ""
  },
  { // 1
    name: "Draft",
    dotColor: "blue",
    textClass: "text-blue-darker"
  },
  { // 2
    name: "WCCD Internal Review",
    dotColor: "yellow",
    textClass: "text-yellow-darker"
  },
  { // 3
    name: "Review - Returned to City",
    dotColor: "yellow",
    textClass: "text-yellow-darker"
  },
  { // 4
    name: "Verification",
    dotColor: "warning",
    textClass: "text-orange-darker"
  },
  { // 5
    name: "Verification - Returned to City",
    dotColor: "warning",
    textClass: "text-orange-darker"
  },
  { // 6
    name: "Recommended for Certification",
    dotColor: "green",
    textClass: "text-success"
  },
  { // 7
    name: "Published",
    dotColor: "green",
    textClass: "text-success"
  },
];

module.exports = { questionTypes, auditQuestionValueTypes, workbookStatuses };