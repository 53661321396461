import React, { Fragment } from 'react';
import spinner2 from './Spinner2.gif';

const Spinner2 = () => (
  <Fragment>
    <div
      className='text-center height-450 valign-middle'
      alt='Loading...'
      style={{
        position: 'relative',
        top: '200px',
      }}
    >
      <img
        src={spinner2}
        alt='Loading...'
        style={{
          width: '100px',
          margin: 'auto',
          display: 'block',
        }}
      />
    </div>
  </Fragment>
);

export default Spinner2;
