import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Linkify from "react-linkify";
import { isDisabled } from "../../../utils/generalHelpers";
import { BackInTime } from "../backInTime/BackInTime";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import IndicatorDataTable from './IndicatorDataTable';

const IndicatorMainValueSource = ({
                                    user,
                                    value,
                                    indicatorStatus,
                                    updateValue,
                                    // has_table_data,
                                  }) => {
  const [sourceState, setSourceState] = useState(value);
  // const [modalState, setModalState] = useState(false);
  const [roleIdState, setRoleIdState] = useState(0);
  const [statusState, setStatusState] = useState(0);

  const { id, text_value } = sourceState;

  const onChange = (e) => {
    setSourceState({ ...sourceState, [e.target.name]: e.target.value });
  };

  const onBlur = () => {
    updateValue(sourceState);
  };

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  useEffect(() => {
    setRoleIdState(user.role);
    var status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      setStatusState(indicatorStatus[0].status);
    }
  }, [user, indicatorStatus]);
  return (
    <div className="input-group m-b-6">
      {isDisabled(statusState, roleIdState) ? (
        <Fragment>
          <div
            className="bg-grey-transparent-2 p-15 row-space-4 text-wrap height-125 overflow-auto scrollbar width-full"
          >
            <Linkify
              componentDecorator={componentDecorator}
              className="text-wrap"
            >
              {text_value}
            </Linkify>
          </div>
          <div className="clearfix" />
        </Fragment>
      ) : (
        <Fragment>
          <textarea
            className="form-control has-success width-full"
            rows="5"
            placeholder="Source Docs"
            name="text_value"
            value={text_value !== null ? text_value : " "}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isDisabled(statusState, roleIdState)}
            id={id}
          />
          <div className="position-absolute" style={{ right: 10, bottom: 0 }}>
            <BackInTime
              disabled={(indicatorStatus[0].status !== 1)}
              user={user}
              id={value?.id}
              setValue={(value) => {
                setSourceState({ ...sourceState, text_value: value });

                updateValue({ ...sourceState, text_value: value });
              }}
            />
          </div>
          <div className="clearfix" />
          {/* <div className='pull-right width-full'>
            {has_table_data ? (
              <div className='pull-right'>
                <button
                  className='btn btn-xs btn-warning m-t-10 pull-right'
                  onClick={() => setModalState(!modalState)}
                >
                  Complete Table Data
                </button>
              </div> 
            ) : (
              ''
            )}
          </div>*/}
        </Fragment>
      )}
      <div className="input-group-append">
        <ul className="dropdown-menu dropdown-menu-right" />
      </div>
    </div>
  );
};
IndicatorMainValueSource.propTypes = {
  user: PropTypes.object,
  indicatorStatus: PropTypes.array,
  updateValue: PropTypes.func,
};
export default IndicatorMainValueSource;
