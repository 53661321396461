import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { yearsList } from '../../utils/generalHelpers';
import renderCityOptions from '../../utils/renderCityOptions';

const AddWorkbookModal = ({
  modalState = false,
  isos,
  cities,
  addWorkbook
}) => {
  const [modalStateWorkboook, setModalState] = useState(false);
  const [workbook, setWorkbook] = useState({
    iso: '',
    city: '',
    year: '',
    status: 1
  });
  const onChangeIso = e => {
    setWorkbook({
      ...workbook,
      iso: parseInt(e.target.value)
    });
  };
  const onChangeYear = e => {
    setWorkbook({
      ...workbook,
      year: parseInt(e.target.value)
    });
  };
  const selectChange = value => {
    setWorkbook({
      ...workbook,
      city: value.value
    });
  };
  const onSubmit = workbook => {
    addWorkbook(workbook);
    setModalState(false);
  };
  const years = yearsList();
  const city_options = renderCityOptions(cities);

  useEffect(() => {
    setModalState(modalState);
  }, [modalState]);

  return (
    <Modal isOpen={modalStateWorkboook}>
      <ModalHeader>Create a new workbook</ModalHeader>
      <ModalBody>
        <div className='input-group'>
          <div className='m-t-20 width-full'>
            <label htmlFor='city'>City: </label>
            <Select
              onChange={value => selectChange(value)}
              options={city_options}
              name='city'
              isSearchable
              styles={{ width: '100%' }}
              defaultValue=''
              required
            />
          </div>
          <div className='m-t-20 width-full'>
            <label htmlFor='isos'>ISO: </label>
            <select
              className='form-control'
              onChange={onChangeIso}
              name='isos'
              id='isos'
              required
            >
              <option>Please select one of the options below:</option>
              {isos !== null
                ? isos.map(iso => (
                    <option key={iso.id} value={iso.id}>
                      {iso.code} ({iso.year}) - {iso.name}
                    </option>
                  ))
                : ''}
            </select>
          </div>
          <div className='m-t-20 width-full'>
            <label htmlFor='years'>Reporting Year: </label>
            <select
              className='form-control'
              onChange={onChangeYear}
              name='years'
              id='years'
              required
            >
              <option>Please select the reporting year:</option>
              {years.map(year => (
                <option key={year}>{year}</option>
              ))}
            </select>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-success' onClick={() => onSubmit(workbook)}>
          Add
        </button>
        <button
          className='btn btn-white'
          id='addwb_submit'
          onClick={() => setModalState(false)}
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};
AddWorkbookModal.propTypes = {
  modalState: PropTypes.bool,
  addWorkbook: PropTypes.func,
  isos: PropTypes.array,
  cities: PropTypes.array
};
export default AddWorkbookModal;
