import React, { Fragment, useContext, useEffect } from "react";
import PageContext from "../../config/pageContext";
import AuthContext from "../../context/auth/authContext";
import CityWorkbookState from "../../context/cityworkbook/CityWorkbookState";
import WorkbooksAdminDash from "../workbook/WorkbooksAdminDash";

const WorkbooksAdmin = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.handleSetPageSidebar(false);
    // eslint-disable-next-line
    return () => {
      pageContext.handleSetPageSidebar(true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <CityWorkbookState>
        <WorkbooksAdminDash />
      </CityWorkbookState>
    </Fragment>
  );
};

export default WorkbooksAdmin;