const countIndicators = isotheme => {
  let core = 0;
  let supporting = 0;
  let profile = 0;
  let indicator = 0;
  let coreTotal = 0;
  let supportingTotal = 0;
  let profileTotal = 0;
  let indicatorTotal = 0;

  for (let theme of isotheme.themes) {
    for (let ind of theme.indicators) {
      // Excluding indicators with a parent id
      if (ind.parent_id || !ind.is_visible) {
        continue;
      }

      const indicatorType = ind.indicatortype;
      const status = (ind.status[0] || {});
      const countTally = status.status !== 0 || status.is_forced_tally;

      switch (indicatorType) {
        case 1:
          if (countTally) {
            // Exception for 8.1 (183) and 8.2 (184):
            // - when 8.1 and 8.2 are reported: core indicator count will be +2
            // - when only 8.1 is reported: core indicator count will be +2
            // - when only 8.2 is reported: core indicator count will be +1
            if (ind.id === 183 && !theme.indicators.find(i => i.id === 184 && (i.status[0] || {}).status !== 0)) {
              core++;
            }
            core++;
          }
          coreTotal++;
          break;
        case 2:
          if (countTally) supporting++;
          supportingTotal++;
          break;
        case 3:
          if (countTally) profile++;
          profileTotal++;
          break;
        case 4:
          if (countTally) indicator++;
          indicatorTotal++;
          break;
        default:
          break;
      }
    }
  }

  return {
    core,
    supporting,
    profile,
    indicator,
    coreTotal,
    supportingTotal,
    profileTotal,
    indicatorTotal
  }
};
export default countIndicators;
