import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../context/auth/authContext';

const About = props => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <h1>About this app</h1>
      <p className='my-1'>this is an about page</p>
      <p className='bg-dark p'>version 0.0.1</p>
    </Fragment>
  );
};

export default About;
