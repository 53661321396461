import React, { useContext, useEffect, useReducer, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../config/const";
import AuthContext from "./authContext";
import AlertContext from "../alert/alertContext";
import authReducer from "./authReducer";
import { setAuthToken } from "../../utils/generalHelpers";
import {
  ACCEPT_DISCLAIMER,
  AUTH_ERROR,
  CLEAR_ERRORS,
  GET_CITIES,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  USER_LOADED,
} from "../types";
import { useIdleTimer } from "react-idle-timer";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const AuthState = (props) => {
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    db_export: null,
    user: { role: 6, cities: [999] },
    error: null,
  };
  const [state, dispatch] = useReducer(authReducer, initialState);

  // session activity stuff
  const [showSessionExpireModal, setShowSessionExpireModal] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());
  const [sessionExpiresIn, setSessionExpiresIn] = useState(0);

  const onAction = async () => {
    if (!state.isAuthenticated) {
      return;
    }

    const now = new Date();
    if (!isPrompted() && now - lastUpdatedAt > 15000) {
      setLastUpdatedAt(now);
      await updateSession();
    }
  };

  const onIdle = () => {
    logout();
  };

  const onPrompt = () => {
    if (state.isAuthenticated) {
      setShowSessionExpireModal(true);
    }
  };

  const { isPrompted, reset, getRemainingTime } = useIdleTimer({
    debounce: 200,
    crossTab: true,
    emitOnAllTabs: true,
    onIdle,
    onPrompt,
    promptTimeout: 61000,
    timeout: 60000 * 59,
    onAction,
  });

  useEffect(() => {
    const timerId = setInterval(async () => {
      if (!state.isAuthenticated) {
        return;
      }

      if (isPrompted()) {
        setSessionExpiresIn(Math.floor(getRemainingTime() / 1000));
        setShowSessionExpireModal(true);
      } else {
        setShowSessionExpireModal(false);
      }
    }, 1000);

    return () => clearInterval(timerId);
  });

  const updateSession = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/user/update/`,
        setAuthToken(localStorage.token),
      );
      if (!res.data.email) {
        setAlert("Your session has expired. Please login again", "danger", 10000);
        dispatch({ type: AUTH_ERROR });
      }
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // load user
  const loadUser = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/user/update/`,
        setAuthToken(localStorage.token),
      );
      if (res.data.email) {
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      } else {
        setAlert("Your session has expired. Please login again", "danger", 10000);
        dispatch({ type: AUTH_ERROR });
      }
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // register user
  const register = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${baseUrl}/api/user/register/`,
        formData,
        config,
      );
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      await loadUser();
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response.data,
      });
    }
  };

  // login user
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${baseUrl}/api/user/login/`,
        formData,
        config,
      );
      setAlert("Welcome back, " + res.data.user.name + "!", "success");
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      await loadUser();
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data,
      });
    }
  };

  // password reset request
  const resetPasswordRequest = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${baseUrl}/api/password_reset/`,
        formData,
        config,
      );
      dispatch({
        type: REQUEST_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: REQUEST_FAIL,
        payload: err.response.data,
      });
    }

    setAlert(
      "Please check your inbox. If there's an account associated with this address, you will receive an email with further instructions momentarily.",
      "success",
      5000,
    );
  };

  // password reset confirm
  const resetPasswordConfirm = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${baseUrl}/api/password_reset/confirm/`,
        formData,
        config,
      );
      setAlert(
        "Password reset successfully. You will be redirected to the login page now.",
        "success",
        2000,
      );
      dispatch({
        type: REQUEST_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: REQUEST_FAIL,
        payload: err,
      });
    }
  };

  // logout
  const logout = () => {
    setShowSessionExpireModal(false);
    dispatch({ type: LOGOUT });
  };

  // acceptDisclaimer
  const acceptDisclaimer = () => {
    return axios.post(
      `${baseUrl}/api/user/accept-disclaimer/`,
      {},
      setAuthToken(localStorage.token),
    ).then(() => {
      dispatch({ type: ACCEPT_DISCLAIMER });
    });
  };

  //  clear errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  // load dbexport
  const exportDB = async (uuid, cities, years, mostRecentYear, isoID, unpublished, sourceYear, isDrivingInvestment, isAnnotatedExport, simplifiedView) => {
    try {
      let url = `${baseUrl}/api/cert/db-export/?uuid=${uuid}`;
      if (cities && cities.length) {
        url += "&cities=" + cities.join(",");
      }
      if (mostRecentYear) {
        url += "&mostRecentYear=" + mostRecentYear;
      } else if (years && years.length) {
        url += "&years=" + years.join(",");
      }
      if (isoID) {
        url += "&isoID=" + isoID;
      }
      if (unpublished) {
        url += "&unpublished=true";
      }
      if (sourceYear) {
        url += "&sourceYear=true";
      }
      if (isDrivingInvestment) {
        url += "&investment=true";
      }
      if (isAnnotatedExport) {
        url += "&annotated=true";
      }
      if (simplifiedView) {
        url += "&simplified=true";
      }
      const res = await axios.get(
        url,
        setAuthToken(localStorage.token),
      );

      return res.data[0];
    } catch (err) {
      return null;
    }
  };
  // get cities
  const getCities = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/city-workbook-all/`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: GET_CITIES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  const subdomain = window.location.host.split(".")[0];
  if (["open", "cities"].includes(subdomain)) {
    window.location = window.location.protocol + "//dashboard." + window.location.hostname.replace(subdomain + ".", "");
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        db_export: state.db_export,
        isAuthenticated: state.isAuthenticated,
        acceptedDisclaimer: state.accepted_disclaimer,
        loading: state.loading,
        user: state.user,
        error: state.error,
        cities: state.cities,
        register,
        loadUser,
        login,
        logout,
        acceptDisclaimer,
        clearErrors,
        resetPasswordRequest,
        resetPasswordConfirm,
        exportDB,
        getCities,
      }}
    >
      <Modal isOpen={showSessionExpireModal && state.isAuthenticated}>
        <ModalHeader>
          Your session is about to expire
        </ModalHeader>
        <ModalBody>
          <div className="p-t-20">
            Your session will expire
            in {sessionExpiresIn} second{sessionExpiresIn === 1 ? "" : "s"} and
            you will be logged out
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            id="session_continue"
            onClick={async () => {
              setSessionExpiresIn(60);
              setShowSessionExpireModal(false);
              reset();
              await updateSession();
            }}
          >
            Continue session
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              reset();
              logout();
            }}
          >
            Logout
          </button>
        </ModalFooter>
      </Modal>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
