import React, { useContext } from "react";
import styled from 'styled-components';
import AuthContext from "../../context/auth/authContext";
import { Redirect } from "react-router-dom";

const Text = styled.p`
  font-size: 15px;
  color: black;
`;

const Disclaimer = () => {
  const authContext = useContext(AuthContext);
  const {
    isAuthenticated,
    acceptDisclaimer,
    acceptedDisclaimer,
    logout
  } = authContext;

  if (!isAuthenticated) return <Redirect to='/login' />;
  if (acceptedDisclaimer) return <Redirect to='/landing' />

  return <div className={"row"}>
    <div className="col-lg-10 bg-white p-20" style={{ textAlign: "justify" }}>
      <h2 className="text-center pt-2">WCCD Website and Portal Terms of Use
        Agreement</h2>
      <Text>
        This World Council on City Data (WCCD) Website and Portal Terms of Use
        Agreement, outlines the use of the information and tools made available
        on www.dataforcities.org and its subdomains (collectively, the
        “Website”) and is governed by the Terms of Use of the WCCD City Members
        Portal (the “Portal”). The individual gaining access to the Website,
        Visualization Portal and/or Workbooks (the “User”) on behalf of their
        City/Municipal Government (the “City”) agrees to abide by the terms set
        out in the WCCD Website and Portal Terms of Use, as reiterated and
        specified below. All Data, information, graphs, images, design elements,
        content, and Data reporting and verification processes displayed on,
        accessible through, or otherwise made available on the Website and
        Portal (the “Data”) are the intellectual property of the World Council
        on City Data (“WCCD”). WCCD grants the User a limited, personal,
        non-exclusive, non-transferable, revocable license to access and use the
        Website, Data, and Portal <b>solely for use and benefit of the
        City</b> and solely in accordance with these terms and conditions. While
        Users may have different levels of access to the Portal, these Terms and
        Conditions apply to all types of users regardless of their level of
        access.
      </Text>
      <Text>
        The User acknowledges that Data from the Website and the Portal may not
        be shared or disclosed to any person or party outside of the City and
        acknowledges that this Data is for internal use only to the City. The
        User further acknowledges that the Terms of Use for the WCCD Website
        specifically <b>prohibits commercial use of all material provided by the
        WCCD on the WCCD Website.</b> The User also acknowledges that the Terms
        of Use for the WCCD City Data in the WCCD City Members Portal,
        specifically <b>prohibits commercial use of all material and Datasets
        made available on the WCCD City Members Portal and Website,</b> and as
        reiterated below:
      </Text>
      <Text>
        <ul>
          <li>
            The User may only access the WCCD Website and Portal on behalf of
            the municipal government that employs you (your “City”)
          </li>
          <li>
            The User will not share your username or password with anyone,
            whether inside or outside of your City.
          </li>
          <li>
            The User is only authorized to use the WCCD Website, Portal, and
            Data <b>for non-commercial purposes that are internal to the
            City.</b> Any commercial use of the Website, Portal, or Data, in
            whole or in part, directly or indirectly, is forbidden, unless
            specifically authorized in writing by WCCD.
          </li>
          <li>
            The User may not share or disclose Data <b>(including any downloaded
            graphs), including the Data of other cities in any form,</b> to any
            person outside of the City <b>including to consultants contracted by
            the City,</b> for any reason, unless specifically authorized in
            writing by WCCD.
          </li>
          <li>
            The User will not allow any third party to, remove or otherwise
            alter any proprietary marks, notices, or disclaimers on Data
            accessed through the Website or Portal. For example, if a screenshot
            of the Website, Portal, or any Data is taken, such screenshot must
            include the WCCD’s branding and must specify that the information is
            for internal use only, and must not be shared outside of the City
            unless authorized in writing by WCCD.
          </li>
          <li>
            The User will not engage in or permit any "screen scraping",
            "Database scraping" or any other activity intended to collect,
            store, reorganize, or manipulate Data or the pages produced by, or
            displayed on, the Website and Portal.
          </li>
          <li>
            <b>All year over year visualizations are based on the year of the
              workbook, not the source year of the Data, unless otherwise
              stated. Any analysis shall
              consider the source year of Data recorded in the workbook. The
              WCCD reserves the right to audit indicators periodically.
              Downloads of visualizations or Data reflect the Database at a
              single point in time. The workbooks and visualization portal shall
              be referenced for the most current Data.</b>
          </li>
          <li>
            The WCCD reserves the right, in its sole discretion, to terminate,
            limit, or suspend the access of the User to the Website and Portal
            without notice or liability, for any reason whatsoever, including in
            connection with actual or attempted breach of any of the terms
            above.
          </li>
        </ul>
      </Text>

      <Text>
        <b>By clicking accept, The User expressly agrees to access and use
          the WCCD City Members Visualization and Reporting Portal (the
          “Portal”) and the “WCCD Website” in accordance with the above
          terms.</b>
      </Text>

      <div className="d-flex justify-content-between px-3 pt-3">
        <button className="btn btn-success" onClick={acceptDisclaimer}>
          Accept and Continue
        </button>
        <button className="btn btn-outline-dark" onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  </div>;
};

export default Disclaimer;