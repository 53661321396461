import React, { useContext, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import IndicatorState from './../../context/indicator/IndicatorState';
import PageContext from '../../config/pageContext';
import AuthContext from '../../context/auth/authContext';
import WorkbookDash from '../workbook/WorkbookDash';

const Workbook = props => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  const workbookId = props.match.params.workbookId;
  const isoId = props.match.params.isoId;

  useEffect(() => {
    authContext.loadUser();
    pageContext.handleSetPageSidebar(false);
    return () => {
      pageContext.handleSetPageSidebar(true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <IndicatorState>
      <Fragment>
        <WorkbookDash workbookId={workbookId} isoId={isoId} />
      </Fragment>
    </IndicatorState>
  );
};
export default withRouter(Workbook);
