// used by IndicatorCommentsItem
import { workbookStatuses } from "./constants";

export const getRole = roleId => {
  switch (roleId) {
    case 1:
      return 'Admin';
    case 2:
      return 'Reviewer';
    case 3:
      return 'Verifier';
    case 4:
      return 'City Member';
    default:
      break;
  }
};

export const getStyle = roleId => {
  switch (roleId) {
    case 1:
      return 'danger';
    case 2:
      return 'warning';
    case 3:
      return 'green';
    case 4:
      return 'info';
    default:
      break;
  }
};

// returns isDisabled boolean for indicator values based on status
export const isDisabled = (statId, roleId) => {
  switch (statId) {
    case 0:
    case 2:
    case 3:
    case 4:
      return true;
    case 1:
      switch (roleId) {
        case 4:
        case 1:
        case 7:
          return false;
        case 2:
        case 3:
        case 5:
        case 6:
          return true;
        default:
          return true;
      }
    default:
      return false;
  }
};

//  generate a list for Year dropdown
export const yearsList = () => {
  const years = [];
  const d = new Date();
  const y = d.getFullYear();
  let i = y - 11;
  for (i; i < y + 1; i++) {
    years.push(i);
  }
  return years;
};

// set page title - used by content.jsx
export const setTitle = (path, routeArray) => {
  let pageTitle;
  for (let i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      pageTitle = 'WCCD | ' + routeArray[i].title;
    }
  }
  document.title = pageTitle ? pageTitle : 'WCCD - Portal';
};

// set the Authentication Token for axios requests
export const setAuthToken = token => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }
  return config;
};

// estimated workbook cetification
export const getCertLevel = (core, supporting, indicator, iso_id) => {
  switch (iso_id) {
    case 1:
    case 2:
      if (core >= 30 && core <= 44) {
        return 'Aspirational';
      } else if (core >= 45 && supporting <= 14) {
        return 'Bronze';
      } else if (core >= 45 && supporting >= 15 && supporting <= 29) {
        return 'Silver';
      } else if (core >= 45 && supporting >= 30 && supporting <= 44) {
        return 'Gold';
      } else if (core >= 45 && supporting >= 45 && supporting <= 59) {
        return 'Platinum';
      } else {
        return 'None';
      }
    case 3:
      if (indicator >= 40 && indicator <= 80) {
        return 'Early Adopter';
      } else {
        return 'None';
      }
    case 4:
      if (indicator >= 34 && indicator <= 68) {
        return 'Early Adopter';
      } else {
        return 'None';
      }
    default:
      break;
  }
};

// square styles for WorkbookGridIndicatorItem
export const getStatusColour = (type, status) => {
  switch (status) {
    case 0:
      switch (type) {
        case 4:
        case 1:
          return 'bg-black-lighter';
        case 2:
          return 'bg-grey-darker';
        case 3:
          return 'bg-grey-lighter';
        default:
          break;
      }
      return 'bg-black-lighter';
    default:
      break;
  }
  switch (type) {
    case 4:
    case 1:
      return 'bg-teal-darker';
    case 2:
      return 'bg-success';
    case 3:
      return 'bg-teal-lighter';
    default:
      break;
  }
};

// icon styles for WorkbookGridIndicatorItem
export const getStatus = status => {
  switch (status) {
    case 0:
      return 'fa-lock text-grey-lighter';
    case 1:
      return 'fa-pencil-alt text-red';
    case 2:
      return 'fa-eye text-yellow';
    case 3:
      return 'fa-bullseye text-orange';
    case 4:
      return 'fa-check';
    default:
      break;
  }
};

// used by main value indicator status
export const getStatusString = statId => {
  switch (statId) {
    case 0:
      return 'Not Applicable';
    case 1:
      return 'Draft';
    case 2:
      return 'Review';
    case 3:
      return 'Verification';
    case 4:
      return 'Verified';
    default:
      break;
  }
};

// used by main value indicator status
export const getStatusColor = statId => {
  switch (statId) {
    case 0:
      return 'grey';
    case 1:
      return 'blue';
    case 2:
      return 'yellow';
    case 3:
      return 'orange';
    case 4:
      return 'green';
    default:
      break;
  }
};

// used by main value indicator status
export const getSwitcherColor = statId => {
  switch (statId) {
    case 4:
      return 'inverse';
    default:
      return 'success';
  }
};

// used by main value indicator status
export const isSwitchDisabled = (statId, roleId) => {
  switch (roleId) {
    case 1:
      return false;
    case 2:
    case 3:
    case 5:
    case 6:
      return true;
    case 4:
      switch (statId) {
        case 0:
        case 1:
        case 2:
        case 3:
          return false;
        default:
          return true;
      }
    default:
      return true;
  }
};

// used by main value indicator status
export const isHidden = (userRole, currentStatus, option) => {
  if (currentStatus === option || userRole === 6 || userRole === 5) {
    return true;
  }

  // Only draft and review options are available for city and intern roles
  if (userRole === 4 || userRole === 7) {
    if (currentStatus >= 3) return true;

    return option > 2;
  }

  // Reviewers can set to draft, verification or back to review
  if (userRole === 2) {
    return currentStatus > 3 ? true : option > 3;
  }

  // Verifiers and admins can do whatever
  if (userRole === 3 || userRole === 1) {
    return false;
  }

  // Any other role, hide everything
  return true;
};

// used by main value workbook status
export const isHiddenWB = (userRole, currentStatus, option) => {
  if (currentStatus === option || userRole === 6 || userRole === 5) {
    return true;
  }

  // Only review and verification options are available for city
  if (userRole === 4) {
    // allow to go into review
    if ((currentStatus === 1 || currentStatus === 3) && option === 2) {
      return false;
    }

    // allow to go into Verification from "Verification - Returned to City"
    return !(currentStatus === 5 && option === 4);
  }

  // Reviewers can set to "Review - Returned to City" and "Verification"
  // from "Review" or "Review - Returned to City".
  if (userRole === 2) {
    return !((currentStatus === 2 || currentStatus === 3)
      && (option === 2 || option === 3 || option === 4));
  }

  // Verifiers can set to "Verification - Returned to City"
  // and "Recommend for Certification" from "Verification" or
  // "Verification - Returned to City"
  if (userRole === 3) {
    return !((currentStatus === 4 || currentStatus === 5)
      && (option === 4 || option === 5 || option === 6));
  }

  // Admins can do whatever
  if (userRole === 1) {
    return false;
  }

  // Any other role, hide everything
  return true;
};

export const isWorkbookClosed = (userRole, workbookStatus) => {
  return userRole === 4 &&
    (workbookStatus === 2 || workbookStatus === 4);
};

// get workbook string
export const getWBStatusString = status => {
  if (!status || status < 0) {
    status = 0;
  }
  return workbookStatuses[status].name;
};

// used by WorkbookItem
export const getWBStatusDot = status => {
  if (!status || status < 0) {
    status = 0;
  }
  return workbookStatuses[status].dotColor;
};

export const getCertColour = cert => {
  switch (cert) {
    case 'PL':
      return { class: 'bg-aqua', title: 'Platinum' };
    case 'GL':
      return { class: 'bg-gradient-yellow', title: 'Gold' };
    case 'SL':
      return { class: 'bg-silver', title: 'Silver' };
    case 'BR':
      return { class: 'bg-orange-darker', title: 'Bronze' };
    case 'AS':
      return { class: 'bg-purple-transparent-7', title: 'Aspirational' };
    case 'EA':
      return { class: 'bg-purple-transparent-7', title: 'Early Adopter' };
    default:
      break;
  }
};
// used by WorkbookGrid
export const getIso = isoId => {
  switch (isoId) {
    case 1:
      return 'ISO37120:2014';
    case 2:
      return 'ISO37120';
    case 3:
      return 'ISO37122';
    case 4:
      return 'ISO37123';
    default:
      break;
  }
};
