import React, { Fragment, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CityContext from '../../context/city/cityContext';
import AlertContext from '../../context/alert/alertContext';
import setError from '../../utils/setError';
import CityWorkbookInfo from './CityWorkbookInfo';
import Spinner from '../layout/Spinner';

const CityDash = props => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const cityContext = useContext(CityContext);
  const { city, getCity, loading, error } = cityContext;
  const cityId = props.match.params.cityId;

  useEffect(() => {
    if (!error) {
      getCity(cityId);
    } else {
      setAlert(setError(error), 'danger');
    }
    // eslint-disable-next-line
  }, [error]);

  return (
    <Fragment>
      {city !== null && !loading ? (
        <div>
          <CityWorkbookInfo />
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default withRouter(CityDash);
