import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { isDisabled } from '../../../utils/generalHelpers';

const IndicatorAttachmentsItem = ({
  user,
  attachment,
  deleteAttachment,
  indicatorStatus
}) => {
  const [alertState, setAlertState] = useState(false);
  const [confirmState, setConfirmState] = useState(false);
  const { id, code, date, file, download, icon } = attachment;

  const onDelete = () => {
    deleteAttachment(id);
  };

  const toggleAlert = name => {
    switch (name) {
      case 'info':
        setAlertState(!alertState);
        break;
      case 'success':
        setConfirmState(!confirmState);
        break;
      default:
        break;
    }
  };
  if (indicatorStatus) {
    var status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      status = indicatorStatus[0].status;
    }
  }

  var roleId = user.role;

  return (
    <Fragment key={id}>
      <tr>
        <td className='with-img'>
          <span className={icon}></span>
        </td>
        <td>{file}</td>
        <td>{code}</td>
        <td>{moment(date).format('lll')}</td>
        <td className='with-btn text-nowrap'>
          <a
            download={file}
            target='_blank'
            href={download}
            rel='noopener noreferrer'
          >
            <button className='btn btn-xs btn-success width-60 m-r-2'>
              <span className='fa fa-cloud-download-alt'></span>
            </button>
          </a>
        </td>
        <td
          className='with-btn text-nowrap'
          hidden={isDisabled(status, roleId)}
        >
          <button
            className='btn btn-xs btn-white width-60'
            onClick={() => toggleAlert('info')}
          >
            <span className='fa fa-times-circle'></span>
          </button>{' '}
          <Fragment>
            {/* FOR UPLOAD https://stackoverflow.com/questions/55831213/uploading-multiple-images-with-react */}
            {alertState && (
              <SweetAlert
                warning
                showCancel
                confirmBtnText='Delete'
                confirmBtnBsStyle='warning'
                cancelBtnBsStyle='default'
                title='Would you like to delete this file?'
                onConfirm={() => {
                  toggleAlert('info');
                  toggleAlert('success');
                  setTimeout(() => {
                    onDelete();
                  }, 1000);
                }}
                onCancel={() => toggleAlert('info')}
              >
                {file}
              </SweetAlert>
            )}
          </Fragment>
          <Fragment>
            {confirmState && (
              <SweetAlert
                success
                confirmBtnText='Close'
                confirmBtnBsStyle='success'
                cancelBtnBsStyle='default'
                title={`Deleted.`}
                onConfirm={() => {
                  toggleAlert('success');
                  onDelete();
                }}
                onCancel={() => toggleAlert('success')}
              ></SweetAlert>
            )}
          </Fragment>
        </td>
      </tr>
    </Fragment>
  );
};
IndicatorAttachmentsItem.propTypes = {
  user: PropTypes.object,
  attachment: PropTypes.object,
  indicatorStatus: PropTypes.array,
  deleteAttachment: PropTypes.func
};
export default IndicatorAttachmentsItem;
