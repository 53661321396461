import React, { useReducer } from 'react';
import axios from 'axios';
import { baseUrl } from '../../../config/const';
import ValueContext from './valueContext';
import valueReducer from './valueReducer';
import { setAuthToken } from '../../../utils/generalHelpers';

import {
  UPDATE_VALUE,
  VALUE_ERROR,
  SET_CURRENT_NUM,
  SET_CURRENT_DEN,
  SET_CURRENT_MAIN,
  SET_VALUE_OBJECT,
  SET_CALC_NUM,
  SET_CALC_DEN,
  SET_LOADING,
} from '../../types';

const ValueState = (props) => {
  const initialState = {
    loading: true,
    valueObject: { year: 1999 },
    currentNum: null,
    currentDen: null,
    calcNum: { main_value: null },
    calcDen: { main_value: null },
    currentMainValue: null,
    error: null,
  };

  const [state, dispatch] = useReducer(valueReducer, initialState);

  const updateValue = async (value) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    try {
      const res = await axios.patch(
        `${baseUrl}/api/cert/value/${value.id}/`,
        value,
        setAuthToken(localStorage.token)
      );
      // console.log(res.data);
      dispatch({
        type: UPDATE_VALUE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: VALUE_ERROR,
        payload: err.response.data,
      });
    }
  };
  // Set Current Numerator
  const setCurrentNum = (value) => {
    dispatch({
      type: SET_CURRENT_NUM,
      payload: value,
    });
  };

  // Set Current Denominator
  const setCurrentDen = (value) => {
    dispatch({
      type: SET_CURRENT_DEN,
      payload: value,
    });
  };

  // Set Calc Numerator
  const setCalcNum = (value) => {
    dispatch({
      type: SET_CALC_NUM,
      payload: value,
    });
  };

  // Set Calc Denominator
  const setCalcDen = (value) => {
    dispatch({
      type: SET_CALC_DEN,
      payload: value,
    });
  };

  // Set Current Main Value
  const setCurrentMainValue = (value) => {
    dispatch({
      type: SET_CURRENT_MAIN,
      payload: value,
    });
  };

  // Set Current Main Value
  const setValueObject = (value) => {
    dispatch({
      type: SET_VALUE_OBJECT,
      payload: value,
    });
  };

  return (
    <ValueContext.Provider
      value={{
        loading: state.loading,
        currentNum: state.currentNum,
        currentDen: state.currentDen,
        calcNum: state.calcNum,
        calcDen: state.calcDen,
        currentMainValue: state.currentMainValue,
        valueObject: state.valueObject,
        error: state.error,
        updateValue,
        setCurrentNum,
        setCurrentDen,
        setCalcNum,
        setCalcDen,
        setCurrentMainValue,
        setValueObject,
      }}
    >
      {props.children}
    </ValueContext.Provider>
  );
};

export default ValueState;
