import React, { useContext, useEffect, Fragment } from 'react';
import PageContext from '../../config/pageContext';
import Continents from '../continents/Continents';
import ContinentForm from '../continents/ContinentForm';
import ContinentFilter from '../continents/ContinentFilter';
import AuthContext from '../../context/auth/authContext';
import ContinentState from './../../context/continent/ContinentState';
const Home = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.handleSetPageSidebar(false);
    // eslint-disable-next-line
    return () => {
      pageContext.handleSetPageSidebar(true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <ContinentState>
        <div className='grid-2'>
          <div>
            <ContinentForm />
          </div>
          <div>
            <ContinentFilter />
            <Continents />
          </div>
        </div>
      </ContinentState>
    </Fragment>
  );
};

export default Home;
