import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AlertContext from "../../context/alert/alertContext";
import Select from "react-select";
import userFields from "./userFields";

const Settings = ({ user, updateUser }) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [loading, setLoading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const updateSelectedColumns = () => {
    setSelectedColumns(Object.entries(userFields)
      .filter(([key]) => (user.admin_settings || []).includes(key))
      .map(([key, value]) => ({ value: key, label: value.label })));
  };

  useEffect(() => {
    updateSelectedColumns();
    // eslint-disable-next-line
  }, [user]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);

    if (modalOpen) {
      updateSelectedColumns();
    }
  };

  const onSave = async () => {
    setLoading(true);

    user.admin_settings = selectedColumns.map(({ value }) => value);
    if (await updateUser(user, true)) {
      toggleModal();
    } else {
      setAlert("Error saving your settings, please try again", "danger");
    }

    setLoading(false);
  };

  const columnOptions = Object.entries(userFields)
    .map(([key, value]) => ({
      value: key,
      label: value.label
    }));

  return (
    <>
      <div className='width-50 ml-2'>
        <button
          className='btn bg-black-transparent-8 btn-lg width-50'
          onClick={toggleModal}
        >
          <i className='fa fa-cog text-white '> </i>
        </button>
      </div>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Table Settings
        </ModalHeader>
        <ModalBody>
          <label htmlFor='tableColumns'>Select columns to be visible: </label>
          <Select
            disabled={loading}
            value={selectedColumns}
            onChange={options => setSelectedColumns(options || [])}
            options={columnOptions}
            name='tableColumns'
            placeholder={"Select columns to display"}
            isMulti
            className='bg-white'
          />
        </ModalBody>
        <ModalFooter>
          <div className={"d-flex flex-row justify-content-between width-full"}>
            <button className='btn btn-white' onClick={toggleModal}
                    disabled={loading}>
              Close
            </button>
            <button className='btn btn-success m-r-10' onClick={onSave}
                    disabled={loading}>
              Save
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Settings;