import React, { useEffect, useState } from "react";
import Select from "react-select";
import { mapCitiesToGroups } from "../../../utils/renderHelpers";

const filterOptions = [
  { value: "label", label: "City" },
  { value: "country", label: "Nation" },
  { value: "region", label: "Region" },
  { value: "climate", label: "Climate" },
  { value: "pop_tier", label: "Population" },
  { value: "density_tier", label: "Population Density" },
  { value: "land_tier", label: "City Land Area" },
  { value: "gdp_tier", label: "Country GDP Per Capita" },
  { value: "cap_tier", label: "Gross Capital Budget Per Capita" },
  { value: "op_tier", label: "Gross Operating Budget Per Capita" },
];

const Filter = (props) => {
  const {
    secondary,
    cities,
    onSelectCities,
    previousFilter,
    minOptions = 1,
  } = props;
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
  const [selectAll, setSelectAll] = useState(false);
  const [valueOptions, setValueOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const updateOptions = () => {
    const options = [...new Set(cities.map(city => city[selectedFilter?.value]))]
      .sort((a, b) => {
        const trimA = a.split(" - ")[0].replace(/[,$]/g, "");
        const trimB = b.split(" - ")[0].replace(/[,$]/g, "");

        return trimA.localeCompare(trimB, "en", { numeric: true });
      })
      .map(value => {
        return {
          value,
          label: value,
        };
      });

    setValueOptions(options);

    if (selectAll) {
      onValuesSelect(options);
    } else if (selectedValues.length) {
      const stringOptions = (options || []).map(({ value }) => value);
      onValuesSelect(selectedValues.filter(({ value }) => stringOptions.includes(value)));
    }

    return options;
  }

  useEffect(() => {
    if (!selectedFilter) {
      setValueOptions([]);
      return;
    }

    updateOptions();
    // eslint-disable-next-line
  }, [selectedFilter]);

  useEffect(() => {
    updateOptions();
    // eslint-disable-next-line
  }, [cities]);

  const onValuesSelect = (values) => {
    setSelectedValues(values || []);

    const stringValues = (values || []).map(({ value }) => value);
    const selectedCities = cities.filter(city => stringValues.includes(city[selectedFilter?.value]));

    if (selectedFilter?.value === "label") {
      onSelectCities(selectedCities, "city");
    } else {
      onSelectCities(selectedCities, selectedFilter?.value);
    }
  };

  const options = filterOptions.filter(({ value }) => {
    if (value === previousFilter) {
      return false;
    }

    const counts = cities.reduce((acc, city) => ({
      ...acc,
      [`${city[value]}`]: true,
    }), {});

    return Object.keys(counts).length > minOptions;
  });

  if (!options.length) {
    return <></>;
  }

  return (
    <div className="text-left text-black bold p-b-10">
      <div className="text-white p-b-2">
        {secondary ? "(Optional) " : ""}Select a {secondary ? "secondary" : ""} peer group or city:
      </div>
      <Select
        value={selectedFilter}
        onChange={(value) => {
          setSelectedFilter(value);
          onValuesSelect([]);
          setSelectAll(false);
        }}
        options={options}
        isSearchable
      />
      {!!valueOptions?.length && (
        <div
          className="text-left clearfix width-full text-white p-t-5">
          <div>
            <input
              type="checkbox"
              onChange={() => {
                setSelectAll(!selectAll);
                onValuesSelect(selectAll ? [] : valueOptions);
              }}
              checked={selectAll}
            />
            {" Select all"}
          </div>
          <div className="text-left text-black bold p-b-5">
            <Select
              value={selectedValues}
              onChange={onValuesSelect}
              options={valueOptions}
              isMulti
              isSearchable
            />
          </div>
        </div>
      )}
    </div>
  );
};

const VisFilter = ({ cities, onSelectCities, noSecondary }) => {
  const [firstCities, setFirstCities] = useState([]);
  const [secondaryCities, setSecondaryCities] = useState([]);
  const [firstGrouping, setFirstGrouping] = useState();
  const [secondaryGrouping, setSecondaryGrouping] = useState();

  useEffect(() => {
    const secondary = !!secondaryCities.length;
    const selectedCities = secondary ? secondaryCities : firstCities;
    const grouping = secondary ? secondaryGrouping : firstGrouping;

    onSelectCities(selectedCities, grouping, mapCitiesToGroups(selectedCities, grouping, [], secondary ? firstGrouping : undefined));

    // eslint-disable-next-line
  }, [firstCities, secondaryCities]);

  return (<div className="d-flex flex-column" style={{ gap: "1rem" }}>
      <Filter
        cities={cities}
        onSelectCities={(cities, grouping) => {
          setFirstCities(cities);
          setFirstGrouping(grouping);

          setSecondaryCities([]);
          setSecondaryGrouping(null);
        }}
        minOptions={0}
      />
      {!noSecondary && !!firstCities.length && (
        <Filter
          secondary
          previousFilter={firstGrouping}
          cities={firstCities}
          onSelectCities={(cities, grouping) => {
            setSecondaryCities(cities);
            setSecondaryGrouping(grouping);
          }}
        />
      )}
    </div>
  );
};

export default VisFilter;