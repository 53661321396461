import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import IndicatorContext from "../../../context/indicator/indicatorContext";
import ValueContext from "../../../context/indicator/value/valueContext";
import AuthContext from "../../../context/auth/authContext";
import IndicatorMainValue from "../main/IndicatorMainValue";
import IndicatorComments from "../comments/IndicatorComments";
import IndicatorAuditParameters from "../audit/IndicatorAuditParameters";
import IndicatorAttachments from "../attachments/IndicatorAttachments";
import IndicatorHeader from "../dash/IndicatorHeader";
import Sidebar from "../../layout/sidebar/Sidebar";
import { isWorkbookClosed } from "../../../utils/generalHelpers";
import AdminNotes from "../admin/AdminNotes";

export const BackInTimeContext = createContext({});

const IndicatorDash = ({ workbookId, indicatorId, isoId, themeId }) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const valueContext = useContext(ValueContext);
  const {
    valueObject,
    setValueObject,
    updateValue,
    setCurrentNum,
    setCurrentDen,
    currentNum,
    currentDen,
    setCalcNum,
    setCalcDen,
    setCurrentMainValue,
    calcNum,
    calcDen,
    currentMainValue,
  } = valueContext;
  const indicatorContext = useContext(IndicatorContext);
  const {
    getWorkbookCity,
    getIndicatorData,
    getIndicatorStatus,
    getHeaderParams,
    getIndicatorValueType,
    getChildIndicators,
    getIsoTheme,
    deleteComment,
    deleteAttachment,
    clearErrors,
    childIndicators,
    isotheme,
    indicatorLabel,
    themeLabel,
    indicatorType,
    cityObject,
    isoLabel,
    workbookObject,
    indicatorObject,
    indicatorValueTypeObject,
    indicatorValueType,
    isParent,
    hasButton,
    loading,
    indicatorStatus,
    error,
    addComment,
    commentsObject,
    mainValueCode,
    mainValueID,
    attachmentsObject,
    updateIndicatorStatus,
    uploadAttachment,
    copyAttachments,
    uploadPercentage,
    uploadCount,
    updateAndGetChildIndicators,
    grabTotals,
    resetTable,
    uploadCityImage,
    deleteCityImage,
  } = indicatorContext;

  if (cityObject !== null && isoLabel !== null && indicatorLabel !== null) {
    document.title =
      indicatorLabel +
      " " +
      cityObject.name +
      " " +
      isoLabel +
      " " +
      workbookObject.year +
      "Workbook Dash";
  }

  useEffect(() => {
    getHeaderParams(themeId, indicatorId);
    getIsoTheme(isoId, workbookId);
    getIndicatorData(indicatorId, workbookId);
    getWorkbookCity(workbookId);
    getIndicatorStatus(indicatorId, workbookId);
    return () => {
      clearErrors();
    };
    // eslint-disable-next-line
  }, [themeId, indicatorId, isoId, workbookId]);

  useEffect(() => {
    indicatorValueType && getIndicatorValueType(indicatorValueType);
    // eslint-disable-next-line
  }, [indicatorValueType]);

  useEffect(() => {
    isParent && getChildIndicators(indicatorId, workbookId);
    // eslint-disable-next-line
  }, [isParent]);

  const [backInTimeState, setBackInTimeState] = useState({
    fetching: false,
    loadingBackInTime: [],
    loading: false,
  });

  const backInTimeContextValue = {
    ...backInTimeState,
    addLoading: (id) => {
      setBackInTimeState((old) => {
        const loadingBackInTime = [...old.loadingBackInTime, id];

        return ({
          ...old,
          loadingBackInTime: [...new Set(loadingBackInTime)],
          loading: true,
        });
      });
    },
    removeLoading: (id) => {
      setBackInTimeState((old) => {
        const loadingBackInTime = [...old.loadingBackInTime];
        loadingBackInTime.splice(loadingBackInTime.indexOf(id), 1);

        return ({
          ...old,
          loadingBackInTime,
          loading: !!loadingBackInTime.length,
        });
      });
    },
    fetchAll: () => {
      setBackInTimeState((old) => ({
        ...old,
        fetching: true,
      }));
      setTimeout(() => {
        setBackInTimeState((old) => ({
          ...old,
          fetching: false,
        }));
      });
    },
  };

  if (isWorkbookClosed(user.role, workbookObject.status)) {
    return <Redirect to={`/iso/${isoId}/workbook/${workbookId}`} />;
  }

  return (
    <Fragment>
      {error ? (
        <span>...loading</span>
      ) : (
        <Fragment>
          <Sidebar
            cityObject={cityObject}
            isotheme={isotheme}
            uploadCityImage={uploadCityImage}
            deleteCityImage={deleteCityImage}
            workbookId={workbookId}
            user={user}
          />
          <BackInTimeContext.Provider value={backInTimeContextValue}>
            <IndicatorHeader
              indicatorLabel={indicatorLabel}
              themeLabel={themeLabel}
              indicatorType={indicatorType}
              cityObject={cityObject}
              isoLabel={isoLabel}
              workbookObject={workbookObject}
              isoId={isoId}
              workbookId={workbookId}
            />
            <div className="row">
              <div className="col-lg-9">
                <IndicatorMainValue
                  indicatorObject={indicatorObject}
                  loading={loading}
                  indicatorStatus={indicatorStatus}
                  error={error}
                  user={user}
                  workbookId={workbookId}
                  updateIndicatorStatus={updateIndicatorStatus}
                  updateValue={updateValue}
                  valueObject={valueObject}
                  setCurrentNum={setCurrentNum}
                  setCurrentDen={setCurrentDen}
                  currentNum={currentNum}
                  currentDen={currentDen}
                  setCalcNum={setCalcNum}
                  setCurrentMainValue={setCurrentMainValue}
                  calcNum={calcNum}
                  calcDen={calcDen}
                  currentMainValue={currentMainValue}
                  setCalcDen={setCalcDen}
                  indicatorValueTypeObject={indicatorValueTypeObject}
                  setValueObject={setValueObject}
                  isParent={isParent}
                  childIndicators={childIndicators}
                  updateAndGetChildIndicators={updateAndGetChildIndicators}
                  grabTotals={grabTotals}
                  resetTable={resetTable}
                  indicatorId={indicatorId}
                  hasButton={hasButton}
                />
              </div>
              <div className="col-lg-3">
                <IndicatorComments
                  workbookId={workbookId}
                  addComment={addComment}
                  deleteComment={deleteComment}
                  commentsObject={commentsObject}
                  mainValueCode={mainValueCode}
                  mainValueID={mainValueID}
                  loading={loading}
                  user={user}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <IndicatorAuditParameters
                  indicatorObject={indicatorObject}
                  loading={loading}
                  updateValue={updateValue}
                  indicatorStatus={indicatorStatus}
                  user={user}
                />
              </div>
              <div className="col-lg-6">
                <IndicatorAttachments
                  workbookId={workbookId}
                  attachmentsObject={attachmentsObject}
                  loading={loading}
                  deleteAttachment={deleteAttachment}
                  mainValueID={mainValueID}
                  uploadAttachment={uploadAttachment}
                  uploadPercentage={uploadPercentage}
                  copyAttachments={copyAttachments}
                  uploadCount={uploadCount}
                  indicatorStatus={indicatorStatus}
                  user={user}
                />
                <AdminNotes
                  indicatorObject={indicatorObject}
                  updateValue={updateValue}
                  user={user}
                />
              </div>
            </div>
          </BackInTimeContext.Provider>
        </Fragment>
      )}
    </Fragment>
  );
};

IndicatorDash.propTypes = {
  workbookId: PropTypes.string.isRequired,
  indicatorId: PropTypes.string.isRequired,
  themeId: PropTypes.string.isRequired,
  isoId: PropTypes.string.isRequired,
};

export default withRouter(IndicatorDash);
