import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup } from 'react-radio-group';
import Select from 'react-select';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { renderCountryOptions } from '../../utils/renderHelpers';

const climateOptions = [
  "Polar and alpine",
  "Dry (arid and semiarid)",
  "Tropical/megathermal",
  "Continental/microthermal",
  "Temperate/mesothermal"
].map(value => ({ value, label: value }));

const AddCityModal = (props) => {
  const {
    modalState = false,
    countries,
    city_results,
    addCity,
    findCity,
    getRegion,
    clearResults,
    result_loading,
    region_results
  } = props;
  const [modalStateCity, setModalState] = useState(false);
  const [cityState, setCity] = useState({
    country: '',
    country_id: '',
    city: ''
  });

  const [newCityState, setNewCityState] = useState({
    name: '',
    name_ascii: '',
    name_alt: '',
    timezone: '',
    longitude: '',
    latitude: '',
    country: '',
    region: ''
  });
  const [climate, setClimate] = useState(null);

  const selectChange = value => {
    setCity({
      ...cityState,
      country: value.label,
      country_id: value.value
    });
  };
  const onSubmit = () => {
    if (!climate) {
      return;
    }

    addCity({
      ...newCityState,
      climate
    });
    setModalState(false);
  };

  const onSelect = newCity => {
    if (newCity.admin_name !== '') {
      getRegion(newCity.admin_name, cityState.country_id);
    }
    setNewCityState({
      ...newCityState,
      name: newCity.city,
      name_ascii: newCity.city_ascii,
      name_alt: newCity.city_alt ? newCity.city_alt : null,
      timezone: newCity.time_zone,
      longitude: newCity.lng,
      latitude: newCity.lat,
      country: cityState.country_id
    });
  };
  const searchCity = () => {
    clearResults();
    findCity(cityState);
  };

  const country_options = renderCountryOptions(countries);

  useEffect(() => {
    if (region_results.length) {
      setNewCityState({
        ...newCityState,
        region: region_results[0].id
      });
    }
    setModalState(modalState);
    // eslint-disable-next-line
  }, [modalState, region_results]);

  return (
    <Modal isOpen={modalStateCity}>
      <ModalHeader>Create a new city</ModalHeader>
      <ModalBody>
        <div className='form-group'>
          <div className='m-t-20 width-full'>
            <label htmlFor='country'>Please select a country: </label>
            <Select
              onChange={value => selectChange(value)}
              options={country_options}
              name='city'
              isSearchable
              styles={{ width: '100%' }}
              required
            />
          </div>
          {cityState.country !== '' ? (
            <div className='form-group m-t-20'>
              <label htmlFor='new_city'>
                Please type the name of the new city below:
              </label>
              <input
                type='text'
                name='new_city'
                className='form-control'
                required
                onChange={e =>
                  setCity({
                    ...cityState,
                    city: e.target.value
                  })
                }
              />
              {cityState.city !== '' ? (
                <div className='m-t-20'>
                  <div className='width-full'>
                    <button
                      className='btn btn-info btn-block'
                      onClick={searchCity}
                    >
                      Search
                    </button>
                  </div>
                  <Fragment>
                    {city_results !== null && city_results.length !== 0 ? (
                      <RadioGroup
                        name='city_options_rad'
                        onChange={radSelect => onSelect(radSelect)}
                      >
                        {city_results.map(city => (
                          <div
                            className='radio radio-css radio-inline'
                            key={city.id}
                          >
                            <Radio
                              value={city}
                              name={`radio_css_inline_${city.id}`}
                              id={`inlineCssRadio1${city.id}`}
                            />
                            <label htmlFor={`inlineCssRadio1${city.id}`}>
                              {city.city}, {city.admin_name}
                            </label>
                          </div>
                        ))}
                      </RadioGroup>
                    ) : result_loading ? (
                      'Searching...'
                    ) : (
                      ''
                    )}
                  </Fragment>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {newCityState.name && (
            <div className='m-t-20 width-full'>
              <label htmlFor='country'>Please select climate: </label>
              <Select
                onChange={value => setClimate(value.value)}
                options={climateOptions}
                name='climate'
                isSearchable
                styles={{ width: '100%' }}
                required
              />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className='btn btn-white'
          id='addwb_submit'
          onClick={() => setModalState(false)}
        >
          Close
        </button>
        <button className='btn btn-success'
                onClick={onSubmit}
                disabled={!climate}>
          Add City to Portal
        </button>
      </ModalFooter>
    </Modal>
  );
};
AddCityModal.propTypes = {
  addCity: PropTypes.func,
  findCity: PropTypes.func,
  clearResults: PropTypes.func,
  getRegion: PropTypes.func,
  modalState: PropTypes.bool,
  isos: PropTypes.array,
  countries: PropTypes.array,
  city_results: PropTypes.array,
  region_results: PropTypes.array,
  result_loading: PropTypes.bool
};
export default AddCityModal;
