import React, { useEffect, useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";

const visAdditionalIsoList = [
  { value: 3, label: "ISO 37122" },
  { value: 4, label: "ISO 37123" },
];

const yoyVisYearList = [
  { value: 0, label: "Certification Year" },
  { value: 1, label: "Source Year" },
  { value: 2, label: "Certification and Source Year" },
];

const UserItem = (props) => {
  const {
    userItem,
    // cities_options,
    cities_list,
    user,
    updateUser,
    hardDeleteUser,
    generateResetUrl,
    sendResetEmail,
  } = props;
  const [openAlert, setOpenAlert] = useState(null);
  const [resetUrl, setResetUrl] = useState(null);
  const [userState, setUserState] = useState(userItem);
  const [userRole, setUserRole] = useState();
  const [userVis, setUserVis] = useState();
  const [userYoyEnabled, setUserYoyEnabled] = useState();
  const [userMultiFilterEnabled, setUserMultiFilterEnabled] = useState();
  const [userComparisonEnabled, setUserComparisonEnabled] = useState();
  const [userDataDownloadEnabled, setUserDataDownloadEnabled] = useState();
  const [userAnnotatedDataDownloadEnabled, setUserAnnotatedDataDownloadEnabled] = useState();
  const [userUploadCityImages, setUserUploadCityImages] = useState();

  const {
    id,
    name,
    email,
    phone,
    cities,
    last_login,
    is_archived,
    role,
    vis,
    yoy_vis_enabled,
    comparison_vis_enabled,
    data_download_enabled,
    annotated_data_download_enabled,
    upload_city_images,
    vis_additional_iso,
    yoy_vis_year,
    multiple_filters_vis_enabled
  } = userState;

  const [dropdownRole, setOpen] = useState(false);
  const toggleRole = () => {
    setOpen(!dropdownRole);
  };

  const [dropdownVis, setVisOpen] = useState(false);
  const toggleVis = () => {
    setVisOpen(!dropdownVis);
  };

  const Truncate = (str) => {
    return str.length > 20 ? str.substring(0, 20) + "..." : str;
  };

  const getRole = (key) => {
    switch (key) {
      case 1:
        return "Admin";
      case 2:
        return "Reviewer";
      case 3:
        return "Verifier";
      case 4:
        return "City";
      case 5:
        return "Read Only";
      case 6:
        return "Reports Only";
      case 7:
        return "Researcher";
      default:
        break;
    }
  };
  const getVis = (key) => {
    switch (key) {
      case 0:
        return "None";
      case 1:
        return "Global";
      case 2:
        return "Canada";
      case 3:
        return "MENASA";
      default:
        break;
    }
  };

  const isArchived = () => {
    return is_archived ? "#f59c1a" : "#07bc10";
  };

  const isDisabled = (curuser, user_id) => {
    return curuser.id === user_id;
  };

  const isNotAdmin = (curuser) => {
    return curuser.role !== 1;
  };

  const onArchive = () => {
    updateUser({ ...userState, is_archived: !is_archived });
    setUserState({
      ...userItem,
      is_archived: !is_archived,
    });
  };

  const onChange = (e) => {
    setUserState({
      ...userItem,
      [e.target.name]: e.target.value,
    });
  };
  const onBlur = (e) => {
    e.preventDefault();
    updateUser(userState);
  };

  const onClick = (role) => {
    let is_staff = false;
    if (role === 1) {
      is_staff = true;
    }
    updateUser({ ...userState, role: role, is_staff: is_staff });
    setUserState({
      ...userItem,
      role: role,
    });
  };

  const onVisClick = (vis) => {
    updateUser({ ...userState, vis: vis });
    setUserState({
      ...userItem,
      vis: vis,
    });
  };

  const onYoyEnabledClick = () => {
    updateUser({ ...userState, yoy_vis_enabled: !userYoyEnabled });
    setUserState({
      ...userItem,
      yoy_vis_enabled: !userYoyEnabled,
    });
  };

  const onMultiFilterEnabledClick = () => {
    updateUser({ ...userState, multiple_filters_vis_enabled: !userMultiFilterEnabled });
    setUserState({
      ...userItem,
      multiple_filters_vis_enabled: !userMultiFilterEnabled,
    });
  };

  const onComparisonEnabledClick = () => {
    updateUser({
      ...userState,
      comparison_vis_enabled: !userComparisonEnabled,
    });
    setUserState({
      ...userItem,
      comparison_vis_enabled: !userComparisonEnabled,
    });
  };

  const onDataDownloadEnabledClick = () => {
    updateUser({
      ...userState,
      data_download_enabled: !userDataDownloadEnabled,
    });
    setUserState({
      ...userItem,
      data_download_enabled: !userDataDownloadEnabled,
    });
  };

  const onAnnotatedDataDownloadEnabledClick = () => {
    updateUser({
      ...userState,
      annotated_data_download_enabled: !userAnnotatedDataDownloadEnabled,
    });
    setUserState({
      ...userItem,
      annotated_data_download_enabled: !userAnnotatedDataDownloadEnabled,
    });
  };

  const onUploadCityImagesClick = () => {
    updateUser({
      ...userState,
      upload_city_images: !userUploadCityImages,
    });
    setUserState({
      ...userItem,
      upload_city_images: !setUserUploadCityImages(),
    });
  };

  const selectChange = (value) => {
    const newValue = [];
    for (const i in value) {
      newValue.push(value[i].value);
    }
    setUserState({
      ...userItem,
      cities: newValue,
    });
    updateUser({
      ...userItem,
      cities: newValue,
    });
  };

  const onVisAdditionalIsoSelect = (value) => {
    const newValues = (value || []).map(({ value }) => value);

    setUserState({
      ...userItem,
      vis_additional_iso: newValues,
    });
    updateUser({
      ...userItem,
      vis_additional_iso: newValues,
    });
  };

  const onYoyVisYearSelect = (value) => {
    const newValue = (value || {}).value || 0;

    setUserState({
      ...userItem,
      yoy_vis_year: newValue,
    });
    updateUser({
      ...userItem,
      yoy_vis_year: newValue,
    });
  };

  useEffect(() => {
    setUserRole(getRole(role));
    setUserVis(getVis(vis));
    setUserYoyEnabled(!!yoy_vis_enabled);
    setUserMultiFilterEnabled(!!multiple_filters_vis_enabled);
    setUserComparisonEnabled(!!comparison_vis_enabled);
    setUserDataDownloadEnabled(!!data_download_enabled);
    setUserAnnotatedDataDownloadEnabled(!!annotated_data_download_enabled);
    setUserUploadCityImages(!!upload_city_images);
  }, [role, vis, yoy_vis_enabled, multiple_filters_vis_enabled, comparison_vis_enabled, data_download_enabled, annotated_data_download_enabled, upload_city_images]);

  const roles = [];
  for (let i = 1; i < 8; i++) {
    roles.push(
      <DropdownItem onClick={() => onClick(i)} key={i}>
        {getRole(i)}
      </DropdownItem>,
    );
  }

  const visTypes = [];
  for (let i = 0; i < 4; i++) {
    visTypes.push(
      <DropdownItem onClick={() => onVisClick(i)} key={i}>
        {getVis(i)}
      </DropdownItem>,
    );
  }

  const hasColumn = (name) => (user.admin_settings || []).includes(name);

  const onSendResetEmail = () => {
    setOpenAlert("sendEmail");
  };

  const onGenerateResetUrl = () => {
    setOpenAlert("generateUrl");

    generateResetUrl(email)
      .then((url) => setResetUrl(url))
      .catch(() => setResetUrl("Error"));
  };

  return (
    <tr>
      {hasColumn("name") && (
        <th style={{
          position: "sticky",
          zIndex: 5,
          left: 0,
          borderRight: "solid gray 1px",
        }}>
          <input
            name="name"
            type="text"
            className="form-control-plaintext"
            style={{
              minWidth: 150,
            }}
            value={Truncate(name)}
            onChange={onChange}
            onBlur={(e) => onBlur(e)}
            key={`name${id}`}
          />
        </th>
      )}

      {hasColumn("email") && (
        <td>
          <input
            name="email"
            type="email"
            className="form-control-plaintext"
            style={{
              minWidth: 200,
            }}
            value={email}
            onChange={onChange}
            onBlur={onBlur}
            key={`email${id}`}
          />
        </td>
      )}

      {hasColumn("phone") && (
        <td>
          <input
            name="phone"
            type="text"
            className="form-control-plaintext"
            style={{
              minWidth: 150,
            }}
            value={phone === null ? "" : phone}
            onChange={onChange}
            onBlur={onBlur}
            key={`phone${id}`}
          />
        </td>
      )}

      {hasColumn("cities") && (
        <td>
          <Select
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            defaultValue={cities_list.filter((option) =>
              cities.includes(option.value),
            )}
            onChange={(value) => selectChange(value)}
            options={cities_list}
            name="cities"
            isMulti
            isSearchable
            className="width-300"
          />
        </td>
      )}

      {hasColumn("role") && (
        <td>
          <div className="input-group-append btn-group"
               style={{
                 minWidth: 150,
               }}
          >
            <button
              className="btn bg-grey-transparent-3 b-0 f-w-400 f-s-10"
              id="caret"
              disabled={isDisabled(user, id)}
            >
              {userRole}
            </button>
            <UncontrolledButtonDropdown
              isOpen={dropdownRole}
              toggle={() => toggleRole(!dropdownRole)}
            >
              <DropdownToggle
                caret
                color="default"
                className="fa fa-shield-alt bg-grey-transparent-3 b-0"
                disabled={isDisabled(user, id)}
              />
              <DropdownMenu>
                {roles}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        </td>
      )}

      {hasColumn("vis") && (
        <td>
          <div className="input-group-append btn-group"
               style={{
                 minWidth: 100,
               }}
          >
            <button
              className="btn bg-grey-transparent-3 b-0 f-w-400 f-s-10"
              id="caret"
              disabled={isDisabled(user, id)}
            >
              {userVis}
            </button>
            <UncontrolledButtonDropdown
              isOpen={dropdownVis}
              toggle={() => toggleVis(!dropdownVis)}
            >
              <DropdownToggle
                caret
                color="default"
                className="fa fa-chart-bar bg-grey-transparent-3 b-0"
                disabled={isDisabled(user, id)}
              />
              <DropdownMenu>
                {visTypes}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        </td>
      )}

      {hasColumn("vis_additional_iso") && (
        <td>
          <Select
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            defaultValue={visAdditionalIsoList.filter((item) =>
              (vis_additional_iso || []).includes(item.value),
            )}
            onChange={onVisAdditionalIsoSelect}
            options={visAdditionalIsoList}
            name="visAdditionalIso"
            isMulti
            isSearchable
            className="width-200"
          />
        </td>
      )}

      {hasColumn("comparison_vis_enabled") && (
        <td>
          <div className="input-group-append">
            <div className="form-check">
              <input className="form-check-input" type="checkbox"
                     checked={userComparisonEnabled}
                     onClick={onComparisonEnabledClick}
                     id="comparison"/>
            </div>
          </div>
        </td>
      )}

      {hasColumn("yoy_vis_enabled") && (
        <td>
          <div className="input-group-append">
            <div className="form-check">
              <input className="form-check-input" type="checkbox"
                     checked={userYoyEnabled}
                     onClick={onYoyEnabledClick}
                     id="yoy"/>
            </div>
          </div>
        </td>
      )}

      {hasColumn("yoy_vis_year") && (
        <td>
          <Select
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            defaultValue={yoyVisYearList.filter((item) =>
              yoy_vis_year === item.value,
            )}
            onChange={onYoyVisYearSelect}
            options={yoyVisYearList}
            name="yoyVisYear"
            isSearchable
            className="width-200"
          />
        </td>
      )}

      {hasColumn("multiple_filters_vis_enabled") && (
        <td>
          <div className="input-group-append">
            <div className="form-check">
              <input className="form-check-input" type="checkbox"
                     checked={userMultiFilterEnabled}
                     onClick={onMultiFilterEnabledClick}
                     id="multiFilter"/>
            </div>
          </div>
        </td>
      )}

      {hasColumn("data_download_enabled") && (
        <td>
          <div className="input-group-append">
            <div className="form-check">
              <input className="form-check-input" type="checkbox"
                     checked={userDataDownloadEnabled}
                     onClick={onDataDownloadEnabledClick}
                     id="dataDownload"/>
            </div>
          </div>
        </td>
      )}

      {hasColumn("annotated_data_download_enabled") && (
        <td>
          <div className="input-group-append">
            <div className="form-check">
              <input className="form-check-input" type="checkbox"
                     checked={userAnnotatedDataDownloadEnabled}
                     onClick={onAnnotatedDataDownloadEnabledClick}
                     id="annotatedDataDownload"/>
            </div>
          </div>
        </td>
      )}

      {hasColumn("upload_city_images") && (
        <td>
          <div className="input-group-append">
            <div className="form-check">
              <input className="form-check-input" type="checkbox"
                     checked={userUploadCityImages}
                     onClick={onUploadCityImagesClick}
                     id="cityImagesUpload"/>
            </div>
          </div>
        </td>
      )}

      {hasColumn("last_login") && (
        <td style={{
          minWidth: 180,
        }}>
        <span className="date-time">
          {last_login !== null ? moment(last_login).format("lll") : "Never"}
        </span>
        </td>
      )}

      {hasColumn("is_archived") && (
        <td className="">
          <div className="btn-group">
            <button
              className="btn bg-grey-transparent-3 b-0"
              disabled={isDisabled(user, id)}
            >
              <i
                className="fa fa-circle f-s-10 fa-fw m-r-10 pull-left"
                style={{ color: isArchived() }}
              />
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle
                caret
                color="default"
                className="bg-grey-transparent-3 b-0"
                // disabled={isDisabled(user, id)}
              />
              <DropdownMenu>
                {!is_archived && (
                  <>
                    <DropdownItem
                      onClick={onSendResetEmail}
                    >
                      {"Send " + (user.last_login ? "password reset" : "activation") + " email"}
                    </DropdownItem>
                    <DropdownItem
                      onClick={onGenerateResetUrl}
                    >
                      {"Generate " + (user.last_login ? "password reset" : "activation") + " link"}
                    </DropdownItem>
                    <div className="b-t-1 p-t-10 m-t-4"/>
                  </>
                )}
                <DropdownItem
                  disabled={isNotAdmin(user)}
                  onClick={() => onArchive()}
                >
                  {is_archived ? "Restore Account" : "Archive Account"}
                </DropdownItem>
                <DropdownItem
                  disabled={isNotAdmin(user)}
                  onClick={() => setOpenAlert("delete")}
                >
                  <span className="text-red">Delete Account</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>

            {openAlert === "delete" && (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Delete"
                confirmBtnBsStyle="warning"
                cancelBtnBsStyle="default"
                title="Would you like to permanently delete this user?"
                onConfirm={() => {
                  setOpenAlert(null);
                  hardDeleteUser(id);
                }}
                onCancel={() => setOpenAlert(null)}
              >
                {name}
              </SweetAlert>
            )}

            {openAlert === "sendEmail" && (
              <SweetAlert
                type={"info"}
                showCancel
                confirmBtnText="Send email"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="default"
                title="Please verify the email"
                onConfirm={() => {
                  setOpenAlert(null);

                  sendResetEmail(email);
                }}
                onCancel={() => setOpenAlert(null)}
              >
                {name + ": "}
                <span className="font-weight-bold">{email}</span>
              </SweetAlert>
            )}

            {openAlert === "generateUrl" && (
              <SweetAlert
                type={"info"}
                showCancel
                confirmBtnText="Copy to clipboard"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="default"
                disabled={resetUrl === "Copied!"}
                title={name}
                onConfirm={() => {
                  navigator.clipboard.writeText(resetUrl);

                  setResetUrl("Copied!");
                  setTimeout(() => {
                    setOpenAlert(null);
                    setResetUrl(null);
                  }, 2000);
                }}
                onCancel={() => {
                  setOpenAlert(null);
                  setResetUrl(null);
                }}
              >
                {!resetUrl ? "Loading..." : resetUrl}
              </SweetAlert>
            )}
          </div>
        </td>
      )}

      {hasColumn("id") && (
        <td>{id}</td>
      )}
    </tr>
  );
};

UserItem.propTypes = {
  userItem: PropTypes.object,
  // cities_options: PropTypes.array,
  cities_list: PropTypes.array,
  updateUser: PropTypes.func,
  hardDeleteUser: PropTypes.func,
};

export default UserItem;
