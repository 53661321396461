import React, { useReducer } from 'react';
import axios from 'axios';
import { baseUrl } from '../../config/const';
import CityContext from './cityContext';
import cityReducer from './cityReducer';
import { setAuthToken } from '../../utils/generalHelpers';

import { GET_CITY, CITY_ERROR, CLEAR_ERRORS } from '../types';

const CityState = props => {
  const initialState = {
    city: null,
    error: null
  };

  const [state, dispatch] = useReducer(cityReducer, initialState);

  // Get object for city
  const getCity = async id => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/city-workbook/?cid=${id}`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_CITY,
        payload: res.data[0]
      });
    } catch (err) {
      dispatch({
        type: CITY_ERROR,
        payload: err.response
      });
    }
  };
  // clear errors
  const clearErrors = () => {
    dispatch({ type: CLEAR_ERRORS });
  };

  return (
    <CityContext.Provider
      value={{
        city: state.city,
        error: state.error,
        getCity,
        clearErrors
      }}
    >
      {props.children}
    </CityContext.Provider>
  );
};

export default CityState;
