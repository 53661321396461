import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ContinentContext from '../../context/continent/continentContext';

const ContinentItem = ({ continent }) => {
  const continentContext = useContext(ContinentContext);
  const { deleteContinent, setCurrent, clearCurrent } = continentContext;

  const { id, name, code } = continent;

  const onDelete = () => {
    deleteContinent(id);
    clearCurrent();
  };

  return (
    <div className='card bg-light'>
      <h3 className='text-primary text-left'>
        {name.charAt(0).toUpperCase() + name.slice(1)}{' '}
        <span
          style={{ float: 'right' }}
          className={'badge ' + (id === 1 ? 'badge-success' : 'badge-primary')}
        >
          {code}
        </span>
      </h3>
      <p>
        <button
          className='btn btn-dark btn-sm'
          onClick={() => setCurrent(continent)}
        >
          Edit
        </button>
        <button className='btn btn-danger btn-sm' onClick={onDelete}>
          Delete
        </button>
      </p>
    </div>
  );
};

ContinentItem.propTypes = {
  continent: PropTypes.object.isRequired
};

export default ContinentItem;
