import { renderThemeIcon } from './renderIcon';

const renderThemeIndicatorData = (
  isotheme,
  isoId,
  workbookId,
  dash = false
) => {
  var isoMenu = [];
  if (dash === false) {
    // create top level item for side nav
    var isoItem = {};

    isoItem['path'] = `/iso/${isoId}/workbook/${workbookId}`;
    isoItem['icon'] = 'fa fa-th';
    isoItem['wId'] = workbookId;
    isoItem['iId'] = 999;
    isoItem['vtype'] = 11;
    isoItem['theme'] = 999;
    isoItem['title'] = 'Workbook Dashboard';

    isoMenu.push(isoItem);
  }

  var themes = isotheme.themes;

  // now start looping through Themes
  for (var theme in themes) {
    var themeItem = {};
    var themeItemName = themes[theme].name;
    var themeId = themes[theme].id;
    var themeDisplay = themes[theme].display_order;
    themeItem['id'] = themeId;
    var themeItemPath = `/iso/${isoId}/workbook/${workbookId}/theme/${themeId}`;
    var themeItemIcon = renderThemeIcon(themeItemName);
    themeItem['path'] = themeItemPath;
    themeItem['icon'] = themeItemIcon;
    themeItem['title'] = themeItemName;
    themeItem['display_order'] = parseFloat(themeDisplay);
    if (dash !== false) {
      if (themeItemName === 'Profile Indicators') {
        continue;
      }
    }

    var children = [];

    var indicators = themes[theme].indicators;
    if (indicators.length === 0) {
      continue;
    }
    for (var indicator in indicators) {
      var indicatorItem = {};
      var indicatorId = indicators[indicator].id;
      var indicatorIsoSection = indicators[indicator].iso_section;
      var indicatorDisplayOrder = indicators[indicator].display_order;
      var indicatorType = indicators[indicator].indicatortype;
      var indicatorValueType = indicators[indicator].indicatorvaluetype;
      var isParent = indicators[indicator].is_parent;
      // skip if indicator has parent
      if (indicators[indicator].parent_id) {
        continue;
      }
      var indicatorItemPath = `/iso/${isoId}/workbook/${workbookId}/theme/${themeId}/indicator/${indicatorId}`;
      var indicatorItemName = indicators[indicator].label;
      indicatorItem['path'] = indicatorItemPath;
      indicatorItem['title'] = indicatorIsoSection + ' ' + indicatorItemName;
      indicatorItem['wId'] = workbookId;
      indicatorItem['iId'] = indicatorId;
      indicatorItem['iso_section'] = indicatorIsoSection;
      indicatorItem['type'] = indicatorType;
      indicatorItem['vtype'] = indicatorValueType;
      indicatorItem['theme'] = themeItemName;
      indicatorItem['themeId'] = themeId;
      indicatorItem['is_parent'] = isParent;
      indicatorItem['display_order'] = indicatorDisplayOrder;
      indicatorItem['is_visible'] = indicators[indicator].is_visible;
      var statuses = indicators[indicator].status;
      for (var status in statuses) {
        var statusId = statuses[status].status;
        indicatorItem['status'] = statusId;
      }
      children.push(indicatorItem);
      themeItem['children'] = children.sort(
        (a, b) => a.display_order - b.display_order
      );
    }

    isoMenu.push(themeItem);
  }
  isoMenu = isoMenu.sort((a, b) => a.display_order - b.display_order);
  return isoMenu;
};
export default renderThemeIndicatorData;
