import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';
import { getStatusColour, getStatus } from '../../utils/generalHelpers';

const WorkbookGridIndicatorItem = ({ indicator }) => {
  const { iId, path, theme, title, type, status } = indicator;

  const getType = curtype => {
    switch (curtype) {
      case 1:
        return 'Core';
      case 2:
        return 'Supporting';
      case 3:
        return 'Profile';
      case 4:
        return 'Indicator';
      default:
        break;
    }
  };

  const getStatusString = curstatus => {
    switch (curstatus) {
      case 0:
        return 'Not Reporting';
      case 1:
        return 'Draft';
      case 2:
        return 'Submitted for WCCD Internal Review';
      case 3:
        return 'Submitted for Verification';
      case 4:
        return 'Verified';
      default:
        break;
    }
  };

  return (
    <div className={`ro_square ${getStatusColour(type, status)}`} id={iId}>
      <div className='ro_content'>
        <Tooltip
          content={
            <Fragment>
              <div className='tip-heading text-white b-b-1'>{title}</div>
              <span>Theme: {theme}</span>
              <br></br>
              <span>Indicator Type:</span>
              <span className='label bg-teal-darker m-b-5'>
                {getType(type)}
              </span>
              <br></br>
              Status: <span>{getStatusString(status)}</span>
              <br></br>
            </Fragment>
          }
          direction='right'
          tagName='span'
          className='target'
          id={`tooltip_${iId}`}
        >
          <div className='ro_table'>
            <Link to={`${path}`}>
              <div className='ro_table-cell text-black-darker'>
                <div className='ro_top-right'>
                  <span className={`fa ${getStatus(status)}`}></span>
                </div>
                <span className='f-s-8'>
                  {type === 1
                    ? 'Core'
                    : type === 2
                    ? 'Supporting'
                    : type === 3
                    ? 'Profile'
                    : 'Indicator'}
                </span>
              </div>
            </Link>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
WorkbookGridIndicatorItem.propTypes = {
  indicator: PropTypes.object.isRequired
};
export default WorkbookGridIndicatorItem;
