import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import moment from 'moment';

const WorkbookCertModal = (props) => {
  const {
    addCertification,
    workbookId,
    updateWorkbook,
    workbookObject,
    modalState,
    setModalState
  } = props;

  // const [curModalState, setModalState] = useState(modalState);
  const [cert, setCert] = useState({
    certification: '',
    certification_date: '',
    certification_num: '',
    workbook: ''
  });

  const cert_levels = [
    { id: 0, name: 'Early Adopter', code: 'EA' },
    { id: 1, name: 'Aspirational', code: 'AS' },
    { id: 2, name: 'Bronze', code: 'BR' },
    { id: 3, name: 'Silver', code: 'SL' },
    { id: 4, name: 'Gold', code: 'GL' },
    { id: 5, name: 'Platinum', code: 'PL' }
  ];

  const onChangeLevel = e => {
    setCert({
      ...cert,
      certification: e.target.value
    });
  };

  const onSubmit = async (cert, workbookId) => {
    const success = await addCertification(cert);
    if (success) {
      updateWorkbook(
        {
          ...workbookObject,
          status: 7
        },
        workbookId
      );
      setModalState(false);
    }
  };

  const onChange = e => {
    setCert({ ...cert, certification_num: e.target.value });
  };

  useEffect(() => {
    if (workbookId) {
      setCert({
        ...cert,
        workbook: workbookId,
        certification_date: moment().format('YYYY-MM-DD')
      });
    }
    setModalState(modalState);
    // eslint-disable-next-line
  }, [modalState, workbookId]);
  return (
    <Modal isOpen={modalState}>
      <ModalHeader>Publish Workbook</ModalHeader>
      <ModalBody>
        <div className='input-group'>
          <div className='m-t-20 width-full'>
            <label htmlFor='certs'>Certification Level </label>
            <select
              className='form-control'
              onChange={onChangeLevel}
              name='certs'
              id='certs'
              required
            >
              <option>Please select one of the options below:</option>
              {cert_levels.map(cert => (
                <option key={cert.id} value={cert.code}>
                  {cert.code} - {cert.name}
                </option>
              ))}
            </select>
          </div>
          <div className='m-t-20 width-full'>
            <label htmlFor='cert_num'>Certification Number: </label>
            <input
              className='form-control'
              onChange={onChange}
              name='cert_num'
              id='cert_num'
              required
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className='btn btn-white'
          id='addwb_submit'
          onClick={() => setModalState(false)}
        >
          Cancel
        </button>
        <button
          className='btn btn-green width-450'
          onClick={() => onSubmit(cert, workbookId)}
        >
          Publish
        </button>
      </ModalFooter>
    </Modal>
  );
};
WorkbookCertModal.propTypes = {
  addCertification: PropTypes.func,
  updateWorkbook: PropTypes.func,
  workbookObject: PropTypes.object,
  workbookId: PropTypes.number,
  modalState: PropTypes.bool,
  setModalState: PropTypes.func
};
export default WorkbookCertModal;
