import {
  GET_THEMES,
  VIZ_ERROR,
  GET_INDICATORS,
  GET_CITIES,
  SET_INDICATOR,
  SET_CITIES,
  SET_GROUPING,
  SET_GROUP_DATA,
  SET_GROUP_SELECTION,
  SET_LOADING,
  GET_YEAR_OVER_YEAR
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_THEMES:
      return {
        ...state,
        themes: action.payload,
        loading: false,
      };
    case VIZ_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_GROUPING:
      return {
        ...state,
        selectGrouping: action.payload,
      };
    case SET_GROUP_DATA:
      return {
        ...state,
        groupData: action.payload,
        loading: false,
      };
    case SET_GROUP_SELECTION:
      return {
        ...state,
        groupSelection: action.payload,
        loading: false,
      };
    case GET_INDICATORS:
      return {
        ...state,
        themeIndicators: action.payload,
        loading: false,
      };
    case SET_INDICATOR:
      return {
        ...state,
        currIndicator: action.payload,
      };
    case GET_CITIES:
      return {
        ...state,
        indicatorCities: action.payload,
        filteredCities: action.payload3,
        // groupData: action.payload2,
        loading: false,
      };
    case SET_CITIES:
      return {
        ...state,
        filteredCities: action.payload,
        loading: false,
      };
    case GET_YEAR_OVER_YEAR:
      return {
        ...state,
        yearOverYearData: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
