import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { isDisabled, yearsList } from "../../../utils/generalHelpers";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorMainValueNumerator = (props) => {
  const {
    user,
    value,
    indicatorStatus,
    updateValue,
    setCurrentNum,
    currentNum,
    calcNum,
    setCalcNum,
    isParent,
    hasButton,
  } = props;
  let numerator = value;

  if (currentNum !== null) {
    numerator = currentNum;
  }

  const [numState, setNumState] = useState(numerator);
  if (numerator.year !== numState.year) {
    setNumState(numerator);
    updateValue(numerator);
  }
  const { id, main_value, year } = numState;

  const [dropdownNum, setOpen] = useState(false);
  const toggleNum = () => {
    setOpen(!dropdownNum);
  };

  const onClick = (year) => {
    setCurrentNum({
      ...numState,
      year: year,
    });
  };

  const onBlur = () => {
    if (numerator !== numState) {
      updateValue(numState);
      setCurrentNum(numState);
      setCalcNum(numState);
    }
  };

  let status;
  if (indicatorStatus) {
    status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      status = indicatorStatus[0].status;
    }
  }

  const years = yearsList();
  const roleId = user.role;

  useEffect(() => {
    setCurrentNum(null);
    setCalcNum(value);
    setNumState(value);
    // eslint-disable-next-line
  }, [value]);

  const onChange = (val) => {
    setNumState({
      ...numState,
      main_value: val,
      calc_value: val,
    });
    setCalcNum({
      ...numState,
      main_value: val,
      calc_value: val,
    });
    setCurrentNum({
      ...numState,
      main_value: val,
      calc_value: val,
    });
  };

  return (
    <div className="input-group m-b-6">
      <NumberFormat
        className="form-control has-success"
        thousandSeparator=","
        decimalSeparator="."
        disabled={hasButton ? true : isDisabled(status, roleId)}
        name="main_value"
        key={id}
        value={
          !isParent
            ? main_value
            : currentNum
              ? currentNum.main_value
              : (calcNum || {}).main_value
        }
        onBlur={onBlur}
        onValueChange={(values) => {
          let val = values.floatValue;
          if (val === undefined) {
            val = null;
          }

          onChange(val);
        }}
      />
      <div className="input-group-append btn-group">
        <button
          className="btn btn-default"
          id="caret"
          disabled={isDisabled(status, roleId)}
        >
          {year}
        </button>
        <UncontrolledButtonDropdown
          isOpen={dropdownNum}
          toggle={() => toggleNum(!dropdownNum)}
        >
          <DropdownToggle
            caret
            color="default"
            className="fa fa-calendar"
            disabled={isDisabled(status, roleId)}
          />
          <DropdownMenu>
            {years.map((year) => (
              <DropdownItem onClick={() => onClick(year)} key={year}>
                {year}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>

      <BackInTime
        disabled={(indicatorStatus[0].status !== 1)}
        user={user}
        id={value?.id}
        setValue={(value, year) => {
          const update = {
            ...numState,
            main_value: value,
            calc_value: value,
            year,
          };
          setNumState(update);
          updateValue(update);
        }}
      />
    </div>
  );
};

IndicatorMainValueNumerator.propTypes = {
  user: PropTypes.object,
  value: PropTypes.object,
  indicatorStatus: PropTypes.array,
  updateValue: PropTypes.func,
  setCurrentNum: PropTypes.func,
  setCalcNum: PropTypes.func,
  currentNum: PropTypes.object,
};

export default IndicatorMainValueNumerator;
