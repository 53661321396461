import { Panel, PanelBody, PanelHeader } from "../../layout/panel/panel";
import React, { Fragment, useEffect, useState } from "react";

const AdminNotesInput = ({ name, description, value, setValue, updateValue }) => {
  return <div className="input-group mb-4">
    <strong className="text-black">{description}</strong>
    <textarea
      className="form-control has-success width-full"
      rows="3"
      name="text_value"
      value={value?.[name] || ""}
      onChange={(e) => {
        setValue({
          ...value,
          [name]: e.target.value
        });
      }}
      onBlur={(e) => {
        if (value) {
          updateValue(e.target.value, name);
        }
      }}
      id={name}
    />
  </div>
}

const AdminNotes = ({ updateValue, indicatorObject, user }) => {
  const question = indicatorObject?.find((question) => question.qtype === "Question");
  const [value, setValue] = useState({});

  useEffect(() => {
    const val = question?.value[0];

    setValue(val);
  }, [question]);

  if (user.role !== 1) {
    return null;
  }

  const onUpdate = (newValue, name) => {
    updateValue({
      ...value,
      [name]: newValue
    });
  }

  return <Fragment>
    <Panel theme="default" className="bg-silver-transparent-4">
      <PanelHeader>
        <span className="f-s-14">Admin Notes</span>
      </PanelHeader>
      <PanelBody>
        <div className="table-responsive">
          <AdminNotesInput
            name="admin_data_source"
            description="Data Source"
            value={value}
            setValue={setValue}
            updateValue={onUpdate}
          />
          <AdminNotesInput
            name="admin_data_custodian"
            description="Data Custodian"
            value={value}
            setValue={setValue}
            updateValue={onUpdate}
          />
          <AdminNotesInput
            name="admin_data_notes"
            description="Notes"
            value={value}
            setValue={setValue}
            updateValue={onUpdate}
          />
        </div>
      </PanelBody>
    </Panel>
  </Fragment>
}

export default AdminNotes;