import React, { useContext } from 'react';
import AlertContext from '../../context/alert/alertContext';

const Alerts = () => {
  const alertContext = useContext(AlertContext);
  return (
    alertContext.alerts.length > 0 &&
    alertContext.alerts.map(alert => (
      <div
        key={alert.id}
        className={`alert alert-${alert.type} fade show`}
        style={{
          zIndex: 1200,
          position: 'fixed',
          top: 100,
          right: 20,
          opacity: 0.85,
          width: 300
        }}
      >
        <i className='fas fa-info-circle' /> {alert.msg}
      </div>
    ))
  );
};

export default Alerts;
