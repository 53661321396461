import React, { useContext, useReducer } from "react";
import axios from "axios";
import { baseUrl } from "../../config/const";
import IndicatorContext from "./indicatorContext";
import AlertContext from "../alert/alertContext";
import setError from "../../utils/setError";
import indicatorReducer from "./indicatorReducer";
import { setAuthToken } from "../../utils/generalHelpers";
import renderThemeIndicatorData from "../../utils/renderThemeIndicatorData";
import renderAttachments from "../../utils/renderAttachments";
import renderUploads from "../../utils/renderUploads";
import {
  renderComments,
  renderMainValueID,
  renderWorkbookDetails,
} from "../../utils/renderHelpers";

import countIndicators from "../../utils/countIndicators";

import {
  ADD_CERT,
  ADD_COMMENT,
  CITY_ERROR,
  CLEAR_ERRORS,
  DELETE_ATTACHMENT,
  DELETE_COMMENT,
  GET_CHILD_INDICATORS,
  GET_CITY,
  GET_HEADER_PARAMS,
  GET_INDICATOR,
  GET_ISOTHEME,
  GET_PEER_INDICATORS,
  GET_STATUS,
  GET_VALUE_TYPE,
  INDICATOR_ERROR,
  ISOTHEME_ERROR,
  SET_INDICATORS_PARAMS,
  SET_MODAL,
  SET_UPLOADS,
  STATUS_ERROR,
  UPDATE_PARENT,
  UPDATE_STATUS,
  UPDATE_VALUE,
  UPDATE_WORKBOOK,
  UPLOAD_ATTACHMENT,
  UPLOAD_PROGRESS,
  VALUE_ERROR,
} from "../types";

const IndicatorState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialState = {
    loading: true,
    isotheme: null,
    modalState: false,
    certObject: null,
    uploadPercentage: 0,
    themeIndicator: null,
    indicatorObject: null,
    childIndicators: null,
    isParent: null,
    hasButton: null,
    countObject: null,
    commentsObject: null,
    attachmentsObject: null,
    peerIndicatorsObject: null,
    indicatorValueTypeObject: { id: 1, name: "Percent", unit: " " },
    workbookObject: { year: 2020 },
    indicatorStatus: [{ id: 0, status: 0 }],
    cityObject: null,
    indicatorLabel: null,
    themeLabel: null,
    indicatorType: null,
    indicatorValueType: null,
    isoLabel: null,
    mainValueID: null,
    mainValueCode: null,
    error: null,
  };

  const [state, dispatch] = useReducer(indicatorReducer, initialState);

  // Get header params for first page load
  const getHeaderParams = async (themeId, indicatorId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/iso/theme-indicator/${themeId}/`,
        setAuthToken(localStorage.token),
      );

      const indicator = res.data.indicators
        .find(({ id }) => id === parseInt(indicatorId)) || {};
      dispatch({
        type: GET_HEADER_PARAMS,
        pl_theme_label: res.data.name,
        pl_indicator_label: indicator.iso_section + " " + indicator.label,
        pl_indicator_type: indicator.indicatortype,
        pl_indicator_value_type: indicator.indicatorvaluetype,
        pl_indicator_is_parent: indicator.is_parent,
        pl_indicator_has_button: indicator.has_button,
      });
    } catch (err) {
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Get cityObject for header/breadcrumb and profile
  const getWorkbookCity = async (workbookId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/city-workbook/?workbook=${workbookId}`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: GET_CITY,
        payload: res.data[0],
        pl_workbook_details: renderWorkbookDetails(
          res.data[0].workbooks,
          workbookId,
        ),
      });
    } catch (err) {
      dispatch({
        type: CITY_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Get IsoTheme for workbook nav
  const getIsoTheme = async (isoId, workbookId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/indicator-status/?workbook=${workbookId}&iso=${isoId}`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: GET_ISOTHEME,
        payload: renderThemeIndicatorData(res.data[0], isoId, workbookId),
        pl_iso_code: res.data[0].code,
        pl_theme_indicator: renderThemeIndicatorData(
          res.data[0],
          isoId,
          workbookId,
          true,
        ),
        pl_countObject: countIndicators(res.data[0]),
      });
    } catch (err) {
      dispatch({
        type: ISOTHEME_ERROR,
        payload: err.response ? err.response.data : "Error loading iso theme",
      });
    }
  };

  // Get IndicatorData for indicator page
  const getIndicatorData = async (indicatorId, workbookId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/question-value/?indicator=${indicatorId}&workbook=${workbookId}`,
        setAuthToken(localStorage.token),
      );

      const pl_comments = renderComments(res.data);
      const pl_attachments = renderAttachments(res.data);
      const pl_main_value_id = renderMainValueID(res.data);
      dispatch({
        type: GET_INDICATOR,
        payload: res.data,
        pl_comments,
        pl_attachments,
        pl_main_value_id,
      });
    } catch (err) {
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response,
      });
    }
  };

  // Get IndicatorStatus for indicator page
  const getIndicatorStatus = async (indicatorId, workbookId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/indicatorstatus/?indicator=${indicatorId}&workbook=${workbookId}`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: GET_STATUS,
        payload: res.data,
      });
    } catch (err) {
      setAlert(setError(err), "warning");
      dispatch({
        type: STATUS_ERROR,
        payload: err.response,
      });
    }
  };

  // Get IndicatorValueType for indicator page
  const getIndicatorValueType = async (valueTypeId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/iso/indicatorvaluetype/${valueTypeId}/`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: GET_VALUE_TYPE,
        payload: res.data,
      });
    } catch (err) {
      setAlert(setError(err), "warning");
      dispatch({
        type: STATUS_ERROR,
        payload: err.response,
      });
    }
  };

  // Update IndicatorStatus for indicator page
  const updateIndicatorStatus = async (indicatorstatus, workbookId) => {
    try {
      const res = await axios.put(
        `${baseUrl}/api/cert/insert-indicatorstatus/${indicatorstatus.id}/?workbook=${workbookId}`,
        indicatorstatus,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: UPDATE_STATUS,
        payload: [res.data],
      });
    } catch (err) {
      setAlert(setError(err), "warning");
      dispatch({
        type: STATUS_ERROR,
        payload: err.response,
      });
    }
  };

  // Update WorkbookStatus for workbook dash
  const updateWorkbook = async (workbookObject) => {
    delete workbookObject.date_created;
    try {
      const res = await axios.patch(
        `${baseUrl}/api/cert/workbook/${workbookObject.id}/`,
        workbookObject,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: UPDATE_WORKBOOK,
        payload: res.data,
      });
    } catch (err) {
      setAlert(setError(err), "warning");
      dispatch({
        type: STATUS_ERROR,
        payload: err.response,
      });
    }
  };

  // set inidicator params
  const setIndicatorParams = (
    indicatorLabel,
    indicatorType,
    indicatorValueType,
    themeLabel,
    is_parent,
    has_button,
  ) => {
    dispatch({
      type: SET_INDICATORS_PARAMS,
      pl_indicator_label: indicatorLabel,
      pl_indicator_type: indicatorType,
      pl_indicator_value_type: indicatorValueType,
      pl_theme_label: themeLabel,
      pl_indicator_is_parent: is_parent,
      pl_indicator_has_button: has_button,
    });
  };

  // //  clear errors
  const clearErrors = () => {
    dispatch({ type: CLEAR_ERRORS });
  };

  // Add Comment
  const addComment = async (comment, workbookId) => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/cert/comment/?workbook=${workbookId}`,
        comment,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: ADD_COMMENT,
        payload: res.data,
        pl_id: res.data.id,
      });
    } catch (err) {
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Delete Comment
  const deleteComment = async (id, workbookId) => {
    try {
      await axios.delete(
        `${baseUrl}/api/cert/comment/${id}/?workbook=${workbookId}`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: DELETE_COMMENT,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Delete Attachment
  const deleteAttachment = async (id) => {
    try {
      await axios.delete(
        `${baseUrl}/api/cert/value-upload/${id}/`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: DELETE_ATTACHMENT,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response.data,
      });
    }
  };

  // upload attachment
  const uploadAttachment = async ({ files, valueId }) => {
    try {
      const formData = new FormData();
      formData.append("uploads", files.map(({ name }) => name.replace(/,/g, ".")));
      formData.append("value", valueId);

      const redirectRes = await axios.post(
        `${baseUrl}/api/cert/value-upload/`,
        formData,
        setAuthToken(localStorage.token),
      );
      if (!redirectRes.data || !redirectRes.data.files) {
        throw new Error("Error uploading the file(s), please try again");
      }

      const promises = redirectRes.data.files.map((fileData, index) => {
        const { fileName, fields, url } = fileData;
        const postData = new FormData();
        for (let key in fields) {
          postData.append(key, fields[key]);
        }
        const file = files.find(({ name }) => name.replace(/,/g, ".") === fileName);
        if (!file) {
          throw new Error("Error uploading the file(s), please try again");
        }
        postData.append("file", file);

        return () => axios.post(
          url,
          postData,
          {
            onUploadProgress: (progressEvent) => {
              dispatch({
                type: UPLOAD_PROGRESS,
                payload: Math.round((progressEvent.loaded / progressEvent.total) * 100),
                payloadCount: (index + 1) + "/" + redirectRes.data.files.length,
              });
            },
          },
        );
      });

      for (let promise of promises) {
        await promise();
      }

      // mark it as complete
      await axios.post(
        `${baseUrl}/api/cert/value-upload-complete/`,
        {
          uploadIds: redirectRes.data.files.map(({ upload }) => upload.id),
        },
        setAuthToken(localStorage.token),
      ).then(() => {
        setAlert("File(s) Uploaded Successfully", "success");
        dispatch({
          type: UPLOAD_ATTACHMENT,
          payload: renderUploads(redirectRes.data.files.map(({ upload }) => upload)),
        });
        dispatch({
          type: UPLOAD_PROGRESS,
          payload: 0,
          payloadCount: 0,
        });
      }).catch(() => {
        setAlert("Error uploading the file(s), please try again.", "warning");

        return Promise.all(redirectRes.data.files.map(({ upload }) => deleteAttachment(upload.id)));
      });
    } catch (err) {
      if (err.response && err.response.status === 500) {
        setAlert("Please select files to upload", "warning");
      } else {
        setAlert("Error uploading the file(s), please try again..", "warning");
      }

      dispatch({
        type: UPLOAD_PROGRESS,
        payload: 0,
        payloadCount: 0,
      });
    }
  };

  const copyAttachments = async (workbookId, fromValueId, toValueId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/value-upload-copy/?workbook=${workbookId}&from=${fromValueId}&to=${toValueId}`,
        setAuthToken(localStorage.token),
      );
      if (res.data?.length) {
        dispatch({
          type: SET_UPLOADS,
          payload: renderUploads(res.data[0].uploads),
        });
      }
    } catch (err) {
    }
  };

  // create new Certification
  const addCertification = async (cert) => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/cert/certification/`,
        cert,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: ADD_CERT,
        payload: res.data,
      });

      return true;
    } catch (err) {
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response.data,
      });

      setAlert("Error publishing the workbook. Please check all the fields and try again", "danger", 5000);
      return false;
    }
  };

  // grab child indicators
  const getChildIndicators = async (parentId, workbookId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/indicator-children/?parent_id=${parentId}&workbook=${workbookId}`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: GET_CHILD_INDICATORS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response.data,
      });
    }
  };

  const updateAndGetChildIndicators = async (parentId, workbookId, value) => {
    try {
      const res = await axios.patch(
        `${baseUrl}/api/cert/indicator-children-update/${value.id}/`,
        value,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: UPDATE_VALUE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: VALUE_ERROR,
        payload: err.response.data,
      });
    }

    await getChildIndicators(parentId, workbookId);
  };

  const grabTotals = async (value, workbookId, indicatorId) => {
    try {
      const res = await axios.patch(
        `${baseUrl}/api/cert/update-parent/${value.id}/`,
        value,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: UPDATE_PARENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response.data,
      });
    }

    try {
      await getIndicatorData(indicatorId, workbookId);
    } catch (err) {
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response.data,
      });
    }
  };

  const resetTable = async (parentId, workbookId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/reset-table?parent_id=${parentId}&workbook=${workbookId}`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: GET_CHILD_INDICATORS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Set Modal state for workbook - publish modal
  const setModalState = (value) => {
    dispatch({
      type: SET_MODAL,
      payload: value,
    });
  };

  // Get PeerGroups for workbook dash
  const getPeerIndicators = async (workbookId, isoId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/peer-indicators/?workbook=${workbookId}&iso=${isoId}`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: GET_PEER_INDICATORS,
        payload: res.data,
      });
    } catch (err) {
      setAlert(setError(err), "warning");
      dispatch({
        type: INDICATOR_ERROR,
        payload: err.response,
      });
    }
  };

  const uploadCityImage = async (workbookId, cityId, files, external) => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/cert/city-image-upload/`,
        {
          cityId,
          files: external ? files : files.map(({ name }) => name),
          external: external || false
        },
        setAuthToken(localStorage.token),
      );

      if (!external) {
        const links = res.data;
        for (let linkIndex in links) {
          const { url, fields } = links[linkIndex];

          const postData = new FormData();
          for (let key in fields) {
            postData.append(key, fields[key]);
          }
          postData.append("file", files[linkIndex]);

          await axios.post(
            url,
            postData,
          );
        }
      }

      await getWorkbookCity(workbookId);
    } catch (err) {
      setAlert(setError(err), "warning");
    }
  };

  const deleteCityImage = async (workbookId, id) => {
    try {
      await axios.delete(
        `${baseUrl}/api/cert/city-image/${id}`,
        setAuthToken(localStorage.token),
      );

      await getWorkbookCity(workbookId);
    } catch (err) {
      setAlert(setError(err), "warning");
    }
  };

  return (
    <IndicatorContext.Provider
      value={{
        isotheme: state.isotheme,
        themeIndicator: state.themeIndicator,
        workbookObject: state.workbookObject,
        indicatorObject: state.indicatorObject,
        childIndicators: state.childIndicators,
        indicatorStatus: state.indicatorStatus,
        indicatorLabel: state.indicatorLabel,
        themeLabel: state.themeLabel,
        indicatorType: state.indicatorType,
        indicatorValueType: state.indicatorValueType,
        countObject: state.countObject,
        commentsObject: state.commentsObject,
        attachmentsObject: state.attachmentsObject,
        peerIndicatorsObject: state.peerIndicatorsObject,
        uploadPercentage: state.uploadPercentage,
        uploadCount: state.uploadCount,
        indicatorValueTypeObject: state.indicatorValueTypeObject,
        cityObject: state.cityObject,
        isoLabel: state.isoLabel,
        mainValueID: state.mainValueID,
        mainValueCode: state.mainValueCode,
        modalState: state.modalState,
        isParent: state.isParent,
        hasButton: state.hasButton,
        error: state.error,
        getIndicatorData,
        getIsoTheme,
        setIndicatorParams,
        getWorkbookCity,
        getIndicatorStatus,
        getIndicatorValueType,
        updateIndicatorStatus,
        updateWorkbook,
        clearErrors,
        addComment,
        deleteComment,
        deleteAttachment,
        uploadAttachment,
        copyAttachments,
        getHeaderParams,
        addCertification,
        setModalState,
        getChildIndicators,
        grabTotals,
        updateAndGetChildIndicators,
        resetTable,
        getPeerIndicators,
        uploadCityImage,
        deleteCityImage,
      }}
    >
      {props.children}
    </IndicatorContext.Provider>
  );
};

export default IndicatorState;
