import React, { useState, useContext, Fragment } from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import PageContext from '../../../config/pageContext';
import IndicatorContext from '../../../context/indicator/indicatorContext';
import ValueContext from '../../../context/indicator/value/valueContext';

const SidebarNavList = (props) => {
  const pageContext = useContext(PageContext);
  const { handleSidebarOnMouseOver, handleSidebarOnMouseOut } = pageContext;
  const indicatorContext = useContext(IndicatorContext);
  const valueContext = useContext(ValueContext);
  const { setValueObject } = valueContext;
  const {
    getIndicatorData,
    setIndicatorParams,
    getIndicatorStatus,
    getIndicatorValueType,
    getChildIndicators,
  } = indicatorContext;

  const [menustate, setState] = useState({
    active: -1,
    clicked: -1,
  });

  const handleExpand = (e, i, match) => {
    e.preventDefault();

    if (menustate.clicked === -1 && match) {
      setState((menustate) => ({
        active: -1,
        clicked: 1,
      }));
    } else {
      setState((menustate) => ({
        active: menustate.active === i ? -1 : i,
        clicked: 1,
      }));
    }
  };

  const handleClick = (
    iId,
    wId,
    iLabel,
    iType,
    vType,
    tLabel,
    is_parent,
    has_button
  ) => {
    if (iId !== 999) {
      setValueObject({ year: null });
      getIndicatorData(iId, wId);
      setIndicatorParams(iLabel, iType, vType, tLabel, is_parent, has_button);
      is_parent && getChildIndicators(iId, wId);
      getIndicatorStatus(iId, wId);
      getIndicatorValueType(vType);
    }
  };

  var icon = props.data.icon && <i className={props.data.icon}></i>;
  var img = props.data.img && (
    <div className='icon-img'>
      <img src={props.data.img} alt='' />
    </div>
  );
  var caret = props.data.children && !props.data.badge && (
    <b className='caret'></b>
  );
  var label = props.data.label && (
    <span className='label label-theme m-l-5'>{props.data.label}</span>
  );
  var badge = props.data.badge && (
    <span className='badge pull-right'>{props.data.badge}</span>
  );
  var title = props.data.title && (
    <span className={props.data.is_visible === false ? 'text-red' : ''}>
      {props.data.title} {label}
    </span>
  );

  return (
    <Fragment>
      <Route
        path={props.data.path}
        exact={props.data.exact}
        children={({ match }) => (
          <li
            className={
              (match ? 'active ' : '') +
              (props.active || (props.clicked === -1 && match)
                ? 'expand '
                : 'closed ') +
              (props.data.children ? 'has-sub ' : '')
            }
          >
            {props.data.children ? (
              <Link
                to={props.data.path}
                onMouseOver={(e) => handleSidebarOnMouseOver(e, props.data)}
                onMouseOut={(e) => handleSidebarOnMouseOut(e, props.data)}
                onClick={props.expand}
              >
                {img} {icon} {badge} {title} {caret}
              </Link>
            ) : (
              <Link
                to={props.data.path}
                onClick={(e) =>
                  handleClick(
                    props.data.iId,
                    props.data.wId,
                    props.data.title,
                    props.data.type,
                    props.data.vtype,
                    props.data.theme,
                    props.data.is_parent,
                    props.data.has_button
                  )
                }
              >
                {img} {icon} {badge} {title} {caret}
              </Link>
            )}
            {props.data.children && (
              <ul className={'sub-menu '}>
                {props.data.children &&
                  props.data.children.map((submenu, i) => (
                    <SidebarNavList
                      data={submenu}
                      key={i}
                      expand={(e) => handleExpand(e, i, match)}
                      active={i === menustate.active}
                      clicked={menustate.clicked}
                    />
                  ))}
              </ul>
            )}
          </li>
        )}
      />
    </Fragment>
  );
};

export default withRouter(SidebarNavList);
