import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from 'reactstrap';
import {
  getWBStatusString,
  isDisabled,
  isHiddenWB,
  yearsList
} from '../../utils/generalHelpers';
import WorkbookCertModal from './WorkbookCertModal';
import { workbookStatuses } from "../../utils/constants";

const WorkbookGridPanelStatus = (props) => {
  const {
    workbookObject,
    updateWorkbook,
    user,
    addCertification,
    modalState,
    setModalState
  } = props;

  const [reviewAlertState, setReviewAlertState] = useState(false);
  // const [publishAlertState, setPublishAlertState] = useState(false);
  const [statusState, setStatusState] = useState(workbookObject);
  const { year, status } = statusState;
  const [dropdownStatus, setStatus] = useState(false);
  const toggleStatus = () => setStatus(!dropdownStatus);
  const [dropdownYear, setYear] = useState(false);
  const toggleYear = () => setYear(!dropdownYear);
  const { role } = user;

  const onClickStatus = (role, curStat, newStatId) => {
    if (role === 4 && newStatId === 2) {
      setReviewAlertState(!reviewAlertState);
      return;
    }

    // when publishing
    if (newStatId === 7) {
      setModalState(!modalState);
    } else {
      setStatusState({
        ...statusState,
        status: newStatId
      });
      updateWorkbook(
        {
          ...statusState,
          status: newStatId
        },
        workbookObject.id
      );
    }
  };

  const onClickYear = year => {
    // updateValue({ ...valueObject, year: year }, workbookId);
  };

  const years = yearsList();

  useEffect(() => {
    setStatusState(workbookObject);
  }, [workbookObject]);

  let dropdownDisabled = true;
  const dropdownItems = (() => {
    const items = [];
    for (let i = 1; i < workbookStatuses.length; i++) {
      const workbookStatus = workbookStatuses[i];
      let hidden = isHiddenWB(role, status, i);
      if (!hidden) {
        dropdownDisabled = false;
      }

      items.push(
        <DropdownItem
          key={i}
          onClick={() => onClickStatus(role, status, i)}
          hidden={hidden}
        >
          <span className={workbookStatus.textClass}>
            {workbookStatus.name}
          </span>
        </DropdownItem>
      );
    }

    return items;
  })();

  const getStatusColor = (status) => {
    return workbookStatuses[status < 0 ? 0 : status].textClass;
  };

  return (
    <Fragment>
      <WorkbookCertModal
        modalState={modalState}
        addCertification={addCertification}
        workbookId={(workbookObject || {}).id}
        updateWorkbook={updateWorkbook}
        workbookObject={workbookObject}
        setModalState={setModalState}
      />
      <div className='btn-group width-full'>
        <div
          className='input-group-prepend btn-group width-full width-200-min fa-pull-left'>
          <button
            className={`btn ${getStatusColor(status)} bg-black-darker b-0`}
            id='caret'
          >
            {getWBStatusString(status)}
          </button>
          <UncontrolledButtonDropdown
            isOpen={dropdownStatus}
            toggle={toggleStatus}
          >
            <DropdownToggle
              caret
              color='default'
              className={`fa fa-stream text-grey bg-black-darker b-0 `}
              disabled={dropdownDisabled}
            />
            <DropdownMenu>
              {dropdownItems}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
        <div className='input-group-append btn-group width-100 fa-pull-left'>
          <button
            className='btn bg-black-darker text-grey b-0'
            disabled={isDisabled(status)}
          >
            {year}
          </button>
          <UncontrolledButtonDropdown
            isOpen={dropdownYear}
            toggle={() => toggleYear(!dropdownYear)}
          >
            <DropdownToggle
              caret
              color='default'
              className='fa fa-calendar bg-black-darker text-grey b-0'
              // disabled={isDisabled(status)}
              disabled={true}
            />
            <DropdownMenu>
              {years.map(year => (
                <DropdownItem onClick={() => onClickYear(year)} key={year}>
                  {year}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </div>
      {reviewAlertState && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={"Yes, it's ready"}
          confirmBtnBsStyle='success'
          cancelBtnText='No, not yet'
          cancelBtnBsStyle='default'
          title='Are you sure this workbook is ready for review?'
          onConfirm={() => {
            setStatusState({
              ...statusState,
              status: 2
            });
            updateWorkbook(
              {
                ...statusState,
                status: 2
              },
              workbookObject.id
            );
            setReviewAlertState(!reviewAlertState);
          }}
          onCancel={() => setReviewAlertState(!reviewAlertState)}
        >
          <span className='text-black font-weight-bolder'>
            Please make sure you have included all relevant source documentation
            to support indicator values. If there are indicators with missing
            data, please make sure to mark those as 'Not Reporting"
          </span>
        </SweetAlert>
      )}
    </Fragment>
  );
};
WorkbookGridPanelStatus.propTypes = {
  user: PropTypes.object,
  workbookObject: PropTypes.object,
  updateWorkbook: PropTypes.func,
  addCertification: PropTypes.func,
  modalState: PropTypes.bool,
  setModalState: PropTypes.func
};
export default WorkbookGridPanelStatus;
