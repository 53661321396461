import React, { Fragment, useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function IndicatorMainValueParentChildItem(props) {
  const {
    updateAndGetChildIndicators,
    indicatorStatus,
    question,
    is_totals,
    width,
    is_disabled,
    is_ratio,
    parent_id,
    has_aggregate,
    aggregate_selected,
  } = props;
  const [initValue, setInitValue] = useState(question.value[0]);
  const [valueState, setValueState] = useState(question.value[0]);
  const [isDisabled, setDisabled] = useState(is_disabled);

  const Truncate = (str, num) => {
    return str.length > num ? str.substring(0, num) + '...' : str;
  };

  const onBlur = () => {
    if (valueState !== question.value[0]) {
      updateAndGetChildIndicators(
        indicatorStatus[0].indicator,
        indicatorStatus[0].workbook,
        valueState
      );
    }
  };

  useEffect(() => {
    const val = question.value[0];
    if (initValue.main_value !== val.main_value) {
      setInitValue(val);
      setValueState(val || {});
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (is_disabled) {
      setDisabled(is_disabled);
    } else {
      if (aggregate_selected && has_aggregate && question.has_aggregate) {
        setDisabled(false);
      }
      if (!aggregate_selected && has_aggregate && question.has_aggregate) {
        setDisabled(true);
      }
      if (!aggregate_selected && has_aggregate && !question.has_aggregate) {
        setDisabled(false);
      }
      if (aggregate_selected && has_aggregate && !question.has_aggregate) {
        setDisabled(true);
      }
      if (aggregate_selected && has_aggregate && question.is_aggregate) {
        setDisabled(false);
      }
      if (!aggregate_selected && !has_aggregate && !question.is_aggregate) {
        setDisabled(false);
      }
    }
  }, [
    is_disabled,
    has_aggregate,
    aggregate_selected,
    question.has_aggregate,
    question.is_aggregate,
  ]);

  return (
    <div
      className="f-s-11 table-cell"
      style={{ width }}
    >
      {question.is_visible && (
        <Fragment>
          {parent_id === 254 ? (
            <Tooltip
              content={
                <Fragment>
                  <div>
                    <span>{question.text}</span>
                  </div>
                </Fragment>
              }
            >
              <div className='f-s-10'>{Truncate(question.text, 15)}</div>
            </Tooltip>
          ) : (
            ''
          )}
          <div
            className={`float-left text-center ${
              [443, 441, 514, 515].includes(question.indicator) // TODO: This is really not the way to do this...
                ? 'p-l-20'
                : ''
            }`}
          >
            <NumberFormat
              className={`form-control${
                question.qtype === 'Numerator' ||
                question.qtype === 'Question' ||
                is_ratio ||
                is_totals
                  ? '-plaintext f-s-14 '
                  : ''
              } f-w-500  text-center`}
              thousandSeparator=','
              decimalSeparator='.'
              displayType={
                question.qtype === 'Numerator' ||
                question.qtype === 'Question' ||
                is_ratio ||
                is_totals
                  ? 'text'
                  : 'input'
              }
              decimalScale='2'
              disabled={isDisabled}
              name={question.id}
              key={valueState.id}
              value={valueState.main_value}
              onBlur={onBlur}
              // placeholder='0.0'
              onValueChange={(values) => {
                let val = values.floatValue;
                if (val === undefined) {
                  val = null;
                }

                setValueState({
                  ...valueState,
                  main_value: val,
                  calc_value: val,
                });
              }}
            />
          </div>
          {question.qtype === 'Question' && parent_id === 223 ? (
            <div className='width-10 float-left p-t-10 '>%</div>
          ) : (
            ''
          )}
        </Fragment>
      )}
    </div>
  );
}

IndicatorMainValueParentChildItem.propTypes = {
  user: PropTypes.object,
  indicatorStatus: PropTypes.array,
  width: PropTypes.string,
  is_disabled: PropTypes.bool,
  question: PropTypes.object,
  updateAndGetChildIndicators: PropTypes.func,
};
export default IndicatorMainValueParentChildItem;
