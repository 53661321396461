import * as am4core from "@amcharts/amcharts4/core";
import chevron from "../assets/img/icons/red-down-arrow.png";

export const renderMapData = (values, cohorts, user) => {
  values = values.sort((a, b) => a.city_name.localeCompare(b.city_name));

  let mapData = [];
  for (let entry of values) {
    const cohort = cohorts.find(c => c.city === entry.city) || {};
    const { country } = cohort;

    const mapItem = {};
    mapItem.id = entry.city;
    mapItem.title = entry.city_name;
    mapItem.country = country;
    mapItem.longitude = 0;
    mapItem.latitude = 0;

    switch (user.vis) {
      case 1:
        mapItem.color = am4core.color("#fff");
        break;
      case 2:
        if (country === "Canada") {
          mapItem.color = am4core.color("#eb252b");
        } else {
          mapItem.color = am4core.color("#fff");
        }
        break;
      case 3:
        if (
          country === "India" ||
          country === "United Arab Emirates" ||
          country === "Jordan" ||
          country === "Saudi Arabia"
        ) {
          mapItem.color = am4core.color("#eb252b");
        } else {
          mapItem.color = am4core.color("#fff");
        }
        break;
      default:
        break;
    }

    if (user.cities && user.cities.includes(entry.city)) {
      mapItem.bullet = chevron;
    }

    mapItem.value = entry.city;
    mapItem.label = entry.city_name + ", " + country;

    mapItem.main_value = Number(entry.value.toFixed(4));
    mapItem.vtype = entry.value_type;
    mapItem.region = cohort.region;
    mapItem.climate = cohort.climate;
    mapItem.density_tier = cohort.population_density;
    mapItem.land_tier = cohort.land_area;
    mapItem.gdp_tier = cohort.gdp;
    mapItem.cap_tier = cohort.capital_budget;
    mapItem.op_tier = cohort.operational_budget;
    mapItem.pop_tier = cohort.population;
    mapItem.age_tiers = [
      // TODO certain tiers are temporarily disabled - do not remove them yet
      // "(ages 0-24) " + visuals.group_age_0_24_tier,
      cohort.age[1] ? "(ages 0-14) " + cohort.age[1] : null,
      cohort.age[2] ? "(ages 15-24) " + cohort.age[2] : null,
      cohort.age[3] ? "(ages 25-64) " + cohort.age[3] : null,
      // "(ages 25-49) " + visuals.group_age_25_49_tier,
      // "(ages 50-64) " + visuals.group_age_50_64_tier,
      cohort.age[6] ? "(ages 65+) " + cohort.age[6] : null,
    ];
    mapItem.age_tiers = mapItem.age_tiers.filter(i => !!i);

    mapData.push(mapItem);
  }
  return mapData;
};

// A special horizontal line for age cohorts
export const drawAgeCohortLine = (valueAxis, indicatorId) => {
  if ([443, 441, 444, 514, 515, 442, 585].includes(indicatorId)) {
    const range = valueAxis.axisRanges.create();
    switch (indicatorId) {
      case 443:
        range.value = 20;
        break;
      case 441:
        range.value = 15;
        break;
      case 444:
        range.value = 55;
        break;
      case 514:
        range.value = 35;
        break;
      case 515:
        range.value = 20;
        break;
      case 442:
        range.value = 20;
        break;
      case 585:
        range.value = 35;
        break;
      default:
        return;
    }

    range.grid.stroke = am4core.color("#396478");
    range.grid.strokeWidth = 2;
    range.grid.strokeOpacity = 1;
  }
};

export const filterCitiesByGroup = (cities, groupName) =>
  cities.reduce((acc, city) => {
    let groupValues = city[groupName];
    if (!(groupValues instanceof Array)) {
      groupValues = [groupValues];
    }

    groupValues.forEach(groupValue => {
      let category = acc.find(({ value }) => value === groupValue);
      if (!category) {
        category = {
          value: groupValue,
          label: groupValue,
          [`${groupName}`]: groupValue,
          cities: [],
        };
        acc.push(category);
      }
      category.cities.push({
        city_id: city.id,
        city_name: city.title,
      });
    });

    return acc;
  }, []).sort((a, b) => (a.value || "").localeCompare(b.value));

// used by Vis Filter
export const mapCitiesToGroups = (cities, fieldName, selectedValues = [], primaryFieldName) => {
  const groups = cities.reduce((acc, city) => {
    let groupings = city[fieldName];
    if (!(groupings instanceof Array)) {
      groupings = [groupings];
    }

    groupings
      .filter(grouping => !selectedValues.length || selectedValues.includes(grouping))
      .forEach(grouping => {
        const group = acc[grouping] || {};
        if (primaryFieldName) {
          group[city.title] = {
            value: city.main_value,
            primaryGroup: city[primaryFieldName],
          };
        } else {
          group[city.title] = city.main_value;
        }
        acc[grouping] = group;
      });

    return acc;
  }, {});

  return Object.keys(groups).sort().reduce((obj, key) => {
    obj[key] = groups[key];
    return obj;
  }, {});
};

// used by mapState.js
export const renderCityData = (cities) =>
  cities.reduce((acc, city) => {
    acc[city.id] = {
      latLng: [city.latitude, city.longitude],
      name: city.name,
    };

    return acc;
  }, {});

export const renderCountryData = (countries) =>
  (countries || []).reduce((acc, country) => {
    acc[country.iso2] = 1;
    return acc;
  }, {});

export const renderComments = (items) => {
  const commentsObject = [];
  items.forEach(item => {
    item.value[0].comments.forEach(comment => commentsObject.push({
      id: comment.id,
      code: item.code,
      user: comment.user,
      role: comment.role,
      date: new Date(comment.date_created),
      text: comment.text,
      value: comment.value,
    }));
  });

  return commentsObject.sort((a, b) => b.date - a.date);
};

export const renderMainValueID = (items) => {
  const item = (items || []).find(({ qtype }) => qtype === "Question");
  return {
    id: item.value[0].id,
    code: item.code,
  };
};

export const renderWorkbookDetails = (workbooks, workbookId) => {
  const workbook = (workbooks || []).find(({ id }) => id === parseInt(workbookId));
  return {
    id: workbook.id,
    year: workbook.year,
    iso: workbook.iso,
    status: workbook.status,
    date_created: workbook.date_created,
  };
};

// AddCityModal
export const renderCountryOptions = (countries) => {
  return (countries || []).map(country => ({
    value: country.id,
    label: country.name,
  })).sort((a, b) => a.label.localeCompare(b.label));
};

export const updateFiltered = (allCities, selected) => {
  return selected
    .reduce((acc, item) => {
      const city = allCities.find(({ id }) => id === item.id);
      if (city) {
        acc.push(city);
      }
      return acc;
    }, [])
    .sort((a, b) => a.title.localeCompare(b.title));
};
