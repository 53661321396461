import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getCertColour, getCertLevel } from '../../utils/generalHelpers';
import WorkbookGridPanelCertBar from './WorkbookGridPanelCertBar';

const WorkbookGridPanelCert = ({ countObject, workbooks, workbook_id }) => {
  const { core, supporting, indicator } = countObject;

  const getCert = (workbookId, workbooks) => {
    for (let workbook of workbooks) {
      if (workbook.id === workbookId) {
        return workbook.certifications[0];
      }
    }
  };

  const getIsoId = (workbookId, workbooks) => {
    for (let workbook of workbooks) {
      if (workbook.id === workbookId) {
        return workbook.iso;
      }
    }
  };

  let cert_awarded;
  if (workbooks) {
    cert_awarded = getCert(workbook_id, workbooks);
  }
  const iso_id = getIsoId(workbook_id, workbooks);

  return (
    <Fragment>
      <div className='widget-chart-info m-b-30'>
        <h4 className='widget-chart-info-title'>Certification Level</h4>
        {!!cert_awarded && (
          <span
            className={
              'fa fa-award fa-4x pull-right text-gradient ' +
              getCertColour(cert_awarded.certification).class
            }
          />
        )}

        <p className='widget-chart-info-desc'>
          {cert_awarded
            ? 'Awarded level of certification based on Indicator completion'
            : 'Estimated level of certification based on current Indicator completion'}
        </p>
        <div className='f-s-16 align-middle'>
          {cert_awarded
            ? getCertColour(cert_awarded.certification).title
            : getCertLevel(core, supporting, indicator, iso_id)}
        </div>
      </div>
      <hr />
      <div className='widget-chart-info'>
        <h4 className='widget-chart-info-title'>Indicators</h4>
        <p className='widget-chart-info-desc'>Indicator Distribution</p>
        {iso_id === 2 || iso_id === 1 ? (
          <Fragment>
            <WorkbookGridPanelCertBar
              countObject={countObject}
              barType='Core'
              key='wbgpcb1'
            />
            <WorkbookGridPanelCertBar
              countObject={countObject}
              barType='Supporting'
              key='wbgpcb2'
            />
            <WorkbookGridPanelCertBar
              countObject={countObject}
              barType='Profile'
              key='wbgpcb4'
            />
          </Fragment>
        ) : (
          <WorkbookGridPanelCertBar
            countObject={countObject}
            barType='Indicator'
            key='wbgpcb5'
          />
        )}
        <hr />
      </div>
    </Fragment>
  );
};
WorkbookGridPanelCert.propTypes = {
  countObject: PropTypes.object,
  workbooks: PropTypes.array,
  workbook_id: PropTypes.number
};
export default WorkbookGridPanelCert;
