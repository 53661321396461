import {
  GET_CONTINENTS,
  ADD_CONTINENT,
  DELETE_CONTINENT,
  SET_CURRENT,
  UPDATE_CONTINENT,
  FILTER_CONTINENTS,
  CLEAR_CURRENT,
  CLEAR_FILTER,
  CONTINENT_ERROR,
  CLEAR_CONTINENTS
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_CONTINENTS:
      return {
        ...state,
        continents: action.payload,
        loading: false
      };
    case ADD_CONTINENT:
      return {
        ...state,
        continents: [action.payload, ...state.continents],
        loading: false
      };
    case UPDATE_CONTINENT:
      return {
        ...state,
        continents: state.continents.map(continent =>
          continent.id === action.payload.id ? action.payload : continent
        ),
        loading: false
      };
    case DELETE_CONTINENT:
      return {
        ...state,
        continents: state.continents.filter(
          continent => continent.id !== action.payload
        ),
        loading: false
      };
    case CLEAR_CONTINENTS:
      return {
        ...state,
        continents: null,
        filtered: null,
        error: null,
        current: null
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null
      };
    case FILTER_CONTINENTS:
      return {
        ...state,
        filtered: state.continents.filter(continent => {
          const regex = new RegExp(`${action.payload}`, 'gi');
          return continent.name.match(regex) || continent.code.match(regex);
        })
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null
      };
    case CONTINENT_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
