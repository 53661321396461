import React from 'react';
import { Redirect } from 'react-router-dom';
import Dashboard from '../components/pages/Home';
import About from '../components/pages/About';
import Users from '../components/pages/Users';
import CityWorkbooks from '../components/pages/CityWorkbooks';
import Landing from '../components/pages/Landing';
import Map from '../components/pages/Map';
import City from '../components/pages/City';
import Workbook from '../components/pages/Workbook';
import Indicator from '../components/pages/Indicator';
import Vis from '../components/pages/Vis';
import Iso from '../components/pages/Iso';
import WorkbooksAdmin from "../components/pages/WorkbooksAdmin";

const proutes = [
  {
    path: '/',
    exact: true,
    title: 'Landing',
    component: () => <Redirect to='/landing' />,
  },
  // {
  //   path: '/',
  //   exact: true,
  //   title: 'Workbooks',
  //   component: () => <Redirect to='/workbooks' />
  // },
  {
    path: '/landing',
    exact: true,
    title: 'Landing',
    component: () => <Landing />,
  },
  {
    path: '/dashboard',
    exact: true,
    title: 'Dashboard',
    component: () => <Dashboard />,
  },
  {
    path: '/about',
    exact: true,
    component: () => <About />,
  },
  {
    path: '/users',
    exact: true,
    title: 'Users Admin',
    component: () => <Users />,
  },
  {
    path: '/workbooks-admin',
    exact: true,
    title: 'Workbook Admin',
    component: () => <WorkbooksAdmin />,
  },
  {
    path: '/workbooks',
    exact: true,
    title: 'City Workbooks',
    component: () => <CityWorkbooks />,
  },
  {
    path: '/city/:cityId',
    title: 'City',
    component: (cityId) => <City />,
  },
  {
    path: '/iso/:isoId/workbook/:workbookId/',
    title: 'Workbook',
    exact: true,
    component: ({ isoId, workbookId }) => <Workbook />,
  },
  {
    path:
      '/iso/:isoId/workbook/:workbookId/theme/:themeId/indicator/:indicatorId',
    title: 'Indicator',
    exact: true,
    component: ({ isoId, workbookId, indicatorId, themeId }) => <Indicator />,
  },
  {
    path: '/map',
    exact: true,
    title: 'Map',
    component: () => <Map />,
  },
  {
    path: '/vis',
    exact: true,
    title: 'Vis',
    component: () => <Vis />,
  },
  {
    path: '/iso',
    exact: true,
    title: 'ISO Admin',
    component: () => <Iso />,
  },
];

export default proutes;
