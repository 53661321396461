import React, { useState, useContext, Fragment } from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import PageContext from '../../../config/pageContext';

const SidebarNavList = ({ data, expand, active, clicked, getIndicator }) => {
  const pageContext = useContext(PageContext);
  const { handleSidebarOnMouseOver, handleSidebarOnMouseOut } = pageContext;

  const [menustate, setState] = useState({
    active: -1,
    clicked: -1,
  });

  const handleExpand = (e, i, match) => {
    e.preventDefault();

    if (menustate.clicked === -1 && match) {
      setState((menustate) => ({
        active: -1,
        clicked: 1,
      }));
    } else {
      setState((menustate) => ({
        active: menustate.active === i ? -1 : i,
        clicked: 1,
      }));
    }
  };

  const handleClick = (iId) => {
    getIndicator(iId);
  };

  var icon = data.icon && <i className={data.icon}></i>;
  var img = data.img && (
    <div className='icon-img'>
      <img src={data.img} alt='' />
    </div>
  );
  var caret = data.children && !data.badge && <b className='caret'></b>;
  var label = data.label && (
    <span className='label label-theme m-l-5'>{data.label}</span>
  );
  var badge = data.badge && (
    <span className='badge pull-right'>{data.badge}</span>
  );
  var title = data.title && (
    <span>
      {data.title} {label}
    </span>
  );

  return (
    <Fragment>
      <Route
        path={data.path}
        exact={data.exact}
        children={({ match }) => (
          <li
            className={
              (match ? 'active ' : '') +
              (active || (clicked === -1 && match) ? 'expand ' : 'closed ') +
              (data.children ? 'has-sub ' : '')
            }
          >
            {data.children ? (
              <Link
                to={data.path}
                onMouseOver={(e) => handleSidebarOnMouseOver(e, data)}
                onMouseOut={(e) => handleSidebarOnMouseOut(e, data)}
                onClick={expand}
              >
                {img} {icon} {badge} {title} {caret}
              </Link>
            ) : (
              <Link to='#' onClick={(e) => handleClick(data.iId)}>
                {img} {icon} {badge} {title} {caret}
              </Link>
            )}
            {data.children && (
              <ul className={'sub-menu '}>
                {data.children &&
                  data.children.map((submenu, i) => (
                    <SidebarNavList
                      data={submenu}
                      key={i}
                      expand={(e) => handleExpand(e, i, match)}
                      active={i === menustate.active}
                      clicked={menustate.clicked}
                      getIndicator={getIndicator}
                    />
                  ))}
              </ul>
            )}
          </li>
        )}
      />
    </Fragment>
  );
};

export default withRouter(SidebarNavList);
