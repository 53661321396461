import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import IndicatorMainValueParentChildItem
  from './IndicatorMainValueParentChildItem';
import IndicatorMainValueParentChildRatio
  from './IndicatorMainValueParentChildRatio';

const IndicatorMainValueParentChild = (props) => {
  const {
    user,
    indicatorStatus,
    childIndicator,
    updateAndGetChildIndicators,
    width,
    is_disabled,
    has_aggregate,
    aggregate_selected,
    table_columns,
    index,
  } = props;

  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    const questions = childIndicator.questions
      .sort((a, b) => a.sort_order - b.sort_order);

    // We need a way to only keep invisible questions that are not at the end of the array
    const lastVisibleIndex = questions.map(q => !!q.is_visible).lastIndexOf(true);

    setQuestions(questions.slice(0, lastVisibleIndex + 1));
  }, [childIndicator]);

  return (
    <Fragment>
      {childIndicator.is_visible && (
        <div className='row p-t-10 b-t-1 p-b-10' key={childIndicator.id}>
          <div
            className="f-s-11 overflow-hidden table-cell"
            style={{ width }}
          >
            {' '}
            {childIndicator.requirements ? (
              <Tooltip
                direction='right'
                content={
                  <Fragment>
                    <div className='width-200'>
                      <div className='tip-heading text-white b-b-1 '>
                        {childIndicator.name}
                      </div>
                      <span>{childIndicator.requirements}</span>
                    </div>
                  </Fragment>
                }
              >
                {childIndicator.name}
                <i className='fa fa-info-circle' />
              </Tooltip>
            ) : (
              <Tooltip
                direction='right'
                content={
                  <Fragment>
                    <div className='width-100'>
                      <div className='tip-heading text-white'>
                        {childIndicator.name}
                      </div>
                    </div>
                  </Fragment>
                }
              >
                {/* TODO: this is really not the way to do this... */}
                {([443, 441, 514, 515].includes(childIndicator.id)) && (
                  <i className='fa fa-long-arrow-alt-right' />
                )}
                {' '}
                {childIndicator.name}
              </Tooltip>
            )}
          </div>
          {table_columns && table_columns.reduce((acc, col) => {
            if (col.rows?.length > index) {
              return [...acc, <div
                key={acc.length}
                className="f-s-11 table-cell"
                style={{ width }}
              >
                {col.rows[index]}
              </div>];
            }

            return acc;
          }, [])}
          {!!questions && questions
            .filter(
              (question) =>
                question.qtype === 'Numerator' ||
                question.qtype === 'Additional' ||
                question.qtype === 'Question'
            )
            .map((question) => (
              <Fragment key={question.id}>
                {childIndicator.is_ratio ? (
                  <IndicatorMainValueParentChildRatio
                    user={user}
                    indicatorStatus={indicatorStatus}
                    question={question}
                    updateAndGetChildIndicators={
                      updateAndGetChildIndicators
                    }
                      is_totals={childIndicator.is_totals}
                      is_ratio={childIndicator.is_ratio}
                    />
                  ) : (
                    <IndicatorMainValueParentChildItem
                      indicatorStatus={indicatorStatus}
                      question={question}
                      updateAndGetChildIndicators={
                        updateAndGetChildIndicators
                      }
                      is_totals={childIndicator.is_totals}
                      width={width}
                      is_disabled={is_disabled}
                      parent_id={childIndicator.parent_id}
                      has_aggregate={has_aggregate}
                      aggregate_selected={aggregate_selected}
                    />
                )}
              </Fragment>
            ))}
        </div>
      )}
    </Fragment>
  );
};

IndicatorMainValueParentChild.propTypes = {
  user: PropTypes.object,
  width: PropTypes.string,
  is_disabled: PropTypes.bool,
  isParent: PropTypes.bool,
  indicatorStatus: PropTypes.array,
  childIndicator: PropTypes.object,
  updateAndGetChildIndicators: PropTypes.func,
};
export default IndicatorMainValueParentChild;
