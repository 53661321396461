import React, { Fragment, useContext, useEffect, useState } from "react";
import CityWorkbookContext
  from "../../context/cityworkbook/cityWorkbookContext";
import { Link } from "react-router-dom";
import { Panel, PanelBody, PanelFooter } from "../layout/panel/panel";
import Tooltip from "react-tooltip-lite";
import Select from "react-select";
import * as ReactDOMServer from "react-dom/server";

const isos = {
  1: "ISO37120:2014",
  2: "ISO37120",
  3: "ISO37122",
  4: "ISO37123",
};

const shadeColor = (color, amount) => '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));

const dateDiff = (startingDate, endingDate) => {
  let startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
  if (!endingDate) {
    endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
  }
  let endDate = new Date(endingDate);
  if (startDate > endDate) {
    const swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  const startYear = startDate.getFullYear();
  const february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
  const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  let yearDiff = endDate.getFullYear() - startYear;
  let monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  let dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }

  if (yearDiff > 0) {
    return yearDiff + ' years, ' + monthDiff + ' month' + (monthDiff === 1 ? ", " : "s, ") + dayDiff + ' day' + (dayDiff === 1 ? "" : "s");
  } else if (monthDiff > 0) {
    return monthDiff + ' month' + (monthDiff === 1 ? ", " : "s, ") + dayDiff + ' day' + (dayDiff === 1 ? "" : "s");
  } else {
    return dayDiff + ' day' + (dayDiff === 1 ? "" : "s");
  }
};

const DownIcon = ({ bold }) => {
  return (
    <div className={""}>
      <svg xmlns="http://www.w3.org/2000/svg"
           className={"ml-1 sort-icon" + (bold ? "-bold" : "")}
           fill="none"
           viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    </div>
  );
};

const UpIcon = ({ bold }) => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg"
           className={"ml-1 sort-icon" + (bold ? "-bold" : "")}
           fill="none"
           viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
      </svg>
    </div>
  );
};

const WorkbooksAdminDash = () => {
  const {
    getWorkbooks,
    updateWorkbook,
    cities,
  } = useContext(CityWorkbookContext);
  const [seeHidden, setSeeHidden] = useState(false);
  const [updating, setUpdating] = useState(false);
  const defaultFilters = {
    city: [],
    iso: [],
    year: [],
    date: [],
    users: []
  };
  const [filters, setFilters] = useState({ ...defaultFilters });
  const [sortBy, setSortBy] = useState(null);
  const [sortReverse, setSortReverse] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    getWorkbooks(true);
    // eslint-disable-next-line
  }, []);

  const tabs = [
    // Draft tab
    {
      name: "Draft",
      status: [1],
      buckets: [
        {
          name: 'In draft for 120+ days',
          color: "#FFCCBC",
          workbooks: [],
        },
        {
          name: 'In draft for 90-120 days',
          color: "#FFE0B2",
          workbooks: [],
        },
        {
          name: 'In draft for <90 days',
          color: "#F5F5F5",
          workbooks: [],
        },
      ],
      getBucketIndex: (diff) => diff > 120 ? 0 : diff >= 90 ? 1 : 2,
      additionalFields: []
    },
    // Review tab
    {
      name: "Review",
      status: [2, 3],
      buckets: [
        {
          name: 'In review for 20/31+ days',
          color: "#FFCCBC",
          workbooks: [],
        },
        {
          name: 'In review for 14+ days',
          color: "#FFE0B2",
          workbooks: [],
        },
        {
          name: 'In review for <14 days',
          color: "#F5F5F5",
          workbooks: [],
        },
      ],
      getBucketIndex: (diff, status) => {
        if (status === 2) {
          return diff > 20 ? 0 : diff >= 14 ? 1 : 2;
        }

        return diff > 31 ? 0 : diff >= 14 ? 1 : 2;
      },
      additionalFields: ["users", "review1", "review1r", "review2", "review2r", "review3", "review3r"]
    },
    // Verification tab
    {
      name: "Verification",
      status: [4, 5],
      buckets: [
        {
          name: 'In verification for 21/31+ days',
          color: "#FFCCBC",
          workbooks: [],
        },
        {
          name: 'In verification for 15+ days',
          color: "#FFE0B2",
          workbooks: [],
        },
        {
          name: 'In verification for <15 days',
          color: "#F5F5F5",
          workbooks: [],
        },
      ],
      getBucketIndex: (diff, status) => {
        if (status === 4) {
          return diff > 21 ? 0 : diff >= 15 ? 1 : 2;
        }

        return diff > 31 ? 0 : diff >= 15 ? 1 : 2;
      },
      additionalFields: ["users", "verify1", "verify1r", "verify2", "verify2r", "verify3", "verify3r"]
    },
  ];

  (cities || []).reduce((acc, city) => [
    ...acc,
    ...(city.workbooks || []).map(workbook => ({
      ...workbook,
      city: city.name
    }))
  ], []).forEach(workbook => {
    const tab = tabs.find(({ status }) => status.includes(workbook.status));
    if (!tab) {
      return;
    }

    const dateNow = new Date();
    const dateCreated = new Date(workbook.date_created);

    // Figure out the workbook status log
    const statusChangeLog = (workbook.status_change_log || [])
      .reduce((acc, { description, timestamp }) => {
        const id = description.slice(-1);
        acc[id] = [
          ...(acc[id] || []),
          timestamp
        ];

        return acc;
      }, {});
    Object.keys(statusChangeLog)
      .forEach(key => statusChangeLog[key].sort())

    const getStatusDiffDate = (statusId, index) => {
      let date = (statusChangeLog[statusId] || [])[index];

      if (date) {
        return new Date(date);
      }

      return null;
    }

    const getStatusDiff = (statusId, index, previousStatusId, previousIndex) => {
      const date = getStatusDiffDate(statusId, index);
      const previousDate = getStatusDiffDate(previousStatusId, previousIndex) || dateNow;

      if (date) {
        return date.toLocaleDateString("sv") + " (" + dateDiff(previousDate, date) + ")"
      }

      return "";
    };

    const cell = {
      id: workbook.id + "",
      city: workbook.city,
      iso: isos[workbook.iso] + "",
      year: workbook.year + "",
      date: dateCreated.toLocaleDateString("sv") + " (" + dateDiff(dateNow, dateCreated) + ")",
      hidden: !!workbook.admin_table_hidden,
      users: workbook.assigned_users.map(({ name, is_archived }) =>
        is_archived ? <strike>{name}</strike> : name),
      users_string: workbook.assigned_users.map(({ name }) => name),
      // Review specific fields
      review1: getStatusDiff(2, 0),
      review1r: getStatusDiff(3, 0, 2, 0),
      review2: getStatusDiff(2, 1, 3, 0),
      review2r: getStatusDiff(3, 1, 2, 1),
      review3: getStatusDiff(2, 2, 3, 1),
      review3r: getStatusDiff(3, 2, 2, 2),
      // Verification specific fields
      verify1: getStatusDiff(4, 0),
      verify1r: getStatusDiff(5, 0, 4, 0),
      verify2: getStatusDiff(4, 1, 5, 0),
      verify2r: getStatusDiff(5, 1, 4, 1),
      verify3: getStatusDiff(4, 2, 5, 1),
      verify3r: getStatusDiff(5, 2, 4, 2),
    };

    let diffDate = dateCreated;
    let diffPreviousDate = dateNow;
    if (workbook.status !== 1) {
      let index = 0;
      diffDate = null;
      for (let i = 2; i >= 0; i--) {
        if (!diffDate) {
          diffDate = getStatusDiffDate(workbook.status, i);
          index = i;
        }
      }

      if (workbook.status % 2 === 0) {
        if (index - 1 < 0) {
          diffPreviousDate = dateNow;
        } else {
          diffPreviousDate = getStatusDiffDate(workbook.status + 1, index - 1);
        }
      } else {
        diffPreviousDate = getStatusDiffDate(workbook.status - 1, index);
      }
    }
    const diff = Math.round((diffPreviousDate - (diffDate || dateNow)) / (1000 * 60 * 60 * 24));

    const bucketIndex = tab.getBucketIndex(diff, workbook.status);
    const bucket = tab.buckets[bucketIndex];
    cell.bucket = bucket.name;
    bucket.workbooks.push(cell);
  });

  const toggleWorkbookHide = async (workbookId) => {
    const workbook = (cities || [])
      .reduce((acc, { workbooks }) => [...acc, ...workbooks], [])
      .find(({ id }) => id === workbookId);

    if (workbook) {
      setUpdating(true);
      workbook.admin_table_hidden = !workbook.admin_table_hidden;
      await updateWorkbook(workbook).finally(() => setUpdating(false));
    }
  };

  const headerFilterCell = (minWidth, label, name, additional, sortByName = name, sticky) => {
    if (additional && !tabs[tabIndex].additionalFields.includes(name)) {
      return null;
    }

    const regex = /(<([^>]+)>)/ig;
    const options = [...new Set(
      ((tabs[tabIndex] || {}).buckets || [])
        .reduce((acc, bucket) => [...acc, ...bucket.workbooks], [])
        .filter(workbook => !workbook.hidden || seeHidden)
        .map(workbook => workbook[name])
        .reduce((acc, field) => {
          if (Array.isArray(field)) {
            return [...acc, ...field]
          }

          return [...acc, field];
        }, [])
    )].map(value => ({
      value: ReactDOMServer.renderToString(value).replace(regex, ''),
      label: value
    }));

    const onSortByClick = () => {
      if (sortBy === sortByName) {
        if (sortReverse) {
          setSortBy(null);
          setSortReverse(false);

          return;
        }
        setSortReverse(!sortReverse);
      } else {
        setSortReverse(false);
      }

      setSortBy(sortByName);
    };
    const isSortedBy = sortBy === sortByName;

    let style = {
      minWidth,
      backgroundColor: "white"
    };
    if (sticky) {
      style = {
        ...style,
        position: "sticky",
        zIndex: 5,
        left: 0
      };
    }

    return (
      <th style={style}>
        <div
          className={"d-flex cursor-pointer pb-2 pl-1"}
          onClick={onSortByClick}
        >
          {label}
          {isSortedBy ? (
              sortReverse ? <DownIcon bold /> : <UpIcon bold />) :
            <DownIcon />}
        </div>
        <Select
          value={(filters[name] || []).map(value => ({ value, label: value }))}
          name={name}
          isSearchable
          isMulti
          className={"width-full"}
          options={options}
          onChange={(values) => {
            setFilters({
              ...filters,
              [name]: (values || []).map(({ value }) => value)
            });
          }}
        />
      </th>
    );
  };

  const tableCell = (name, value, backgroundColor, additional, sticky) => {
    if (additional && !tabs[tabIndex].additionalFields.includes(name)) {
      return null;
    }
    if (!Array.isArray(value)) {
      value = [value];
    }

    let style = {
      backgroundColor
    };
    if (sticky) {
      style = {
        backgroundColor: backgroundColor.substring(0, 7),
        position: "sticky",
        zIndex: 5,
        left: 0
      };
    }

    return (
      <td
        className="py-2 pl-4 pr-3"
        style={style}
      >
        {value.map((val, i) => <div key={i}>{val}</div>)}
      </td>
    );
  };

  return (
    <Fragment>
      <ol className='breadcrumb float-xl-right'>
        <li className='breadcrumb-item'>
          <Link to='/workbooks-admin'>Admin</Link>
        </li>
        <li className='breadcrumb-item active'>Workbooks Admin</li>
      </ol>
      <h1 className='page-header'>
        Workbooks Admin
      </h1>
      <div>
        <Panel theme='default'>
          <PanelBody style={{ maxHeight: "80vh", paddingTop: 0 }}
                     className="user-dash">
            <div style={{ minHeight: "85vh" }}>
              <table className='table table-striped m-b-0 workbooks-admin-table'
                     style={{ borderCollapse: "separate", borderSpacing: 0 }}>
                <thead className='text-white' style={{
                  position: "sticky",
                  top: 0,
                  background: "white",
                  zIndex: 6
                }}>
                <tr style={{ height: 1 }}>
                  {headerFilterCell(200, "City", "city", false, "city", true)}
                  {headerFilterCell(150, "ISO", "iso")}
                  {headerFilterCell(150, "Year", "year")}
                  {headerFilterCell(200, "Assigned to", "users", true)}
                  {/* Review specific columns */}
                  {headerFilterCell(200, "Submitted review #1", "review1", true)}
                  {headerFilterCell(200, "Returned review #1", "review1r", true)}
                  {headerFilterCell(200, "Submitted review #2", "review2", true)}
                  {headerFilterCell(200, "Returned review #2", "review2r", true)}
                  {headerFilterCell(200, "Submitted review #3", "review3", true)}
                  {headerFilterCell(200, "Returned review #3", "review3r", true)}
                  {/* Verification specific columns */}
                  {headerFilterCell(200, "Submitted verification #1", "verify1", true)}
                  {headerFilterCell(200, "Returned verification #1", "verify1r", true)}
                  {headerFilterCell(200, "Submitted verification #2", "verify2", true)}
                  {headerFilterCell(200, "Returned verification #2", "verify2r", true)}
                  {headerFilterCell(200, "Submitted verification #3", "verify3", true)}
                  {headerFilterCell(200, "Returned verification #3", "verify3r", true)}
                  {/* Workbook open date */}
                  {headerFilterCell(200, "Opened at", "bucket", false, "date")}
                  <th
                    className={"clickable"}
                    onClick={() => setSeeHidden(!seeHidden)}
                  >
                    <Tooltip
                      arrow={false}
                      hoverDelay={0}
                      content={
                        <div>{seeHidden ? "Click to hide hidden workbooks" : "Click to show hidden workbooks"}</div>}
                    >
                      <i
                        className={"fa " + (seeHidden ? "fa-eye" : "fa-eye-slash")} />
                    </Tooltip>
                  </th>
                </tr>
                </thead>
                <tbody>
                {tabs[tabIndex].buckets.map((bucket) => (
                  <Fragment key={bucket.name}>
                    <tr>
                      <th
                        colSpan="2"
                        scope="colgroup"
                        style={{
                          backgroundColor: bucket.color,
                          position: "sticky",
                          zIndex: 5,
                          left: 0
                        }}
                        className="h4"
                      >
                        {bucket.name}
                      </th>
                      <th
                        colSpan="100%"
                        scope="colgroup"
                        style={{
                          backgroundColor: bucket.color
                        }}
                      />
                    </tr>
                    {!bucket.workbooks.length && (
                      <tr>
                        <td
                          colSpan="100%"
                          className="py-2 pl-4 pr-3"
                          style={{
                            backgroundColor: bucket.color + "80",
                            position: "sticky",
                            zIndex: 5,
                            left: 0
                          }}
                        >
                          No workbooks here
                        </td>
                      </tr>
                    )}
                    {bucket.workbooks
                      .filter((workbook) => {
                        if (workbook.hidden && !seeHidden) {
                          return false;
                        }

                        const filterOut = Object.keys(filters)
                          .find(filterName => {
                            const filtered = filters[filterName];
                            if (!filtered.length) {
                              return false;
                            }

                            let value = workbook[filterName + "_string"];
                            if (!value) {
                              value = workbook[filterName];
                            }
                            if (Array.isArray(value)) {
                              return !filtered.some(f => value.includes(f));
                            }
                            return !filtered.includes(value);
                          });
                        return !filterOut;
                      })
                      .sort((a, b) => {
                        if (sortBy != null) {
                          let aField = a[sortBy];
                          let bField = b[sortBy];

                          if (aField && bField) {
                            if (Array.isArray(aField)) {
                              aField = aField.join(", ");
                              bField = bField.join(", ");
                            }
                            aField += "";
                            bField += "";

                            return aField.localeCompare(bField) * (sortReverse ? -1 : 1)
                          }
                        }

                        return 0;
                      })
                      .map((workbook, index) => {
                        const backgroundColor = shadeColor(bucket.color, index % 2 === 0 ? 10 : -10);
                        return (
                          <tr key={index}>
                            {tableCell("city", workbook.city, backgroundColor, false, true)}
                            {tableCell("iso", workbook.iso, backgroundColor)}
                            {tableCell("year", workbook.year, backgroundColor)}
                            {tableCell("users", workbook.users, backgroundColor, true)}
                            {/* Review specific columns */}
                            {tableCell("review1", workbook.review1, backgroundColor, true)}
                            {tableCell("review1r", workbook.review1r, backgroundColor, true)}
                            {tableCell("review2", workbook.review2, backgroundColor, true)}
                            {tableCell("review2r", workbook.review2r, backgroundColor, true)}
                            {tableCell("review3", workbook.review3, backgroundColor, true)}
                            {tableCell("review3r", workbook.review3r, backgroundColor, true)}
                            {/* Verification specific columns */}
                            {tableCell("verify1", workbook.verify1, backgroundColor, true)}
                            {tableCell("verify1r", workbook.verify1r, backgroundColor, true)}
                            {tableCell("verify2", workbook.verify2, backgroundColor, true)}
                            {tableCell("verify2r", workbook.verify2r, backgroundColor, true)}
                            {tableCell("verify3", workbook.verify3, backgroundColor, true)}
                            {tableCell("verify3r", workbook.verify3r, backgroundColor, true)}
                            {/* Workbook open date */}
                            {tableCell("date", workbook.date, backgroundColor)}
                            <td
                              className="py-0 px-0 text-center align-middle"
                              style={{ backgroundColor }}>
                              {seeHidden && (
                                <input
                                  title={workbook.hidden ? "Click to show this workbook" : "Click to hide this workbook"}
                                  type={"checkbox"}
                                  checked={workbook.hidden}
                                  disabled={updating}
                                  onChange={() => {
                                    if (seeHidden) {
                                      return toggleWorkbookHide(workbook.id);
                                    }
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </Fragment>
                ))}
                </tbody>
              </table>
            </div>
          </PanelBody>
          <PanelFooter className={"p-0 bg-grey-transparent-2"}>
            <div className={"d-flex w-50 px-1"}>
              {tabs.map((tab, index) => {
                return (
                  <div
                    key={tab.name}
                    className={"m-t-0 btn btn-block btn-" + (tabIndex === index ? "white" : "primary")}
                    style={{
                      border: "solid 2px",
                      borderBottom: 0,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0
                    }}
                    onClick={() => {
                      setTabIndex(index);
                      setFilters({ ...defaultFilters });
                    }}
                  >
                    {tab.name}
                  </div>
                );
              })}
            </div>
          </PanelFooter>
        </Panel>
      </div>
    </Fragment>
  );
};

export default WorkbooksAdminDash;