import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import AlertContext from '../../context/alert/alertContext';
import PageContext from '../../config/pageContext';
import AuthContext from '../../context/auth/authContext';

import activepic from './../../assets/img/login-bg/login-bg-17.jpg';
import brandlogo from './../../assets/img/logos/WCCD_Vertical_White.png';

const ConfirmPassword = props => {
  const pageContext = useContext(PageContext);
  const { handleSetPageSidebar, handleSetPageHeader } = pageContext;
  const authContext = useContext(AuthContext);
  const { resetPasswordConfirm, clearErrors, error } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const tokenState = props.match.params.token;
  const [userPassword, setPassword] = useState({
    password: '',
    token: tokenState
  });

  const { password, passwordConfirm } = userPassword;

  const onChange = e =>
    setPassword({
      ...userPassword,
      [e.target.name]: e.target.value
    });
  const onSubmit = e => {
    e.preventDefault();

    if (userPassword.password !== userPassword.passwordConfirm) {
      setAlert("Passwords do not match", 'danger', 3000);
      return;
    }

    resetPasswordConfirm(userPassword);
    setPassword({
      ...userPassword,
      password: '',
      passwordConfirm: '',
    });
  };

  useEffect(() => {
    handleSetPageSidebar(false);
    handleSetPageHeader(false);

    if (error) {
      if (error.status === 'OK') {
        setTimeout(() => props.history.push('/login'), 2000);
      } else {
        if (error.response.data.password) {
          for (let i = 0; i < error.response.data.password.length; i++) {
            setAlert(error.response.data.password, 'danger', 3000);
          }
          clearErrors();
        } else {
          if (error.response.data.status) {
            setAlert(
              'Token expired. Please reset your password again.',
              'danger',
              4000
            );
            setTimeout(() => props.history.push('/_password'), 2000);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [error]);

  return (
    <Fragment>
      <div className='login-cover'>
        <div
          className='login-cover-image'
          style={{
            backgroundImage: 'url(' + activepic + ')'
          }}
        />
        <div className='login-cover-bg' />
      </div>
      <div className='login login-v2'>
        <div className='login-header'>
          <div className='m-b-50'>
            <img src={brandlogo} alt='' className='width-200' />
          </div>
        </div>
        <div className='login-content'>
          <form onSubmit={onSubmit} className='margin-bottom-0'>
            <div className='text-black-darker m-b-10'>
              Please enter a new password below:
            </div>
            <div className='form-group m-b-20'>
              <input
                type='password'
                name='password'
                value={password}
                onChange={onChange}
                className='form-control form-control-lg'
                placeholder='Password'
                required
              />
            </div>
            <div className='form-group m-b-20'>
              <input
                type='password'
                name='passwordConfirm'
                value={passwordConfirm}
                onChange={onChange}
                className='form-control form-control-lg'
                placeholder='Confirm password'
                required
              />
            </div>
            <div className='login-buttons'>
              <input
                type='submit'
                value='Reset Password'
                className='btn btn-inverse btn-block btn-lg'
              />
            </div>
            <div className='m-t-20 text-black-darker'>
              <strong>
                <Link to='/login'>Back to Login page</Link>
              </strong>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(ConfirmPassword);
