import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { isDisabled } from '../../../utils/generalHelpers';
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorMainValueQuestion = (props) => {
  const {
    user,
    value,
    indicatorValueTypeObject,
    updateValue,
    indicatorStatus,
    setCurrentMainValue,
    calcNum,
    calcDen,
    setValueObject,
    isVisible
  } = props;

  const [toggleInput, setInputState] = useState(false);
  const [mainState, setMainState] = useState(value);
  const { id, main_value, calc_value } = mainState;

  const calcMain = (num, den, type) => {
    let ratio = 0;
    if (den !== 0) {
      ratio = num / den;
    }

    switch (type) {
      case 'Percent':
        return Math.round(((ratio * 100) + Number.EPSILON) * 10000) / 10000;
      default:
        return Math.round((ratio + Number.EPSILON) * 10000) / 10000;
    }
  };

  const autoCalcSave = (num, den, type) => {
    const newValue = calcMain(num.main_value, den.main_value, type);
    const newObject = {
      ...value,
      main_value: newValue,
      calc_value: newValue
    };

    setCurrentMainValue({ ...newObject });
    setMainState({ ...newObject });
    updateValue({ ...newObject });
    setInputState(false);
  };

  const backInTimeSetValue = (val, year) => {
    const newObject = {
      ...value,
      main_value: val,
      calc_value: val,
      year
    };

    setCurrentMainValue({ ...newObject });
    setMainState({ ...newObject });
    updateValue({ ...newObject });
    setInputState(false);
  }

  const onBlur = e => {
    setCurrentMainValue({
      ...value,
      main_value: parseFloat(e.target.value.replace(/,/g, '')),
      calc_value: null
    });
    setMainState({
      ...value,
      main_value: parseFloat(e.target.value.replace(/,/g, '')),
      calc_value: null
    });
    updateValue({
      ...value,
      main_value: parseFloat(e.target.value.replace(/,/g, '')),
      calc_value: null
    });
    setInputState(!toggleInput);
  };

  const onChange = e => {
    setMainState({
      ...value,
      main_value: parseFloat(e.target.value.replace(/,/g, '')),
      calc_value: null
    });
  };

  let status = 0;
  if (indicatorStatus && Object.keys(indicatorStatus).length) {
    status = indicatorStatus[0].status;
  }

  useEffect(() => {
    setCurrentMainValue(value);
    setValueObject(value);
    // eslint-disable-next-line
  }, [value]);

  const number = calc_value || main_value;
  return isVisible && (
    <Fragment>
      <div className='text-center'>
        <NumberFormat
          className='f-s-40 f-w-500 text-center'
          displayType={toggleInput ? 'input' : 'text'}
          decimalScale={Math.abs(number) < 0.01 || toggleInput ? '4' : '2'}
          disabled={isDisabled(status, user.role)}
          thousandSeparator=','
          decimalSeparator='.'
          name='main_value'
          key={id}
          value={number}
          onChange={onChange}
          placeholder='0.0'
          onBlur={onBlur}
          renderText={() => <span className={'f-s-40 f-w-500 text-center'}>
            {Math.round(number * (Math.abs(number) < 0.01 ? 10000 : 100)) / (Math.abs(number) < 0.01 ? 10000 : 100)}
          </span>}
        />
        {indicatorValueTypeObject !== null ? indicatorValueTypeObject.unit : ''}
      </div>
      {status === 1 && !isDisabled(status, user.role) && (
        <div className='text-center'>
          {!!calcNum.main_value &&
            !!calcDen.main_value &&
            !!indicatorValueTypeObject && (
              <button
                className='btn btn-link f-s-10 text-grey-lighter'
                onClick={() =>
                  autoCalcSave(calcNum, calcDen, indicatorValueTypeObject.name)
                }
              >
                auto calculate
              </button>
            )}
          <button
            className='btn btn-link f-s-10 text-grey-lighter'
            onClick={() => setInputState(!toggleInput)}
          >
            {toggleInput ? ' ' : 'enter value directly'}
          </button>
        </div>
      )}
        <div className="position-absolute" style={{ right: 0, top: 10}}>
        <BackInTime
          disabled={(indicatorStatus[0].status !== 1)}
          user={user}
          id={value?.id}
          setValue={backInTimeSetValue}
        />
      </div>
    </Fragment>
  );
};
IndicatorMainValueQuestion.propTypes = {
  user: PropTypes.object,
  value: PropTypes.object,
  indicatorStatus: PropTypes.array,
  updateValue: PropTypes.func,
  setValueObject: PropTypes.func,
  setCurrentMainValue: PropTypes.func,
  calcNum: PropTypes.object,
  calcDen: PropTypes.object,
  indicatorValueTypeObject: PropTypes.object
};
export default IndicatorMainValueQuestion;
