import React from 'react';
import PropTypes from 'prop-types';
import WorkbookGridPanelStatus from './WorkbookGridPanelStatus';
import WorkbookGridPanelCert from './WorkbookGridPanelCert';
import WorkbookPanelSummary from './WorkbookPanelSummary';
import WorkbookLegend from './WorkbookLegend';
import WorkbookPeer from './WorkbookPeer';

const WorkbookGridPanel = (props) => {
  const {
    cityObject,
    isoLabel,
    workbookObject,
    countObject,
    updateWorkbook,
    addCertification,
    user,
    modalState,
    setModalState,
    peerIndicatorsObject,
  } = props;

  const { id, year } = workbookObject;
  const { name, region, country, workbooks, climate } = cityObject;
  return (
    <div
      className='vertical-box-column p-15'
      style={{ width: '25%' }}
    >
      <WorkbookPanelSummary
        name={name}
        region={region}
        country={country}
        isoLabel={isoLabel}
        year={year}
      />
      <hr />
      {!!(peerIndicatorsObject || []).length && (
        <WorkbookPeer
          climate={climate}
          country={country.name}
          region={country.subcontinent}
          peerIndicatorsObject={peerIndicatorsObject}
          workbookObject={workbookObject}
          user={user}
        />
      )}
      <div className='widget-chart-info'>
        <h4 className='widget-chart-info-title'>Workbook Status</h4>
        <p className='widget-chart-info-desc' />
        <WorkbookGridPanelStatus
          workbookObject={workbookObject}
          updateWorkbook={updateWorkbook}
          user={user}
          addCertification={addCertification}
          modalState={modalState}
          setModalState={setModalState}
        />
      </div>
      <hr />
      <WorkbookGridPanelCert
        countObject={countObject}
        workbooks={workbooks}
        workbook_id={id}
      />
      <WorkbookLegend />
    </div>
  );
};

WorkbookGridPanel.propTypes = {
  user: PropTypes.object,
  countObject: PropTypes.object,
  cityObject: PropTypes.object,
  isoLabel: PropTypes.string.isRequired,
  workbookObject: PropTypes.object,
  addCertification: PropTypes.func,
  updateWorkbook: PropTypes.func,
  modalState: PropTypes.bool,
  setModalState: PropTypes.func,
  peerIndicatorsObject: PropTypes.array,
};

export default WorkbookGridPanel;
