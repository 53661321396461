import React, { Fragment, useContext, useEffect } from 'react';
import ContinentItem from './ContinentItem';
import Spinner from '../layout/Spinner';
import ContinentContext from '../../context/continent/continentContext';

const Continents = () => {
  const continentContext = useContext(ContinentContext);

  const { continents, filtered, getContinents, loading } = continentContext;

  useEffect(() => {
    getContinents();
    // eslint-disable-next-line
  }, []);

  if (continents !== null && continents.length === 0 && !loading) {
    return <h4>Add a continent</h4>;
  }

  return (
    <Fragment>
      {continents !== null && !loading ? (
        <Fragment>
          {filtered !== null
            ? filtered.map(continent => (
                <ContinentItem continent={continent} key={continent.id} />
              ))
            : continents.map(continent => (
                <ContinentItem continent={continent} key={continent.id} />
              ))}
        </Fragment>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default Continents;
