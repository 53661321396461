import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageContext from '../../config/pageContext';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import brandlogo from './../../assets/img/logos/WCCD_Vertical_White.png';
import coverGlobal from './../../assets/img/landing/All.png';

const Login = props => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);
  const { handleSetPageSidebar, handleSetPageHeader } = pageContext;

  const { setAlert } = alertContext;
  const { login, error, clearErrors, isAuthenticated, loadUser } = authContext;
  const [user, setUser] = useState({
    email: '',
    password: ''
  });

  const { email, password } = user;

  const onChange = e => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    const body = { email, password };
    login(body);
  };

  useEffect(() => {
    loadUser();
    handleSetPageSidebar(false);
    handleSetPageHeader(false);
    if (error) {
      if (error.non_field_errors) {
        setAlert(error.non_field_errors.join(), 'danger');
      }
      clearErrors();
    }
    return () => {
      handleSetPageSidebar(true);
      handleSetPageHeader(true);
    };
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  return (
    <Fragment>
      <div className='login-cover'>
        <div
          className='login-cover-image'
          style={{ backgroundImage: 'url(' + coverGlobal + ')' }}
        >
        </div>
        <div className='login-cover-bg'/>
      </div>
      <div className='login login-v2'>
        <div className='login-header'>
          <div className='m-b-50'>
            <img src={brandlogo} alt='' className='width-200'/>
          </div>
        </div>
        <div className='login-content'>
          <form onSubmit={onSubmit} className='margin-bottom-0'>
            <div className='form-group m-b-20'>
              <input
                type='email'
                name='email'
                value={email}
                onChange={onChange}
                className='form-control form-control-lg'
                placeholder='Email Address'
                required
              />
            </div>
            <div className='form-group m-b-20'>
              <input
                type='password'
                name='password'
                value={password}
                onChange={onChange}
                className='form-control form-control-lg'
                placeholder='Password'
                required
              />
            </div>
            {/* <div className='checkbox checkbox-css m-b-20'>
              <input type='checkbox' id='remember_checkbox' />
              <label htmlFor='remember_checkbox' className=' text-black-darker'>
                Remember Me
              </label>
            </div> */}
            <div className='login-buttons'>
              <input
                type='submit'
                value='Login'
                className='btn btn-inverse btn-block btn-lg'
              />
            </div>
            <div className='m-t-20 text-right'>
              <strong>
                <Link className='text-black-darker' to='/_password'>
                  Forgot Password?
                </Link>
              </strong>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
