import React from 'react';
import PropTypes from 'prop-types';
import CityWorkbookAdd from './CityWorkbookAdd';
import CityWorkbookFilter from './CityWorkbookFilter';

const CityWorkbookHeader = ({
  filterCities,
  clearFilter,
  filtered,
  isos,
  cities,
  countries,
  addWorkbook,
  addCity,
  findCity,
  clearResults,
  city_results,
  result_loading,
  region_results,
  getRegion,
  user
}) => {
  return (
    <div
      className='m-15 d-flex flex-row justify-content-between flex-wrap'
      style={{ minHeight: 70 }}
    >
      <CityWorkbookFilter
        filterCities={filterCities}
        clearFilter={clearFilter}
        filtered={filtered}
        isos={isos}
      />
      {user.role !== 0 && user.role === 1 ? (
        <CityWorkbookAdd
          isos={isos}
          cities={cities}
          addWorkbook={addWorkbook}
          countries={countries}
          addCity={addCity}
          findCity={findCity}
          city_results={city_results}
          result_loading={result_loading}
          clearResults={clearResults}
          getRegion={getRegion}
          region_results={region_results}
        />
      ) : (
        ''
      )}
    </div>
  );
};
CityWorkbookHeader.propTypes = {
  user: PropTypes.object,
  addCity: PropTypes.func,
  findCity: PropTypes.func,
  getRegion: PropTypes.func,
  filterCities: PropTypes.func,
  clearResults: PropTypes.func,
  addWorkbook: PropTypes.func,
  cities: PropTypes.array,
  countries: PropTypes.array,
  city_results: PropTypes.array,
  region_results: PropTypes.array,
  clearFilter: PropTypes.func,
  isos: PropTypes.array,
  result_loading: PropTypes.bool
};
export default CityWorkbookHeader;
