import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from '../layout/Spinner2';

import dlcloud from '../../assets/img/icons/data-export.png';
import logowm from '../../assets/img/logos/WCCD_Vertical_White.png';
import brandlogo from '../../assets/img/logos/WCCD_Horizontal_White.png';
import { renderThemeColour } from '../../utils/renderIcon';
import * as am4core from '@amcharts/amcharts4/core';
import { Percent } from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/dataviz';
import { drawAgeCohortLine } from "../../utils/renderHelpers";
import { addFootNotes } from "./utils";
// import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

// am4core.useTheme(am4themes_dataviz);
am4core.addLicense('CH200325900523135');

const Styles = styled.div`
  .amcharts-amexport-item {
    border: 0px solid #777;
    background: '../../assets/img/icons/data-export.png';
  }

  .amcharts-amexport-top .amcharts-amexport-item > .amcharts-amexport-menu {
    top: -30px !important;
    right: 2px;
    cursor: pointer;
  }
`;

const getMax = (filtered) => {
  let max = -99999;
  filtered.forEach(item => {
    max = item.main_value > max ? item.main_value : max;
  });
  if (max === 0) {
    return 1;
  }
  return max + Math.abs(max * 0.2);
};

const getMin = (filtered) => {
  let min = 99999;
  filtered.forEach(item => {
    min = item.main_value < min ? item.main_value : min;
  });
  if (min === 0) {
    return 0;
  }
  return min - Math.abs(min * 0.1);
};

const getMaxYOY = (yoyData) => {
  let max = -99999;
  yoyData.forEach(item => {
    max = item.value > max ? item.value : max;
  });
  if (max === 0) {
    return 1;
  }
  return max + Math.abs(max * 0.2);
};

const getMinYOY = (yoyData) => {
  let min = 99999;
  yoyData.forEach(item => {
    min = item.value < min ? item.value : min;
  });
  if (min === 0) {
    return 0;
  }
  return min - Math.abs(min * 0.2);
};

const VisChart = (props) => {
  const {
    isoName,
    visualisationType,
    indicatorCities,
    filteredCities,
    yearOverYearData,
    currIndicator,
    loading,
    user
  } = props;

  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    let chart = am4core.create('chartdiv1', am4charts.XYChart);
    chart.maskBullets = false;
    chart.paddingTop = 30;
    chart.paddingBottom = 30;
    if (visualisationType === 1) {
      chart.cursor = new am4charts.XYCursor();
    }
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.minZoomed = 20;
    chart.scrollbarY.exportable = false;
    chart.zoomOutButton.disabled = true;

    // map year over year data to what the old stuff looks like
    if (yearOverYearData) {
      yearOverYearData.forEach(item => {
        item.label = String(item.year) + " " + String(item.value_year);
        item.value = Math.round((item.value + Number.EPSILON) * 100) / 100;
        item.main_value = item.value;
        item.title = item.label;
      })
    }
    chart.numberFormatter.numberFormat = "#,###.00";

    chart.data = visualisationType === 1 ? filteredCities : yearOverYearData;

    // cap percentage type to 100%
    if (currIndicator.indicator_vtype === "%") {
      chart.data.forEach(data => {
        if (data.main_value > 100) {
          data.value = Math.min(data.value, 100);
          data.main_value = Math.min(data.main_value, 100);
          if (user.role === 1) {
            data.customColor = "#ff0000";
          }
        }
      });
    }

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'title';
    // categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10; // for label spacing
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeDasharray = '1,3';
    if (visualisationType === 1) {
      categoryAxis.renderer.labels.template.rotation = -75;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
    }
    categoryAxis.renderer.labels.template.fontSize = 11;
    categoryAxis.renderer.labels.template.fill = am4core.color('#fff');

    if (yearOverYearData) {
      // Replace with either the certification year, source or keep both
      categoryAxis.renderer.labels.template.adapter.add("textOutput", text => {
        const years = (text || "").split(" ");
        if (years.length <= 1) {
          return text;
        }

        switch (user.yoy_vis_year) {
          case 1:
            return years[1];
          case 2:
            return years[1] + "\n" + years[0];
          case 0:
          default: // default to certification year
            return years[0];
        }
      });
      switch (user.yoy_vis_year) {
        case 1:
          categoryAxis.title.text = "\nSource Year";
          break;
        case 2:
          categoryAxis.title.text = "Source Year\nCertification Year";
          break;
        case 0:
        default: // default to certification year
          categoryAxis.title.text = "\nCertification Year";
      }
      categoryAxis.title.align = "left";
      categoryAxis.title.dy = -38;
      categoryAxis.title.dx = -60;
      categoryAxis.title.fontSize = 12;
      categoryAxis.title.fill = am4core.color('#999');
    }

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (visualisationType === 1) {
      drawAgeCohortLine(valueAxis, currIndicator.indicator_id);
    }
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.minGridDistance = 20;
    if (visualisationType === 1 && currIndicator.indicator_vtype && currIndicator.indicator_vtype !== ' ') {
      valueAxis.title.text = `(${currIndicator.indicator_vtype})`;
    } else if (visualisationType === 2 && yearOverYearData && yearOverYearData[0].value_type !== ' ') {
      valueAxis.title.text = `(${yearOverYearData[0].value_type})`;
    }
    valueAxis.title.rotation = 0;
    valueAxis.title.align = 'center';
    valueAxis.title.valign = 'top';
    valueAxis.title.dy = 160;
    valueAxis.title.dx = 0;
    valueAxis.title.fontSize = 12;
    valueAxis.title.fill = am4core.color('#999');
    valueAxis.renderer.labels.template.fill = am4core.color('#999');
    // valueAxis.title.fontWeight = 600;
    valueAxis.min = 0;
    // let axisBreak = valueAxis.axisBreaks.create();
    // axisBreak.startValue = 300;
    // axisBreak.endValue = 1200;
    // axisBreak.breakSize = 0.05;
    // valueAxis.renderer.ticks.template.length = 10;

    let series;
    if (visualisationType === 1 && filteredCities) {
      series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = 0.01;
      series.columns.template.propertyFields.stroke = "customColor";
    } else if (visualisationType === 2 && yearOverYearData) {
      series = chart.series.push(new am4charts.LineSeries());
    }

    series.dataFields.categoryX = 'title';
    series.dataFields.valueY = 'main_value';
    series.dataFields.Label = 'label';

    if (visualisationType === 1) {
      series.tooltipText = `{Label}\n{valueY}${currIndicator.indicator_vtype}`;
    } else if (visualisationType === 2 && yearOverYearData) {
      series.tooltip.disabled = true;
      // series.tooltipText = `{Label}\n{valueY}${yearOverYearData[0].value_type}`;
    }

    series.sequencedInterpolation = true;
    series.fillOpacity = 0;
    series.strokeOpacity = 1;
    series.strokeDashArray = '1,3';
    series.tooltip.pointerOrientation = 'horizontal';
    series.stroke = am4core.color('#fff');

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.radius = 3;
    bullet.fill = am4core.color('#08afdd');
    bullet.stroke = am4core.color('#fff');

    if (visualisationType === 2) {
      const labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{value}";
      labelBullet.label.fill = am4core.color('#fff');
      labelBullet.label.dy = -20;
    }

    if (visualisationType === 1 && filteredCities) {
      valueAxis.min = getMin(filteredCities);
      valueAxis.max = getMax(filteredCities);
    } else if (visualisationType === 2 && yearOverYearData) {
      valueAxis.min = getMinYOY(yearOverYearData);
      valueAxis.max = getMaxYOY(yearOverYearData);
    }
    if (currIndicator.indicator_vtype === "%") {
      valueAxis.max = Math.min(valueAxis.max, 100);
    }

    let title = chart.titles.create();
    if (currIndicator.indicator_type === '3') {
      title.text = `${isoName} Profile Data ${currIndicator.iso_section}`;
    } else {
      title.text = `${isoName} Indicator ${currIndicator.iso_section}`;
    }
    if (visualisationType === 2 && yearOverYearData) {
      title.text = `${isoName} ${currIndicator.iso_section} - ${yearOverYearData[0].city_name} - Year Over Year Trends`;
      if (yearOverYearData[0].indicator_type === "3") {
        title.text += " (Profile Data)";
      }
    }
    title.fontSize = 12;
    title.marginBottom = 50;
    title.align = 'left';

    title.fill = am4core.color('#fff');

    let title2 = chart.titles.create();
    title2.text = currIndicator.indicator_name;
    if (currIndicator.question_name && !currIndicator.main_question) {
      title2.text += " - " + currIndicator.question_name;
    }
    title2.fontSize = 22;
    title2.marginBottom = 15;
    title2.align = 'left';
    title2.wrap = true;
    // title2.maxWidth = 700;
    title2.width = new Percent(70);
    title2.fill = am4core.color(renderThemeColour(currIndicator.theme));
    let title3 = chart.titles.create();
    let title4 = chart.titles.create();

    if (!indicatorCities) {
      title4.text = 'Welcome';
      title4.fontSize = 30;
      title4.marginBottom = 15;
      title4.align = 'left';
      title3.text = 'Please select a Theme and Indicator';
      title3.fontSize = 20;
      title3.marginBottom = 15;
      title3.align = 'left';
    }

    const onExportEvents = [];
    addFootNotes(currIndicator, chart, onExportEvents);

    let watermark = new am4core.Image();
    watermark.href = logowm;
    chart.plotContainer.children.push(watermark);
    watermark.align = 'center';
    watermark.valign = 'top';
    watermark.opacity = 0.1;
    watermark.marginBottom = 35;
    watermark.width = 300;
    watermark.height = 300;

    let watermark2 = new am4core.Image();
    watermark2.href = brandlogo;
    chart.plotContainer.children.push(watermark2);
    watermark2.align = 'right';
    watermark2.valign = 'top';
    watermark2.opacity = 1;
    watermark2.dy = -100;
    watermark2.dx = -20;
    watermark2.width = new Percent(25);
    watermark2.height = 100;

    let star = series.bullets.push(new am4charts.Bullet());
    let image = star.createChild(am4core.Image);
    image.horizontalCenter = 'middle';
    image.verticalCenter = 'bottom';
    image.dy = 12;
    image.y = am4core.percent(100);
    image.width = 20;
    image.propertyFields.href = 'bullet';
    if (visualisationType === 1 && filteredCities) {
      image.tooltipText = series.columns.template.tooltipText;
    }
    // image.propertyFields.fill = 'color';
    image.filters.push(new am4core.DropShadowFilter());

    chart.exporting.menu = new am4core.ExportMenu();

    chart.exporting.menu.items = [
      {
        label: '...',
        menu: [
          { type: 'png', label: 'PNG' },
          { type: 'jpg', label: 'JPG' },
          { type: 'pdf', label: 'PDF' },
          { label: 'Print', type: 'print' },
        ],
      },
    ];
    chart.exporting.filePrefix = `WCCD - ${isoName} Indicator ${currIndicator.iso_section}`;
    chart.exporting.menu.items[0].icon = dlcloud;
    chart.exporting.menu.background = am4core.color('#f00', 0);
    chart.exporting.menu.align = 'right';
    chart.exporting.menu.verticalAlign = 'top';
    chart.exporting.events.on("exportstarted", ev => {
      onExportEvents.forEach(callable => callable());
    }, this);
    let newchart;
    newchart = chart;
    return () => {
      if (newchart) {
        newchart.dispose();
      }
    };
    // eslint-disable-next-line
  }, [refresh, indicatorCities, currIndicator, visualisationType, yearOverYearData]);

  useEffect(() => {
    if (filteredCities && JSON.stringify(filteredCities) !== refresh.filteredCities) {
      setRefresh({
        ...refresh,
        filteredCities: JSON.stringify(filteredCities)
      });
    }
    // eslint-disable-next-line
  }, [filteredCities]);

  return (
    <Styles>
      <div>
        {((filteredCities || []).length !== 0 || yearOverYearData?.length !== 0) && !loading ? (
          <div
            id='chartdiv1'
            style={{ width: '100%', height: '700px' }}
            className='bg-wccd-darker'
          />
        ) : loading ? (
          <Fragment>
            <div
              id='chartdiv1'
              className='hidden'
              style={{ width: '0%', height: '0px', hide: true }}
            />
            <Spinner />
          </Fragment>
        ) : (
          <div
            id='chartdiv1'
            className='hidden'
            style={{ width: '0%', height: '0px', hide: true }}
          />
        )}
      </div>
      {/* <div>
        {filteredCities.length !== 0 ? (
          <div id='chartdiv1' style={{ width: '100%', height: '700px' }}></div>
        ) : (
          <div
            id='chartdiv1'
            className='hidden'
            style={{ width: '1%', height: '0px', hide: true }}
          ></div>
        )}
      </div> */}
    </Styles>
  );
};

VisChart.propTypes = {
  indicatorCities: PropTypes.array,
  loading: PropTypes.bool,
  // getIndicators: PropTypes.func
};

export default VisChart;
