import React, { Fragment } from "react";
import UserItem from "./UserItem";
import PropTypes from "prop-types";

const UserList = (props) => {
  const {
    users,
    loading,
    user,
    updateUser,
    hardDeleteUser,
    cities_list,
    sendResetEmail,
    generateResetUrl
  } = props;

  if (users !== null && users.length === 0 && !loading) {
    return (
      <tr>
        <td>
          <span className="f-s-14 f-w-600">No users found.</span>
        </td>
      </tr>
    );
  }
  return (
    <Fragment>
      {!loading && users !== null ? (
        users.map(userItem => (
          <UserItem
            userItem={userItem}
            // cities_options={cities_options}
            key={userItem.id}
            user={user}
            updateUser={updateUser}
            hardDeleteUser={hardDeleteUser}
            cities_list={cities_list}
            generateResetUrl={generateResetUrl}
            sendResetEmail={sendResetEmail}
          />
        ))
      ) : (
        <tr>
          <td>
            <span className="f-s-14 f-w-600">Loading...</span>
          </td>
        </tr>
      )}
    </Fragment>
  );
};
UserItem.propTypes = {
  users: PropTypes.object,
  // cities_options: PropTypes.array.isRequired,
  cities_list: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  user: PropTypes.object,
  updateUser: PropTypes.func,
  hardDeleteUser: PropTypes.func,
};
export default UserList;
