import {
  SET_CALC_DEN,
  SET_CALC_NUM,
  SET_CURRENT_DEN,
  SET_CURRENT_MAIN,
  SET_CURRENT_NUM,
  SET_LOADING,
  SET_VALUE_OBJECT,
  UPDATE_VALUE,
  VALUE_ERROR
} from '../../types';

export default (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case UPDATE_VALUE:
    case SET_VALUE_OBJECT:
      return {
        ...state,
        valueObject: action.payload,
        loading: false
      };
    case SET_CURRENT_NUM:
      return {
        ...state,
        currentNum: action.payload,
        loading: false
      };
    case SET_CURRENT_DEN:
      return {
        ...state,
        currentDen: action.payload,
        loading: false
      };
    case SET_CALC_NUM:
      return {
        ...state,
        calcNum: action.payload,
        loading: false
      };
    case SET_CALC_DEN:
      return {
        ...state,
        calcDen: action.payload,
        loading: false
      };
    case SET_CURRENT_MAIN:
      return {
        ...state,
        currentMainValue: action.payload,
        loading: false
      };
    case VALUE_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
