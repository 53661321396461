import React from 'react';
import Login from '../components/pages/Login';
import Password from '../components/pages/Password';
import PasswordConfirm from '../components/pages/PasswordConfirm';
import Disclaimer from "../components/pages/Disclaimer";

const routes = [
  {
    path: '/login',
    exact: true,
    title: 'Portal Login',
    component: () => <Login />
  },
  {
    path: '/disclaimer',
    exact: true,
    title: 'WCCD Disclaimer',
    component: () => <Disclaimer />
  },
  {
    path: '/_password',
    exact: true,
    title: 'Retrieve Password',
    component: () => <Password />
  },
  {
    path: '/_password/confirm/:token',
    exact: true,
    title: 'Confirm Password',
    component: ({ token }) => <PasswordConfirm />
  }
];

export default routes;
