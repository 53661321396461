import React, { useState, useContext, useEffect } from 'react';
import ContinentContext from '../../context/continent/continentContext';

const ContinentForm = () => {
  const continentContext = useContext(ContinentContext);

  const {
    addContinent,
    updateContinent,
    clearCurrent,
    current
  } = continentContext;

  useEffect(() => {
    if (current !== null) {
      setContinent(current);
    } else {
      setContinent({
        name: '',
        code: ''
      });
    }
  }, [continentContext, current]);

  const [continent, setContinent] = useState({
    name: '',
    code: ''
  });

  const { name, code } = continent;

  const onChange = e =>
    setContinent({ ...continent, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (current === null) {
      addContinent(continent);
    } else {
      updateContinent(continent);
    }
    clearAll();
  };

  const clearAll = () => {
    clearCurrent();
  };

  return (
    <form onSubmit={onSubmit}>
      <h2 className='text-primaty'>
        {current ? 'Edit Continent' : 'Add Continent'}
      </h2>
      <input
        type='text'
        placeholder='name'
        name='name'
        value={name}
        onChange={onChange}
      />
      <input
        type='text'
        placeholder='code'
        name='code'
        value={code}
        onChange={onChange}
      />
      <div>
        <input
          type='submit'
          value={current ? 'Update Continent' : 'Add Continent'}
          className='btn btn-primary btn-block'
        />
      </div>
      {current && (
        <div>
          <button className='btn btn-light btn-block' onClick={clearAll}>
            Clear
          </button>
        </div>
      )}
    </form>
  );
};

export default ContinentForm;
