import React from 'react';
import PropTypes from 'prop-types';

const UserFilter = ({ loadUsers, searchData, setSearchData }) => {
  const onChange = e => {
    setSearchData({
      ...searchData,
      search: e.target.value
    });
  };
  const onBlur = () => {
    loadUsers({ page: 1 });
  };
  const onSearch = e => {
    e.preventDefault();
    loadUsers({ page: 1 });
  };
  const onClear = () => {
    loadUsers({ page: 1, search: null });
  };
  const handleEnter = e => {
    if (e.keyCode === 13) {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1].focus();
      e.preventDefault();
    }
  };

  return (
    <div className='width-600'>
      <form>
        <div className='input-group input-group-lg'>
          <input
            className='form-control-lg b-0 width-450'
            type='text'
            placeholder='Search user by name or email...'
            onChange={onChange}
            value={searchData.search || ""}
            onBlur={onBlur}
            onKeyDown={handleEnter}
          />
          <div className='input-group-append'>
            <button className='btn btn-default btn-lg b-0' onClick={onSearch}>
              <i className='fa fa-search' />
            </button>
            <button className='btn btn-default btn-lg b-0' onClick={onClear}>
              <i className='fa fa-times' />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

UserFilter.propTypes = {
  loadUsers: PropTypes.func
};

export default UserFilter;
