import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from 'react-radio-group';
import { isDisabled } from '../../../utils/generalHelpers';
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorAuditParametersYesNoP = ({
  user,
  value,
  indicatorStatus,
  updateValue
}) => {
  const [yesNoState, setYesNoState] = useState(value);
  const { id, text_value } = yesNoState;

  const onChange = radSelect => {
    updateValue({ ...yesNoState, text_value: radSelect });
    setYesNoState({ ...yesNoState, text_value: radSelect });
  };

  if (indicatorStatus) {
    var status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      status = indicatorStatus[0].status;
    }
  }
  var roleId = user.role;

  return (
    <div className='radio radio-css radio-inline'>
      <RadioGroup
        name={`text_value${id}`}
        key={id}
        selectedValue={text_value}
        onChange={onChange}
      >
        <div className='radio radio-css radio-inline'>
          <Radio
            value='yes'
            name={`radio_css_inline_${id}`}
            id={`inlineCssRadio1${id}`}
            disabled={isDisabled(status, roleId)}
          />
          <label htmlFor={`inlineCssRadio1${id}`}>Yes</label>
        </div>
        <div className='radio radio-css radio-inline'>
          <Radio
            value='no'
            name={`radio_css_inline_${id}`}
            id={`inlineCssRadio2${id}`}
            disabled={isDisabled(status, roleId)}
          />
          <label htmlFor={`inlineCssRadio2${id}`}>No</label>
        </div>
        <div className='radio radio-css radio-inline'>
          <Radio
            value='partially'
            name={`radio_css_inline_${id}`}
            id={`inlineCssRadio3${id}`}
            disabled={isDisabled(status, roleId)}
          />
          <label htmlFor={`inlineCssRadio3${id}`}>Partially</label>
        </div>
        <div className='radio radio-css radio-inline'>
          <Radio
            value='not applicable'
            name={`radio_css_inline_${id}`}
            id={`inlineCssRadio4${id}`}
            disabled={isDisabled(status, roleId)}
          />
          <label htmlFor={`inlineCssRadio4${id}`}>Not Applicable</label>
        </div>
        <div style={{ transform: "translate(0, 5px)", display: "inline-block" }}>
          <BackInTime
            disabled={(indicatorStatus[0].status !== 1)}
            user={user}
            id={value?.id}
            setValue={(value) => {
              onChange(value);
            }}
          />
        </div>
      </RadioGroup>
    </div>
  );
};
IndicatorAuditParametersYesNoP.propTypes = {
  user: PropTypes.object,
  value: PropTypes.object,
  updateValue: PropTypes.func,
  indicatorStatus: PropTypes.array
};
export default IndicatorAuditParametersYesNoP;
