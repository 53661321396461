import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../../context/user/userContext';
import AuthContext from '../../context/auth/authContext';
import UserList from '../user/UserList';
import Spinner from '../layout/Spinner';

import {
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
} from '../layout/panel/panel';
import UserFilter from './UserFilter';
import UserAdd from './UserAdd';
import UserPaginate from './UserPaginate';
import CitiesFilter from "./CitiesFilter";
import Settings from "./Settings";
import userFields from "./userFields";

const UserDash = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const userContext = useContext(UserContext);
  const {
    getUsers,
    getWorkbooks,
    addUser,
    users,
    // getCities,
    // cities_options,
    cities_list,
    loading,
    count,
    previous,
    next,
    error,
    updateUser,
    hardDeleteUser,
    generateResetUrl,
    sendResetEmail
  } = userContext;

  const [searching, setSearching] = useState(false);
  const [searchData, setSearchData] = useState({});

  useEffect(() => {
    // getCities();
    getUsers();
    getWorkbooks();
    // eslint-disable-next-line
  }, []);

  const loadUsers = ({ page, search, cities } = {}) => {
    setSearching(true);
    const data = {
      page: page === undefined ? searchData.page : page,
      search: search === undefined ? searchData.search : search,
      cities: cities === undefined ? searchData.cities : cities,
    };
    setSearchData(data);
    getUsers(data.page, data.search, data.cities)
      .finally(() => setSearching(false));
  };

  return (
    <Fragment>
      <ol className='breadcrumb float-xl-right'>
        <li className='breadcrumb-item'>
          <Link to='/users'>Admin</Link>
        </li>
        <li className='breadcrumb-item'>
          <Link to='/users'>Users</Link>
        </li>
        <li className='breadcrumb-item active'>User Management</li>
      </ol>
      <h1 className='page-header'>
        Users <small>Portal User Management</small>
      </h1>
      <Panel theme='default'>
        <PanelHeader noButton={true}>
          <div className='d-flex justify-content-between flex-wrap'>
            <div className="d-flex">
              <UserFilter
                loadUsers={loadUsers}
                searchData={searchData}
                setSearchData={setSearchData}
              />
              <CitiesFilter
                loading={loading || searching}
                citiesList={cities_list}
                loadUsers={loadUsers}
              />
            </div>
            <div className="d-flex">
              <UserAdd
                cities_list={cities_list}
                error={error}
                addUser={addUser}
              />
              <Settings user={user} updateUser={updateUser} />
            </div>
          </div>
        </PanelHeader>
        <PanelBody reload={loading || searching} className="user-dash">
          <div style={{ minHeight: 300 }}>
            {users !== null && !loading && !searching ? (
              <table className='table table-striped m-b-0'
                     style={{ borderCollapse: "separate", borderSpacing: 0 }}>
                <thead className='text-white' style={{
                  position: "sticky",
                  top: -15,
                  background: "white",
                  zIndex: 6
                }}>
                <tr>
                  {Object.entries(userFields)
                    .filter(([key]) => (user.admin_settings || []).includes(key))
                    .map(([key, value]) => {
                      if (key === "name") {
                        return <th
                          style={{
                            position: "sticky",
                            zIndex: 5,
                            left: 0,
                            background: "white",
                            borderRight: "solid gray 1px"
                          }}
                          key={key}>{value.label}</th>
                      }
                      return <th key={key}>{value.label}</th>
                    })}
                </tr>
                </thead>
                <tbody>
                {!!cities_list && (
                  <UserList
                    users={users}
                    loading={loading || searching}
                    user={user}
                    updateUser={updateUser}
                    hardDeleteUser={hardDeleteUser}
                    cities_list={cities_list}
                    generateResetUrl={generateResetUrl}
                    sendResetEmail={sendResetEmail}
                  />
                )}
                </tbody>
              </table>
            ) : (
              <Spinner />
            )}
          </div>
        </PanelBody>
        <PanelFooter>
          <UserPaginate
            count={count}
            previous={previous}
            next={next}
            loadUsers={loadUsers}
            searchData={searchData}
          />
        </PanelFooter>
      </Panel>
    </Fragment>
  );
};

export default UserDash;
