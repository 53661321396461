import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, acceptedDisclaimer, loading } = authContext;

  return (
    <Route
      {...rest}
      render={props => {
        if (!loading) {
          if (!isAuthenticated) return <Redirect to='/login' />;
          if (!acceptedDisclaimer) return <Redirect to='/disclaimer' />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
