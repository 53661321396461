import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import WorkbookGridItem from "./WorkbookGridItem";
import WorkbookGridHeader from "./WorkbookGridHeader";
import WorkbookGridPanel from "./WorkbookGridPanel";
import WorkbookNav from "./WorkbookNav";
import WorkbookClosedBanner from "./WorkbookClosedBanner";
import IndicatorContext from "../../context/indicator/indicatorContext";
import Spinner from "../layout/Spinner";
import { isWorkbookClosed } from "../../utils/generalHelpers";

const WorkbookGrid = (props) => {
  const {
    cityObject,
    isoLabel,
    themeIndicator,
    workbookObject,
    updateWorkbook,
    addCertification,
    countObject,
    loading,
    modalState,
    setModalState,
    user,
    wbId,
    peerIndicatorsObject,
    getPeerIndicators,
  } = props;
  const indicatorContext = useContext(IndicatorContext);
  const { getIsoTheme, getWorkbookCity } = indicatorContext;
  const { workbooks, images } = cityObject;
  const [image, setImage] = useState(null);

  useEffect(() => {
    const image = images?.[Math.floor(Math.random() * images?.length)];
    setImage(image?.url || image?.external_url);
  }, [images]);

  const workbookClosed = isWorkbookClosed(user.role, workbookObject.status);

  return (
    <div className="row">
      <div className="col-lg-12 p-0 m-0">
        <div className="row">
          <div className="col-lg-12">
            <WorkbookGridHeader
              cityObject={cityObject}
              isoLabel={isoLabel}
              workbookObject={workbookObject}
            />
            <WorkbookNav
              workbooks={workbooks}
              getIsoTheme={getIsoTheme}
              getWorkbookCity={getWorkbookCity}
              wbId={wbId}
              loading={loading}
              getPeerIndicators={getPeerIndicators}
            />
            <div className="widget m-b-30 inverse-mode">
              <div className="vertical-box with-grid with-border-top b-0">
                <div
                  className="vertical-box-column widget-chart-content position-relative"
                  style={image && {
                    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" + image + ")",
                    backgroundSize: "cover"
                  }}
                >
                  {themeIndicator !== null && !loading ? (
                    <Fragment>
                      {themeIndicator.map((theme) => (
                        <WorkbookGridItem
                          key={theme.id}
                          theme={theme}
                        />
                      ))}
                      {workbookClosed && <WorkbookClosedBanner
                        text={workbookObject.status === 2
                          ? "Workbook is under WCCD Review"
                          : "Workbook is in Verification with a Third-Party Verifier"}
                      />}
                    </Fragment>
                  ) : (
                    <Spinner />
                  )}
                </div>
                <WorkbookGridPanel
                  workbookObject={workbookObject}
                  isoLabel={isoLabel}
                  cityObject={cityObject}
                  countObject={countObject}
                  updateWorkbook={updateWorkbook}
                  user={user}
                  addCertification={addCertification}
                  modalState={modalState}
                  setModalState={setModalState}
                  peerIndicatorsObject={peerIndicatorsObject}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WorkbookGrid.propTypes = {
  cityObject: PropTypes.object,
  user: PropTypes.object,
  countObject: PropTypes.object,
  isoLabel: PropTypes.string.isRequired,
  themeIndicator: PropTypes.array.isRequired,
  workbookObject: PropTypes.object,
  updateWorkbook: PropTypes.func,
  addCertification: PropTypes.func,
  setModalState: PropTypes.func,
  modalState: PropTypes.bool,
  loading: PropTypes.string,
  wbId: PropTypes.string.isRequired,
  peerIndicatorsObject: PropTypes.array,
};

export default WorkbookGrid;
