import React, { useContext, useReducer } from 'react';
import axios from 'axios';
import { baseUrl } from '../../config/const';
import CityWorkbookContext from './cityWorkbookContext';
import AlertContext from '../alert/alertContext';
import cityWorkbookReducer from './cityWorkbookReducer';
import { setAuthToken } from '../../utils/generalHelpers';
import {
  ADD_CITY,
  ADD_WORKBOOK,
  CLEAR_FILTER,
  CLEAR_RESULTS,
  CLEAR_WORKBOOK,
  CLEAR_WORKBOOKS,
  DELETE_WORKBOOK,
  FILTER_WORKBOOKS,
  FIND_CITY,
  FIND_REGION,
  GET_CITY,
  GET_COUNTRIES,
  GET_ISOS,
  GET_WORKBOOKS,
  SET_LOADING,
  SET_WORKBOOK,
  UPDATE_WORKBOOK,
  WORKBOOK_ERROR
} from '../types';
import setError from "../../utils/setError";

const CityWorkbookState = props => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialState = {
    countries: null,
    cities: null,
    city_results: null,
    city_workbook: null,
    region_results: [],
    isos: null,
    current: null,
    filtered: null,
    error: null,
    loading: true,
    result_loading: null
  };

  const [state, dispatch] = useReducer(cityWorkbookReducer, initialState);

  // Get object for city
  const getCity = async id => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/city-workbook/?cid=${id}`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_CITY,
        payload: res.data[0]
      });
    } catch (err) {
      dispatch({
        type: WORKBOOK_ERROR,
        payload: err.response
      });
    }
  };

  // Get a list of Countries
  const getCountries = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/location/country/`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_COUNTRIES,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: WORKBOOK_ERROR,
        payload: err.response.data
      });
    }
  };

  // Get a list of ISOs
  const getIsos = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/iso/iso/`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_ISOS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: WORKBOOK_ERROR,
        payload: err.response.data
      });
    }
  };

  // Get Workbooks
  const getWorkbooks = async (adminPage) => {
    let url = `${baseUrl}/api/cert/city-workbook-all/`;
    if (adminPage) {
      url += "?with_status_change_log=true&with_assigned_users=true";
    }

    try {
      const res = await axios.get(url, setAuthToken(localStorage.token));
      dispatch({
        type: GET_WORKBOOKS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: WORKBOOK_ERROR,
        payload: err.response.data
      });
    }
  };

  // Add Workbook
  const addWorkbook = async workbook => {
    try {
      await axios.post(
        `${baseUrl}/api/cert/workbook/`,
        workbook,
        setAuthToken(localStorage.token)
      );
      const res = await axios.get(
        `${baseUrl}/api/cert/city-workbook-all/?ordering=name`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: ADD_WORKBOOK,
        payload: res.data
      });
      setAlert('Workbook was successfully created.', 'success');
    } catch (err) {
      dispatch({
        type: WORKBOOK_ERROR,
        payload: err.response.data
      });
    }
  };

  // Add City
  const addCity = async newCityObject => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/location/city/`,
        newCityObject,
        setAuthToken(localStorage.token)
      );
      var newRes = [];
      newRes.push(res.data);
      dispatch({
        type: ADD_CITY,
        payload: newRes
      });
      setAlert('New City was successfully added to the portal.', 'success');
    } catch (err) {
      dispatch({
        type: WORKBOOK_ERROR,
        payload: err.response.data
      });
    }
  };

  // Find City
  const findCity = async cityObject => {
    try {
      dispatch({
        type: SET_LOADING,
        payload: true
      });
      const res = await axios.get(
        `${baseUrl}/api/location/new-city/?city=${cityObject.city}&country=${cityObject.country}`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: FIND_CITY,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: WORKBOOK_ERROR,
        payload: err.response.data
      });
    }
  };

  // Find Region
  const getRegion = async (region, country_id) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/location/region/?region=${region}&country_id=${country_id}`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: FIND_REGION,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: WORKBOOK_ERROR,
        payload: err.response.data
      });
    }
  };

  // Delete Workbook
  const deleteWorkbook = async id => {
    try {
      await axios.delete(`${baseUrl}/api/cert/workbook/${id}/`);
      dispatch({
        type: DELETE_WORKBOOK,
        payload: id
      });
    } catch (err) {
      dispatch({
        type: WORKBOOK_ERROR,
        payload: err.response.data
      });
    }
  };

  // Update Workbook
  const updateWorkbook = async workbookObject => {
    const {
      date_created,
      ...workbook
    } = workbookObject;

    try {
      const res = await axios.patch(
        `${baseUrl}/api/cert/workbook/${workbook.id}/`,
        workbook,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: UPDATE_WORKBOOK,
        payload: res.data,
      });
    } catch (err) {
      setAlert(setError(err), 'warning');
      dispatch({
        type: WORKBOOK_ERROR,
        payload: err.response,
      });
    }
  };

  // Clear findCity results
  const clearResults = () => {
    dispatch({ type: CLEAR_RESULTS });
  };

  // Clear Workbooks
  const clearWorkbooks = () => {
    dispatch({ type: CLEAR_WORKBOOKS });
  };

  // Set Current Workbook
  const setCurrent = workbook => {
    dispatch({
      type: SET_WORKBOOK,
      payload: workbook
    });
  };

  // Clear Curent Workbook
  const clearCurrent = () => {
    dispatch({ type: CLEAR_WORKBOOK });
  };

  // Filter Workbook
  const filterCities = text => {
    dispatch({
      type: FILTER_WORKBOOKS,
      payload: text
    });
  };

  // Clear Filter
  const clearFilter = () => {
    dispatch({ type: CLEAR_FILTER });
  };

  return (
    <CityWorkbookContext.Provider
      value={{
        countries: state.countries,
        cities: state.cities,
        city_workbook: state.city_workbook,
        city_results: state.city_results,
        region_results: state.region_results,
        isos: state.isos,
        current: state.current,
        filtered: state.filtered,
        error: state.error,
        loading: state.loading,
        result_loading: state.result_loading,
        getWorkbooks,
        addWorkbook,
        deleteWorkbook,
        setCurrent,
        clearCurrent,
        updateWorkbook,
        filterCities,
        clearFilter,
        clearWorkbooks,
        getIsos,
        getCountries,
        addCity,
        findCity,
        clearResults,
        getRegion,
        getCity
      }}
    >
      {props.children}
    </CityWorkbookContext.Provider>
  );
};

export default CityWorkbookState;
