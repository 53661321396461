import React, { useEffect, useState } from 'react';
import { Radio, RadioGroup } from 'react-radio-group';
import {
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

import IsoQuestion from './IsoQuestion';
import { questionTypes } from "../../utils/constants";

const IsoIndicator = (props) => {
  const {
    curr_ind,
    childIndicators,
    getIndicator,
    types,
    updateIndicator,
    updateQuestion,
    createQuestion,
    deleteQuestion,
    setAlert
  } = props;
  const { id } = curr_ind;

  const [indicatorData, setIndicatorData] = useState(curr_ind);
  const [dirtyData, setDirtyData] = useState({});
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [newQuestionDataLoading, setNewQuestionDataLoading] = useState(false);
  const [newQuestionData, setNewQuestionData] = useState({
    indicator: id
  });

  const onChange = (fieldName, value) => {
    setDirtyData({
      ...dirtyData,
      [`${fieldName}`]: curr_ind[fieldName] !== value
    });

    setIndicatorData({
      ...indicatorData,
      [`${fieldName}`]: value
    });
  };

  const hasDirtyData = () => {
    return !Object.values(dirtyData).find((dirty) => {
      if (Array.isArray(dirty)) {
        return dirty.length;
      }

      return !!dirty;
    });
  };

  useEffect(() => {
    setIndicatorData(curr_ind);
    setDirtyData({});
    setNewQuestionData({
      indicator: curr_ind.id
    });
  }, [curr_ind]);

  return (
    <div>
      <Modal isOpen={questionModalOpen}>
        <ModalHeader>Create a new indicator question</ModalHeader>
        <ModalBody>
          <div className="input-form">
            <label>New question type:</label>
            <select
              className='form-control'
              onChange={(e) => {
                setNewQuestionData({
                  ...newQuestionData,
                  qtype: e.target.value || null
                })
              }}
            >
              {questionTypes.map(({ value, text }, i) =>
                <option key={i} value={value}>
                  {text}
                </option>
              )}
            </select>
          </div>

          <div className="input-form m-t-20">
            <label>New question code:</label>
            <input
              className='form-control'
              onChange={(e) => {
                setNewQuestionData({
                  ...newQuestionData,
                  code: e.target.value || null
                })
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className='btn btn-white'
            id='addwb_submit'
            onClick={() => {
              setNewQuestionData({
                indicator: id
              });
              setQuestionModalOpen(false);
            }}
          >
            Close
          </button>
          <button
            disabled={!newQuestionData.qtype || newQuestionDataLoading}
            className='btn btn-success'
            onClick={async () => {
              setNewQuestionDataLoading(true);

              if (await createQuestion(newQuestionData)) {
                setAlert("A new question was created", "success");
                setQuestionModalOpen(false);
                setNewQuestionData({ indicator: id });
              } else {
                setAlert("Error creating a new question", "danger");
              }

              setNewQuestionDataLoading(false);
            }}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>

      <button
        className='mb-4 btn btn-success'
        style={{
          position: "sticky",
          top: 8,
          zIndex: 9999
        }}
        onClick={() => {
          const {
            questions = [],
            ...data
          } = indicatorData;

          updateIndicator(data);
          questions.forEach(question => {
            if (question.dirty) {
              delete question.dirty;
              updateQuestion(question);
            }
          });

          setDirtyData({});
        }}
        disabled={hasDirtyData()}
      >
        Save changes
      </button>

      {!!curr_ind.parent_id && (
        <button
          className='mb-4 ml-4 btn btn-primary'
          style={{
            position: "sticky",
            top: 8,
            zIndex: 9999
          }}
          onClick={() => {
            getIndicator(curr_ind.parent_id);
          }}
        >
          Back to Parent
        </button>
      )}

      <h3>
        {indicatorData.iso_section} - {indicatorData.label}
      </h3>
      <div className='font-weight-bold m-b-15'>Database ID: {id}</div>

      {/* iso section  */}
      <div className='input-group m-b-15 width-200'>
        <span className='m-t-10 width-150'>ISO Section:</span>
        <input
          className='form-control'
          type='text'
          placeholder=''
          value={indicatorData.iso_section}
          id={id}
          onChange={(e) => {
            onChange("iso_section", e.target.value);
          }}
        />
      </div>

      {/* indicator name */}
      <div className='input-group m-b-15 width-600'>
        <span className='m-t-10 width-150'>Indicator Name:</span>
        <textarea
          className='form-control'
          placeholder=''
          value={indicatorData.name}
          id={id}
          onChange={(e) => {
            onChange("name", e.target.value);
          }}
        />
      </div>

      {/* indicator label */}
      <div className='input-group m-b-15 width-600'>
        <span className='m-t-10 width-150'>Indicator Label:</span>
        <textarea
          className='form-control'
          placeholder=''
          value={indicatorData.label}
          id={id}
          onChange={(e) => {
            onChange("label", e.target.value);
          }}
        />
      </div>

      {/* indicator type */}
      <div className='radio radio-css radio-inline m-b-15 width-600'>
        <RadioGroup
          name={`text_value${id}`}
          key={id}
          selectedValue={indicatorData.indicatortype}
          onChange={(radSelect) => {
            onChange("indicatortype", radSelect);
          }}
        >
          <div className='m-t-10 width-150 m-r-15 float-left'>
            <span>Indicator Type:</span>
          </div>
          <div className='radio radio-css radio-inline'>
            <Radio
              value={1}
              name={`radio_css_inline_${id}`}
              id={`inlineCssRadio1${id}`}
            />
            <label htmlFor={`inlineCssRadio1${id}`}>Core</label>
          </div>
          <div className='radio radio-css radio-inline'>
            <Radio
              value={2}
              name={`radio_css_inline_${id}`}
              id={`inlineCssRadio2${id}`}
            />
            <label htmlFor={`inlineCssRadio2${id}`}>Supporting</label>
          </div>
          <div className='radio radio-css radio-inline'>
            <Radio
              value={3}
              name={`radio_css_inline_${id}`}
              id={`inlineCssRadio3${id}`}
            />
            <label htmlFor={`inlineCssRadio3${id}`}>Profile</label>
          </div>
          <div className='radio radio-css radio-inline'>
            <Radio
              value={4}
              name={`radio_css_inline_${id}`}
              id={`inlineCssRadio4${id}`}
            />
            <label htmlFor={`inlineCssRadio4${id}`}>Indicator</label>
          </div>
        </RadioGroup>
      </div>

      {/* indicator value type */}
      <div className='input-group m-b-15 width-600'>
        <span className='m-t-10 width-150'>Unit:</span>
        <select
          className='form-control'
          value={indicatorData.indicatorvaluetype}
          onChange={(e) => {
            onChange("indicatorvaluetype", e.target.value);
          }}
        >
          {types.map((type) => (
            <option key={type.id} value={type.id}>
              {type.unit}
            </option>
          ))}
        </select>
      </div>

      {/* requirements */}
      <div className='input-group m-b-15 width-600'>
        <span className='m-t-10 width-150'>Hover Text (i):</span>
        <textarea
          className='form-control'
          placeholder=''
          value={indicatorData.requirements || ''}
          rows={5}
          onChange={(e) => {
            onChange("requirements", e.target.value);
          }}
        />
      </div>

      {/* is visualized */}
      <div className='input-group m-b-15 width-600'>
        <span className='m-t-10 width-150'>Is visualized:</span>
        <input
          style={{ maxWidth: 20 }}
          className='form-control'
          type="checkbox"
          checked={indicatorData.is_visualize || false}
          onChange={() => {
            onChange("is_visualize", !indicatorData.is_visualize);
          }}
        />
      </div>

      {/* is visible */}
      <div className='input-group m-b-15 width-600'>
        <span className='m-t-10 width-150'>Is visible:</span>
        <input
          style={{ maxWidth: 20 }}
          className='form-control'
          type="checkbox"
          checked={indicatorData.is_visible || false}
          onChange={() => {
            onChange("is_visible", !indicatorData.is_visible);
          }}
        />
      </div>

      {/* display order */}
      {!!curr_ind.parent_id && (
        <div className='input-group m-b-15 width-600'>
          <span className='m-t-10 width-150'>Display order:</span>
          <input
            style={{ maxWidth: 100 }}
            className='form-control'
            type="number"
            value={indicatorData.display_order || 0}
            onChange={(e) => {
              onChange("display_order", e.target.value);
            }}
          />
        </div>
      )}

      {!!(childIndicators || []).length && (
        <>
          {/* has button */}
          <div className='input-group m-b-15 width-600'>
            <span className='m-t-10 width-150'>Has button:</span>
            <input
              style={{ maxWidth: 20 }}
              className='form-control'
              type="checkbox"
              checked={indicatorData.has_button || false}
              onChange={() => {
                onChange("has_button", !indicatorData.has_button);
              }}
            />
          </div>

          {/* child indicators */}
          <div className="m-b-15 d-flex flex-row">
            <span className='m-t-10 width-150'>Child indicators:</span>
            <ListGroup vertical style={{ width: 450 }}>
              {childIndicators.map((ind, i) =>
                <ListGroupItem
                  key={i}
                  action
                  tag="button"
                  onClick={() => {
                    getIndicator(ind.id);
                  }}
                >
                  {ind.label} ({ind.id})
                </ListGroupItem>
              )}
            </ListGroup>
          </div>
        </>
      )}

      <IsoQuestion
        setAlert={setAlert}
        questions={indicatorData.questions}
        setQuestion={(question) => {
          const oldQuestion = curr_ind.questions
            .find(({ id }) => id === question.id) || {};

          const dirty = !!Object.keys(oldQuestion)
            .find((key) => oldQuestion[key] !== question[key]);
          let dirtyQuestions = (dirtyData.questions || []);

          if (dirty) {
            dirtyQuestions.push(question.id);
          } else {
            dirtyQuestions = dirtyQuestions.filter((id) => id !== question.id);
          }
          setDirtyData({
            ...dirtyData,
            questions: dirtyQuestions
          });
          question.dirty = dirty;

          const questions = indicatorData.questions.reduce((acc, stateQuestion) => {
            if (stateQuestion.id === question.id) {
              return [...acc, question];
            }

            return [...acc, stateQuestion];
          }, []);
          setIndicatorData({
            ...indicatorData,
            questions
          });
        }}
        deleteQuestion={deleteQuestion}
      />

      <hr />
      <button
        className='mb-4 btn btn-primary'
        onClick={() => {
          setQuestionModalOpen(true);
        }}
      >
        Create a new question
      </button>
    </div>
  );
};

export default IsoIndicator;
