import React, { useContext, useEffect, useState } from 'react';
import CityWorkbookContext
  from '../../context/cityworkbook/cityWorkbookContext';
import wccdLogo from './../../assets/img/logos/WCCD_Horizontal_White.png';
import canadaLogo from './../../assets/img/logos/Canadian_Logo_white.png';
import menasaLogo from './../../assets/img/logos/Dubai_WCCD_white.png';
import dubaiCouncilLogo from './../../assets/img/logos/Dubai_Council_Logo.png';
import { Link } from 'react-router-dom';
import coverGlobal from './../../assets/img/landing/All.png';
import coverCanada from './../../assets/img/landing/Canada.png';
import coverMenasa from './../../assets/img/landing/Dubai.png';
import { Row } from "reactstrap";

const Cover = (user, url, justifyLeft, coverImage, logo, bottomLogos) => {
  const justify = justifyLeft ? "justify-content-start" : "justify-content-center";
  return <div className='container-fluid'>
    <div
      className='landing-cover-image'
      style={{ backgroundImage: 'url(' + coverImage + ')' }}
    />

    <Row className={justify} style={{ marginTop: "20%" }}>
      <img src={logo} alt=''
           style={{ maxHeight: 150, height: "100%", maxWidth: "100%" }} />
    </Row>

    <Row className={justify}>
      <div className='text-center text-white bold f-w-600 f-s-25-c mt-5'>
        <span>WELCOME!</span>
      </div>
    </Row>

    <Row
      className={justify + " pt-5 text-left text-white f-w-600 font-italic f-s-15"}>
      <div>
        {user.vis !== 0 && (
          <div className='m-b-15'>
            <Link to='/vis' style={{ color: "white" }}>
                <span className='underline'>
                  <i className='fa fa-arrow-right p-r-5'> </i>
                  VISIT THE VISUALIZATION PORTAL
                </span>
            </Link>
          </div>
        )}
        {user.role !== 6 && (
          <div className='m-b-15'>
            <Link to={url} style={{ color: "white" }}>
                <span className='underline'>
                  <i className='fa fa-arrow-right p-r-5'> </i>
                  VISIT YOUR WORKBOOK IN THE CITIES PORTAL
                </span>
            </Link>
          </div>
        )}
      </div>
    </Row>

    {!!bottomLogos && (
      <Row style={{ marginTop: "20%" }}>
        {bottomLogos.map((logo, i) =>
          <img key={i} src={logo} alt=''
               style={{ maxHeight: 55, marginRight: 50 }} />)}
      </Row>
    )}
  </div>;
};

const LandingDash = ({ vis, user }) => {
  const cityWorkbookContext = useContext(CityWorkbookContext);
  const [wbURL, setURL] = useState('/');

  const { getCity, city_workbook } = cityWorkbookContext;
  const renderSwitch = (type, wbURL) => {
    switch (type) {
      case 2:
        return Cover(user, wbURL, true, coverCanada, canadaLogo, [wccdLogo]);
      case 3:
        return Cover(user, wbURL, true, coverMenasa, menasaLogo, [wccdLogo, dubaiCouncilLogo]);
      default:
        return Cover(user, wbURL, false, coverGlobal, wccdLogo);
    }
  };

  useEffect(() => {
    if (user.cities.length === 1 && (user.role === 4 || user.role === 5)) {
      if (city_workbook) {
        const workbook = city_workbook.workbooks[0];
        setURL(`/iso/${workbook.iso}/workbook/${workbook.id}`);
      }
    } else {
      setURL('/workbooks');
    }
    // eslint-disable-next-line
  }, [city_workbook]);

  useEffect(() => {
    if (user.role !== 1) {
      getCity(user.cities[0]);
    }

    // eslint-disable-next-line
  }, [user]);

  return <div>
    {renderSwitch(vis, wbURL)}
  </div>;
};

export default LandingDash;
