const renderCityOptions = cities => {
  var cityOptions = [];
  for (var city in cities) {
    var cityOption = {};
    var id = cities[city].id;
    var name = cities[city].name;
    var region = cities[city].region;
    var country = cities[city].country;
    cityOption['value'] = id;
    cityOption['label'] = name + ', ' + region + ', ' + country;
    cityOptions.push(cityOption);
  }
  const sortedOptions = cityOptions.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  return sortedOptions;
};
export default renderCityOptions;
