import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const WorkbookGridPanelCertBar = ({ countObject, barType }) => {
  const type = barType.toLowerCase();
  const typeTotal = type + "Total";
  return (
    <Fragment>
      <div className='widget-chart-info-progress'>
        <b>
          <span className='f-s-8'>{barType}</span>
        </b>
        <span className='pull-right'>
          {countObject[type] + " / " + countObject[typeTotal]}
        </span>
      </div>
      <div className='progress progress-sm m-b-15'>
        <div
          className='progress-bar bg-teal-darker'
          style={{
            width: `${(countObject[type] * 100) / countObject[typeTotal]}%`
          }}
        />
      </div>
    </Fragment>
  );
};

WorkbookGridPanelCertBar.propTypes = {
  countObject: PropTypes.object,
  barType: PropTypes.string,
  iso_id: PropTypes.number
};
export default WorkbookGridPanelCertBar;
