import * as am4core from "@amcharts/amcharts4/core";
import { Percent } from "@amcharts/amcharts4/core";

export const addFootNotes = (indicator, chart, onExport = []) => {
  const { vis_chart_note } = indicator;

  if (vis_chart_note) {
    let container = chart.createChild(am4core.Container);
    container.width = new Percent(100);

    let label = container.createChild(am4core.Label);
    label.fill = am4core.color("#fff");
    label.text = vis_chart_note;
    label.fontSize = 14;
    label.align = "left";
    label.marginTop = 15;
    label.marginRight = 15;
    label.wrap = true;
    label.clickable = true;
    label.cursorDownStyle = am4core.MouseCursorStyle.pointer;
    label.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    label.truncate = true;
    label.ellipsis = "... CLICK TO READ MORE";
    label.paddingBottom = 10;
    label.width = new Percent(100);

    label.events.on("hit", ev => {
      ev.target.truncate = !ev.target.truncate;
    }, this);

    onExport.push(() => {
      label.truncate = false;
    });
  }
};