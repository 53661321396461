import React, { useContext, useEffect, Fragment } from 'react';
import PageContext from '../../config/pageContext';
import AuthContext from '../../context/auth/authContext';
import CityState from '../../context/city/CityState';
import CityDash from '../city/CityDash';

const City = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.handleSetPageSidebar(false);
    // eslint-disable-next-line
    return () => {
      pageContext.handleSetPageSidebar(true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <CityState>
        <CityDash />
      </CityState>
    </Fragment>
  );
};

export default City;
