import React, { Fragment } from 'react';
import WorkbookItem from './WorkbookItem';
import PropTypes from 'prop-types';

const CityWorkbookItem = ({ city, loading, isos }) => {
  const { name, region, country, workbooks } = city;
  return (
    <Fragment>
      <div className='col-lg-3 col-md-6 col-sm-12 pull-left'>
        <div className='card border-0 bg-dark text-white text-truncate mb-3  height-250'>
          <div className='card-body height-full overflow-auto scrollbar'>
            <div className='d-flex align-items-center mb-1'>
              <h3 className='text-white text-wrap mb-0'>{name}</h3>
            </div>
            <div className='mb-4 text-grey text-wrap'>
              <i className='fa fa-map-marker-alt'></i> {region}, {country}
            </div>
            <div className='m-r-15'>
              {workbooks !== null && !loading ? (
                <div>
                  {workbooks.map(workbook => (
                    <WorkbookItem
                      key={workbook.id}
                      workbook={workbook}
                      isos={isos}
                    />
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
CityWorkbookItem.propTypes = {
  isos: PropTypes.array,
  city: PropTypes.object,
  loading: PropTypes.bool
};
export default CityWorkbookItem;
