import {
  GET_USERS,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
  USER_ERROR,
  GET_CITIES,
  GET_WORKBOOKS
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_WORKBOOKS:
      return {
        ...state,
        cities_list: action.payload,
        loading: false
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload.results,
        count: action.payload.count,
        previous: action.payload.previous,
        next: action.payload.next,
        loading: false
      };
    case ADD_USER:
      return {
        ...state,
        users: [action.payload, ...state.users],
        error: null,
        loading: false
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.id ? action.payload : user
        ),
        loading: false
      };
    case GET_CITIES:
      return {
        ...state,
        cities_options: action.payload,
        loading: false
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter(user => user.id !== action.payload),
        loading: false
      };
    case USER_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
