import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  getWBStatusString,
  getWBStatusDot,
  getCertColour
} from '../../utils/generalHelpers';

const WorkbookItem = ({ workbook, isos }) => {
  const { year, iso, status, id, certifications } = workbook;

  var isocode;

  const getIso = iso => {
    for (var item in isos) {
      if (iso === isos[item].id) {
        isocode = isos[item].code;
      }
    }
    return isocode;
  };
  return (
    <Fragment>
      <Link to={`/iso/${iso}/workbook/${id}`} className='text-white'>
        <div className='d-flex mb-2 f-s-12'>
          <div className='d-flex'>
            <i
              className={
                'fa fa-circle text-' +
                getWBStatusDot(status) +
                ' mr-2  f-s-10 m-b-3'
              }
            />
            <div className='pr-5' style={{ width: '30%' }}>
              {year}
            </div>
          </div>
          <div className='d-flex flex-row flex-wrap width-full'>
            <div className='text-grey mr-1'>
              <i className='fa fa-globe' /> {getIso(iso)}
            </div>
            <div className='d-flex flex-row'>
              <div className='text-center f-w-600  mr-1'>
                {getWBStatusString(status)}
              </div>
              <div className='text-center  mr-1'>
                {certifications !== null && certifications.length ? (
                  <i
                    className={
                      'fa fa-award text-gradient ' +
                      getCertColour(certifications[0].certification).class
                    }
                    title={getCertColour(certifications[0].certification).title}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </Fragment>
  );
};

WorkbookItem.propTypes = {
  workbook: PropTypes.object.isRequired,
  isos: PropTypes.array
};

export default WorkbookItem;
