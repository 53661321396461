import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Nav, NavItem, NavLink } from 'reactstrap';
import { getCertColour, getIso } from '../../utils/generalHelpers';

const WorkbookNav = ({
  workbooks,
  getIsoTheme,
  getWorkbookCity,
  wbId,
  loading,
  getPeerIndicators,
}) => {
  const [activeTab, setActiveTab] = useState(parseInt(wbId));
  const getWorkbook = (isoId, workbookId) => {
    setActiveTab(workbookId);
    getIsoTheme(isoId, workbookId);
    getWorkbookCity(workbookId);
    getPeerIndicators(workbookId, isoId);
  };
  return (
    <div>
      <Nav tabs>
        {workbooks !== null && !loading ? (
          <Fragment>
            {workbooks.map((workbook) => (
              <NavItem key={workbook.id}>
                <NavLink
                  className={classnames({
                    active: activeTab === workbook.id,
                  })}
                  style={{ cursor: 'pointer' }}
                  onClick={() => getWorkbook(workbook.iso, workbook.id)}
                >
                  <span className='d-sm-none'>{workbook.year}</span>
                  <span className='d-sm-block d-none'>
                    <span className='f-w-700 p-r-10'>{workbook.year}</span>
                    <span className='f-s-10 p-r-10'>
                      {getIso(workbook.iso)}
                    </span>
                    <div className="pull-right">
                      {workbook.status === 7 && workbook.certifications?.length ? (
                        <i
                          className={
                            "fa fa-award text-gradient " +
                            getCertColour(
                              workbook.certifications[0].certification,
                            ).class
                          }
                          title={
                            getCertColour(
                              workbook.certifications[0].certification,
                            ).title
                          }
                        ></i>
                      ) : (
                        ""
                      )}
                    </div>
                  </span>
                </NavLink>
              </NavItem>
            ))}
          </Fragment>
        ) : (
          ''
        )}
      </Nav>
    </div>
  );
};

WorkbookNav.propTypes = {
  workbooks: PropTypes.array,
  getIsoTheme: PropTypes.func,
  getWorkbookCity: PropTypes.func,
  wbId: PropTypes.string,
  loading: PropTypes.bool,
};

export default WorkbookNav;
