import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";
import React, { Fragment, useContext, useEffect, useState } from "react";
import spinner from "../../spinner.gif";
import uuid from "uuid";
import AlertContext from "../../../../context/alert/alertContext";

const ModalHeaderDiv = ({ className, ...rest }) => <div {...rest} />;

const DataExportModal = (props) => {
  const {
    exportModalState,
    setExportModalState,
    cityOptions,
    getCities,
    dbExport,
    user,
  } = props;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [isDrivingInvestment, setIsDrivingInvestment] = useState(false);
  const [selectedISO, setSelectedISO] = useState();
  const [selectedCities, setSelectedCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectAllCities, setSelectAllCities] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectRecentYear, setSelectRecentYear] = useState(false);
  const [showUnpublishedWorkbooks, setShowUnpublishedWorkbooks] = useState(false);
  const [includeSourceYear, setIncludeSourceYear] = useState(false);
  const [simplifiedView, setSimplifiedView] = useState(false);
  const [isAnnotatedExport, setIsAnnotatedExport] = useState(false);

  useEffect(() => {
    getCities();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (user.role === 4) {
  //     setSelectedCities([user.cities[0]]);
  //   }
  // }, [user]);

  useEffect(() => {
    if (isDrivingInvestment && cityOptions) {
      setFilteredCities(cityOptions);
    }
    // eslint-disable-next-line
  }, [isDrivingInvestment, cityOptions]);

  useEffect(() => {
    if (filteredCities.length <= 1) {
      setSelectedCities(filteredCities.map(value => value.id));
    }
    // eslint-disable-next-line
  }, [selectedISO]);

  const reset = () => {
    setIsDrivingInvestment(false);
    setLoading(false);
    setSelectedISO();
    setSelectedCities([]);
    setFilteredCities([]);
    setSelectedYears([]);
    setShowUnpublishedWorkbooks(false);
    setSelectAllCities(false);
    setSelectRecentYear(false);
    setExportModalState(false);
    setIncludeSourceYear(false);
    setIsAnnotatedExport(false);
    setSimplifiedView(false);
  };

  const sendError = () => {
    setAlert(
      "Error generating the export, please try again or contact staff",
      "warning",
      15000);
  };

  const filterCityOptionsByISO = (isoID) => {
    const options = user.role === 1
      ? cityOptions
      : cityOptions.filter((city) => (user.cities || []).includes(city.id));

    setFilteredCities(options.reduce((acc, city) => {
      if (city.workbooks && city.workbooks.some(workbook => {
        const iso = workbook.iso === 1 ? 2 : workbook.iso;
        return /*workbook.status === 7 &&*/ iso === isoID;
      })) {
        acc.push(city);
      }

      return acc;
    }, []));
  };

  const getYearOptions = () => {
    const reduce = (acc, city) => {
      if (city && city.workbooks) {
        city.workbooks.forEach(workbook => {
          if (selectedISO === 1 || selectedISO === 2) {
            if (workbook.iso <= 2 && (workbook.status === 7 || showUnpublishedWorkbooks)) {
              acc.push(workbook.year);
            }
          } else if (isDrivingInvestment) {
            if (workbook.status === 7 || showUnpublishedWorkbooks) {
              acc.push(workbook.year);
            }
          } else {
            if (workbook.iso === selectedISO && (workbook.status === 7 || showUnpublishedWorkbooks)) {
              acc.push(workbook.year);
            }
          }
        });
      }

      return acc;
    };

    let years = [];
    if (selectAllCities) {
      // return all years
      years = filteredCities.reduce((acc, city) => reduce(acc, city), []);
    } else if (selectedCities.length) {
      // return years from the cities selected
      years = selectedCities.reduce((acc, id) => {
        const city = filteredCities.find(city => city.id === id);
        return reduce(acc, city);
      }, []);
    }

    return [...new Set(years)].sort().reverse().map(year => ({
      value: year,
      label: year,
    }));
  };

  const generate = async () => {
    setLoading(true);

    const exportUuid = uuid.v4();
    const response = await dbExport(
      exportUuid,
      selectAllCities ? [] : selectedCities,
      selectedYears.length ? selectedYears : getYearOptions().map(({ value }) => value),
      selectRecentYear,
      selectedISO,
      showUnpublishedWorkbooks,
      includeSourceYear,
      isDrivingInvestment,
      isAnnotatedExport,
      simplifiedView,
    );
    if (!response) {
      reset();
      sendError();
      return;
    }

    const timer = setInterval(async () => {
      const exportObject = await dbExport(exportUuid, null);
      if (!exportObject) {
        reset();
        sendError();
        clearInterval(timer);
        return;
      }

      if (exportObject.exported_at) {
        if (!exportObject.export) {
          sendError();
        } else {
          const link = document.createElement("a");
          link.href = exportObject.export;
          link.click();
        }

        reset();
        clearInterval(timer);
      }
    }, 2000);
  };

  return <Modal isOpen={exportModalState}>
    {user.role === 1 ? (
      <ModalHeader tag={ModalHeaderDiv} wrapTag={ModalHeaderDiv}>
        <div
          className="d-flex width-full text-grey-darker text-center border-bottom-1">
          <div
            className="data-export-tab"
            data-selected={!isDrivingInvestment}
            onClick={() => setIsDrivingInvestment(false)}
          >
            Workbooks Export
          </div>
          <div
            className="data-export-tab"
            data-selected={isDrivingInvestment}
            onClick={() => setIsDrivingInvestment(true)}
          >
            Investment Index Export
          </div>
        </div>
      </ModalHeader>
    ) : (
      <ModalHeader>
        Workbooks Export
      </ModalHeader>
    )}
    <ModalBody>
      {loading ? (
        <Fragment>
          <img
            src={spinner}
            alt="Loading..."
            style={{
              width: "100px",
              margin: "auto",
              display: "block",
              marginBottom: 30,
            }}
          />
          <span>Generating export. Please wait, this may take several minutes...</span>
          <br/>
          <span>DO NOT REFRESH THE PAGE</span>
        </Fragment>
      ) : (
        <Fragment>
          <div className="input-group">
            {!isDrivingInvestment && (
              <div className="width-full">
                <label>ISO:</label>
                <Select
                  options={[
                    { value: 2, label: "ISO37120" },
                    { value: 3, label: "ISO37122" },
                    { value: 4, label: "ISO37123" },
                  ]}
                  styles={{ width: "100%" }}
                  onChange={(options) => {
                    filterCityOptionsByISO(options.value);
                    setSelectedISO(options.value);
                  }}
                />

                {(user.role === 1 || user.annotated_data_download_enabled) && (
                  <div className="m-t-20 width-full">
                    <label className="m-r-10">Annotated data export:</label>
                    <input
                      type="checkbox"
                      onChange={() => setIsAnnotatedExport(!isAnnotatedExport)}
                      value={isAnnotatedExport}
                    />
                  </div>
                )}
              </div>
            )}

            {isAnnotatedExport && selectedISO && (
              <div className="m-t-10 width-full">
                <div className="width-full">
                  <label>City:</label>
                  <Select
                    isDisabled={selectAllCities}
                    options={filteredCities.map(city => ({
                      value: city.id,
                      label: city.name + " - " + city.country,
                    }))}
                    isSearchable
                    styles={{ width: "100%" }}
                    onChange={(option) => {
                      setSelectedCities([option.value]);
                    }}
                  />
                </div>
              </div>
            )}

            {((isDrivingInvestment || selectedISO) && user.role === 1 && !isAnnotatedExport) && (
              <div className="m-t-20 width-full">
                <div className="width-full">
                  <label>Cities:</label>
                  <Select
                    isDisabled={selectAllCities}
                    isMulti
                    options={filteredCities.map(city => ({
                      value: city.id,
                      label: city.name + " - " + city.country,
                    }))}
                    isSearchable
                    styles={{ width: "100%" }}
                    onChange={(options) => {
                      setSelectedCities((options || []).map(({ value }) => value));
                    }}
                  />
                </div>
                {(filteredCities || []).length && (
                  <div className="input-group">
                    <div className="m-t-20 width-full">
                      <label className="m-r-10">Select all cities:</label>
                      <input
                        type="checkbox"
                        onChange={() => setSelectAllCities(!selectAllCities)}
                        value={selectAllCities}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {!isDrivingInvestment && selectedISO && user.role === 1 && !isAnnotatedExport && (
              <div className="m-t-20 width-full">
                <label className="m-r-10">Include source year:</label>
                <input
                  type="checkbox"
                  onChange={() => setIncludeSourceYear(!includeSourceYear)}
                  value={includeSourceYear}
                />
              </div>
            )}

            {isDrivingInvestment && (
              <div className="m-t-20 width-full">
                <label className="m-r-10">Simplified view:</label>
                <input
                  type="checkbox"
                  onChange={() => setSimplifiedView(!simplifiedView)}
                  value={simplifiedView}
                />
              </div>
            )}

            {(!!selectedCities.length || selectAllCities) && (
              <div className="input-group">
                {!isAnnotatedExport && (
                  <>
                    {!isDrivingInvestment && (
                      <div className="m-t-20 width-full">
                        <label className="m-r-10">Most recent year:</label>
                        <input
                          type="checkbox"
                          onChange={() => setSelectRecentYear(!selectRecentYear)}
                          value={selectRecentYear}
                        />
                      </div>
                    )}
                    <div className="m-t-20 width-full">
                      <label className="m-r-10">Include unpublished
                        workbooks:</label>
                      <input
                        type="checkbox"
                        onChange={() => setShowUnpublishedWorkbooks(!showUnpublishedWorkbooks)}
                        value={showUnpublishedWorkbooks}
                      />
                    </div>
                  </>
                )}

                {!isDrivingInvestment && (
                  <div className="m-t-20 width-full">
                    <label>{(showUnpublishedWorkbooks ? "W" : "Published w") + "orkbook years (optional):"}</label>
                    <Select
                      isDisabled={selectRecentYear}
                      isMulti
                      options={getYearOptions()}
                      isSearchable
                      styles={{ width: "100%" }}
                      onChange={(options) => {
                        setSelectedYears((options || []).map(({ value }) => value));
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </Fragment>
      )}
    </ModalBody>
    <ModalFooter>
      <button
        className="btn btn-success"
        disabled={(!selectedISO && !isDrivingInvestment) || (!selectedCities.length && !selectAllCities) || loading}
        onClick={generate}
      >
        Generate
      </button>
      <button
        className="btn btn-white"
        disabled={loading}
        onClick={() => {
          setExportModalState(false);
        }}
      >
        Close
      </button>
    </ModalFooter>
  </Modal>;
};

export default DataExportModal;