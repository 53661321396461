import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
} from "../../layout/panel/panel";
import FileUpload from "./FileUpload";
import IndicatorAttachmentsItem from "./IndicatorAttachmentsItem";
import { isDisabled } from "../../../utils/generalHelpers";
import Spinner from "../../layout/Spinner";
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorAttachments = (props) => {
  const {
    workbookId,
    user,
    attachmentsObject,
    indicatorStatus,
    loading,
    deleteAttachment,
    mainValueID,
    uploadAttachment,
    copyAttachments,
    uploadPercentage,
    uploadCount,
  } = props;

  if (indicatorStatus) {
    var status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      status = indicatorStatus[0].status;
    }
  }

  return (
    <Fragment>
      <Panel theme="default" className="bg-silver-transparent-4">
        <PanelHeader>
          <span className="f-s-14"> Attachments</span>

          <div className="float-right" style={{ marginRight: -10 }}>
            <BackInTime
              disabled={(indicatorStatus[0].status !== 1)}
              user={user}
              id={mainValueID}
              setValue={(value, year, valueId) => copyAttachments(workbookId, valueId, mainValueID)}
            />
          </div>
        </PanelHeader>
        <PanelBody>
          <div className="table-responsive">
            {attachmentsObject !== null && !loading ? (
              <table className="table table-striped m-b-0">
                <thead>
                <tr>
                  <th>Type</th>
                  <th>File</th>
                  <th>Related to</th>
                  <th>Uploaded at</th>
                  <th>Download</th>
                  <th hidden={isDisabled(status)}>Delete</th>
                </tr>
                </thead>
                <tbody>
                {attachmentsObject.map(attachment => (
                  <IndicatorAttachmentsItem
                    key={attachment.id}
                    attachment={attachment}
                    deleteAttachment={deleteAttachment}
                    indicatorStatus={indicatorStatus}
                    user={user}
                  />
                ))}
                </tbody>
              </table>
            ) : (
              <Spinner />
            )}
            {attachmentsObject !== null &&
            attachmentsObject.length === 0 &&
            !loading ? (
              <div className="p-t-20">
                <p>
                  No attachments found for this indicator. Please upload an
                  attachment below.
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </PanelBody>
        <PanelFooter>
          <FileUpload
            mainValueID={mainValueID}
            uploadAttachment={uploadAttachment}
            uploadPercentage={uploadPercentage}
            uploadCount={uploadCount}
            indicatorStatus={indicatorStatus}
            user={user}
          />
        </PanelFooter>
      </Panel>
    </Fragment>
  );
};

IndicatorAttachments.propTypes = {
  user: PropTypes.object,
  attachmentsObject: PropTypes.array,
  indicatorStatus: PropTypes.array,
  loading: PropTypes.bool,
  deleteAttachment: PropTypes.func,
  mainValueID: PropTypes.number,
  uploadAttachment: PropTypes.func,
  uploadPercentage: PropTypes.number,
};

export default IndicatorAttachments;
