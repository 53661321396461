import React, { Fragment } from 'react';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';
import WorkbookGridIndicatorItem from './WorkbookGridIndicatorItem';
import Tooltip from 'react-tooltip-lite';

const WorkbookGridItem = ({ theme }) => {
  const { icon, id, title, children } = theme;
  return (
    <Fragment>
      <div className='ro_square' id={id}>
        <div className='ro_content'>
          <div className='ro_table'>
            <div className='ro_table-cell'>
              <Tooltip content={`${title}`}>
                <span className={`${icon} fa-2x`} />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {children !== null ? (
        <div>
          {children.map(indicator => (
            <WorkbookGridIndicatorItem
              key={indicator.iId}
              indicator={indicator}
            />
          ))}

          <div className='clearfix' />
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};
WorkbookGridItem.propTypes = {
  theme: PropTypes.object.isRequired
};
export default WorkbookGridItem;
