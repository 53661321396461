import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";

const SidebarProfile = (props) => {
  const {
    cityObject,
    uploadCityImage,
    deleteCityImage,
    workbookId,
    user,
  } = props;

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const inputFileRef = useRef(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const image = cityObject?.images?.[Math.floor(Math.random() * cityObject?.images?.length)];
    setImage(image?.url || image?.external_url);
    // eslint-disable-next-line
  }, [cityObject?.images]);

  const handleFileUpload = async (files) => {
    if (files.length) {
      uploadCityImage(workbookId, cityObject.id, files).then(() => {
        setLoading(false);
      });
    }
  };

  const canUploadImages = user.upload_city_images || user.role === 1;

  return (
    <>
      <ul className="nav">
        <li
          className={
            "bg-gradient-black nav-profile"
          }
        >
          <div className={canUploadImages && "cursor-pointer"} onClick={() => {
            if (canUploadImages) {
              setModalOpen(true);
            }
          }}>
            <div className="cover with-shadow">
              {image && (
                <img
                  src={image}
                  style={{
                    width: 350,
                    objectFit: "none",
                    objectPosition: "center",
                  }}
                  alt="City"
                />
              )}
            </div>
            <div className="info">
              <b className="caret_NO pull-right" />
              <div>
                <strong>{cityObject ? cityObject.name : ""}</strong>
                <small>
                  {cityObject ? cityObject.region : ""},{" "}
                  {cityObject ? cityObject.country.name : ""}
                </small>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <Modal isOpen={modalOpen}>
        <ModalHeader style={{ padding: 0 }}>
          <div className="d-flex" style={{ gap: "1rem" }}>
            <ul className="image-gallery">
              {!!cityObject?.images?.length ? (
                cityObject.images.map(image => {
                  const url = image.url || image.external_url;

                  return <li key={image.id}>
                    <img src={url} alt="City" />
                    <div
                      className="img-top cursor-pointer"
                      onClick={() => {
                        deleteCityImage(workbookId, image.id);
                      }}
                    >
                      <i className="fa fa-trash" />
                    </div>
                    <div
                      className="img-bottom cursor-pointer"
                      onClick={() => {
                        const link = document.createElement("a");
                        link.setAttribute("target", "_blank");
                        link.href = url;
                        link.click();
                      }}
                    >
                      <i className="fa fa-download" />
                    </div>
                  </li>;
                })
              ) : (
                <div className={"p-20"}>No city images</div>
              )}
            </ul>
          </div>
        </ModalHeader>
        <ModalFooter>
          <input
            className="hide"
            type="file"
            accept="image/*"
            multiple
            ref={inputFileRef}
            onChange={(e) => handleFileUpload([...e.target.files])}
          />

          <div style={{ flexGrow: 1 }}>
            <button
              className="btn btn-primary float-left"
              disabled={loading}
              onClick={() => {
                setLoading(true);

                axios.get("https://source.unsplash.com/random/?" + cityObject.name + "," + cityObject.country?.name)
                  .then(res => {
                    const image = res.request?.responseURL;
                    if (image) {
                      uploadCityImage(workbookId, cityObject.id, [image], true);
                    }
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            >
              Get an image from Unsplash
            </button>
          </div>

          <button
            className="btn btn-success"
            disabled={loading}
            onClick={() => {
              setLoading(true);
              inputFileRef.current.click();
            }}
          >
            Upload new images
          </button>
          <button
            className="btn btn-white"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

SidebarProfile.propTypes = {
  cityObject: PropTypes.object,
};

export default SidebarProfile;
