import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isDisabled } from '../../../utils/generalHelpers';

const IndicatorAuditParametersNumber = ({
  user,
  value,
  indicatorStatus,
  updateValue
}) => {
  const [numberState, setNumberState] = useState(value);
  const { id, text_value } = numberState;

  const onChange = e => {
    setNumberState({ ...numberState, text_value: e.target.value });
  };
  const onBlur = () => {
    updateValue(numberState);
  };

  if (indicatorStatus) {
    var status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      status = indicatorStatus[0].status;
    }
  }

  var roleId = user.role;

  return (
    <div className='input-group m-b-6'>
      <input
        disabled={isDisabled(status, roleId)}
        type='text'
        className='form-control'
        placeholder='18'
        value={text_value !== null ? text_value : ' '}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};
IndicatorAuditParametersNumber.propTypes = {
  value: PropTypes.object,
  updateValue: PropTypes.func,
  indicatorStatus: PropTypes.array
};
export default IndicatorAuditParametersNumber;
