import React, { Fragment, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import AuthContext from './../../../../context/auth/authContext';
import DataExportModal from "./DataExportModal";

const DropdownProfile = (props) => {
  const {
    visPortal
  } = props;
  const authContext = useContext(AuthContext);

  const {
    logout,
    user,
    isAuthenticated,
    acceptedDisclaimer,
    exportDB,
    getCities,
    cities
  } = authContext;
  const [modalState, setModalState] = useState(false);

  const [dropdownOpen, setToggle] = useState(false);

  const goTo = (action) => {
    switch (action) {
      case 'users':
        props.history.push('/users');
        break;
      case 'export':
        setModalState(!modalState);
        break;
      case 'iso':
        props.history.push('/iso');
        break;
      case 'vis':
        props.history.push(visPortal ? '/workbooks' : '/vis');
        break;
      case 'workbooks-admin':
        props.history.push('/workbooks-admin');
        break;
      case 'profile':
        console.log('redirecting to user profile page...');
        break;
      default:
        break;
    }
  };

  const isAdmin = () => user.role === 1;

  const isDataDownloadHidden = () => {
    if (user.role === 1) {
      return false;
    }
    return !acceptedDisclaimer || user.role !== 4 || !user.data_download_enabled;
  };

  const noDisplayVis = () => {
    return !(user.role === 1 || user.role === 7 || (user.role === 4 && user.vis > 0));
  };

  return isAuthenticated && (
    <Fragment>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => setToggle(!dropdownOpen)}
        className='dropdown navbar-user'
        tag='li'
      >
        <DropdownToggle className='dropdown-toggle' tag='a'>
          <div className='image image-icon bg-black text-grey-darker'>
            <i className='fa fa-user' />
          </div>
          <span className='d-none d-md-inline cursor-pointer'>
              {user && user.email}
            </span>
          <b className='caret' />
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu dropdown-menu-right' tag='ul'>
          {/* <DropdownItem onClick={() => goTo('profile')}>
              Edit Profile
            </DropdownItem> */}
          <DropdownItem
            onClick={() => goTo('vis')}
            hidden={noDisplayVis()}
          >
            {visPortal ? "Dashboard" : "Visualization"}
          </DropdownItem>
          <DropdownItem
            onClick={() => goTo('export')}
            hidden={isDataDownloadHidden()}
          >
            Export Data
          </DropdownItem>
          {isAdmin() && (<div className='dropdown-divider' />)}
          <DropdownItem
            onClick={() => goTo('users')}
            hidden={!isAdmin()}
          >
            Users Admin
          </DropdownItem>
          <DropdownItem
            onClick={() => goTo('iso')}
            hidden={!isAdmin()}
          >
            ISO Admin
          </DropdownItem>
          <DropdownItem
            onClick={() => goTo('workbooks-admin')}
            hidden={!isAdmin()}
          >
            Workbooks Admin
          </DropdownItem>
          <div className='dropdown-divider' />
          <DropdownItem onClick={logout}>Log Out</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <DataExportModal
        exportModalState={modalState}
        setExportModalState={setModalState}
        dbExport={exportDB}
        getCities={getCities}
        cityOptions={cities}
        user={user}
      />
    </Fragment>
  );
};
export default withRouter(DropdownProfile);
