import React, { Fragment, useState } from 'react';
import AddWorkbookModal from './AddWorkbookModal';
import AddCityModal from './AddCityModal';
import PropTypes from 'prop-types';

const CityWorkbookAdd = ({
  isos,
  cities,
  countries,
  addWorkbook,
  addCity,
  findCity,
  getRegion,
  city_results,
  result_loading,
  clearResults,
  region_results
}) => {
  const [workbookModalState, setWorkbookModalState] = useState(false);
  const [cityModalState, setCityModalState] = useState(false);
  return (
    <Fragment>
      <AddWorkbookModal
        modalState={workbookModalState}
        isos={isos}
        cities={cities}
        addWorkbook={addWorkbook}
      />
      <AddCityModal
        modalState={cityModalState}
        cities={cities}
        countries={countries}
        addCity={addCity}
        findCity={findCity}
        city_results={city_results}
        result_loading={result_loading}
        clearResults={clearResults}
        getRegion={getRegion}
        region_results={region_results}
      />
      <div className='pull-right'>
        <div className='btn-group btn-group-lg'>
          <div className='btn-group-prepend btn-group'>
            <button
              className='btn bg-black-transparent-8 btn-lg b-0 width-150'
              title='Add a Workbook'
              onClick={() => setWorkbookModalState(!workbookModalState)}
            >
              <i className='fa fa-book-open text-white m-l-20'></i>
            </button>
            <button
              className='btn bg-black-transparent-8 btn-lg b-0  width-150'
              title='Add a City'
              onClick={() => setCityModalState(!cityModalState)}
            >
              <i className='fa fa-city text-white'></i>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
CityWorkbookAdd.propTypes = {
  addCity: PropTypes.func,
  findCity: PropTypes.func,
  getRegion: PropTypes.func,
  clearResults: PropTypes.func,
  addWorkbook: PropTypes.func,
  isos: PropTypes.array,
  cities: PropTypes.array,
  countries: PropTypes.array,
  city_results: PropTypes.array,
  region_results: PropTypes.array,
  result_loading: PropTypes.bool
};
export default CityWorkbookAdd;
