import React, { Fragment, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import IndicatorContext from '../../context/indicator/indicatorContext';
import AuthContext from '../../context/auth/authContext';
import WorkbookGrid from '../workbook/WorkbookGrid';
import Spinner from '../layout/Spinner';

const WorkbookDash = ({ workbookId, isoId }) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const indicatorContext = useContext(IndicatorContext);
  const {
    getIsoTheme,
    getWorkbookCity,
    updateWorkbook,
    cityObject,
    isoLabel,
    themeIndicator,
    workbookObject,
    countObject,
    loading,
    addCertification,
    setModalState,
    modalState,
    getPeerIndicators,
    peerIndicatorsObject,
  } = indicatorContext;

  if (cityObject !== null && isoLabel !== null) {
    document.title =
      cityObject.name +
      ' ' +
      isoLabel +
      ' ' +
      workbookObject.year +
      ' Workbook Dash';
  }
  useEffect(() => {
    getIsoTheme(isoId, workbookId);
    getWorkbookCity(workbookId);
    getPeerIndicators(workbookId, isoId);
    // eslint-disable-next-line
  }, []);
  // console.log(cityObject);
  return (
    <Fragment>
      {themeIndicator !== null && !loading ? (
        <WorkbookGrid
          cityObject={cityObject}
          isoLabel={isoLabel}
          themeIndicator={themeIndicator}
          workbookObject={workbookObject}
          loading={loading}
          countObject={countObject}
          updateWorkbook={updateWorkbook}
          user={user}
          addCertification={addCertification}
          setModalState={setModalState}
          modalState={modalState}
          wbId={workbookId}
          peerIndicatorsObject={peerIndicatorsObject}
          getPeerIndicators={getPeerIndicators}
        />
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

WorkbookDash.propTypes = {
  workbookId: PropTypes.string.isRequired,
  isoId: PropTypes.string.isRequired,
};

export default withRouter(WorkbookDash);
