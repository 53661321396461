export default {
  name: {
    label: "Full name"
  },
  email: {
    label: "Email",
    readOnly: true
  },
  phone: {
    label: "Phone",
  },
  cities: {
    label: "Cities",
    type: "multiselect",
    options: (cities) => cities?.reduce((acc, { value, label }) => ({
      ...acc,
      [value]: label
    }), {})
  },
  role: {
    label: "Role",
    type: "select",
    options: () => ({
      1: "Admin",
      2: "Reviewer",
      3: "Verified",
      4: "City",
      5: "Read Only",
      6: "Reports Only",
      7: "Researcher"
    })
  },
  vis: {
    label: "Vis type",
    type: "select",
    options: () => ({
      0: "None",
      1: "Global",
      2: "Canada",
      3: "MENASA",
    })
  },
  vis_additional_iso: {
    label: "Vis additional ISOs",
    type: "multiselect",
    options: () => ({
      3: "ISO 37122",
      4: "ISO 37123"
    })
  },
  comparison_vis_enabled: {
    label: "Comparison enabled",
    type: "boolean"
  },
  yoy_vis_enabled: {
    label: "YOY enabled",
    type: "boolean"
  },
  yoy_vis_year: {
    label: "YOY vis year",
    type: "select",
    options: () => ({
      0: "Certification Year",
      1: "Source Year",
      2: "Certification and Source Year"
    })
  },
  multiple_filters_vis_enabled: {
    label: "Multiple vis filters",
    type: "boolean"
  },
  data_download_enabled: {
    label: "Data download enabled",
    type: "boolean"
  },
  annotated_data_download_enabled: {
    label: "Annotated data download enabled",
    type: "boolean"
  },
  upload_city_images: {
    label: "City images upload enabled",
    type: "boolean"
  },
  last_login: {
    label: "Last login",
    readOnly: true,
    hideOnCreate: true
  },
  is_archived: {
    label: "Account status",
    type: "boolean",
    hideOnCreate: true
  },
  id: {
    label: "ID",
    hideOnCreate: true
  }
};