import Select from "react-select";
import React from "react";

const CitiesFilter = ({ citiesList, loading, loadUsers }) => {
  const handleChange = (cities) => {
    loadUsers({
      cities: cities ? cities.map(({ value }) => value) : null
    });
  };

  return <div style={{ zIndex: 9 }}>
    <Select
      disabled={loading}
      onChange={handleChange}
      options={citiesList}
      name='citiesFilter'
      placeholder={"Select city to filter by"}
      isMulti
      isSearchable
      className='width-300 bg-white'
      styles={{
        control: (provided) => ({
          ...provided,
          minHeight: 46
        })
      }}
    />
  </div>;
};

export default CitiesFilter;