import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Radio, RadioGroup } from "react-radio-group";
import { Panel, PanelBody, PanelHeader } from "../layout/panel/panel";
import Tooltip from "react-tooltip-lite";
import { renderThemeColour } from "../../utils/renderIcon";

const VisIndicators = (props) => {
  const {
    theme,
    themeIndicators,
    getCities,
    groupSelection,
    selectGrouping,
    setGrouping,
    setIndicator,
    currIndicator,
    user,
  } = props;
  const { name, id } = theme;

  const [selectedIndicator, setSelectedIndicator] = useState({});

  const Truncate = (str) => {
    return str.length > 50 ? str.substring(0, 50) + "..." : str;
  };

  const handleQuestionClick = (question) => {
    saveIndicator(selectedIndicator, question);
  };

  const handleClick = (indicator) => {
    if (indicator.indicator === selectedIndicator.indicator) {
      return;
    }

    setSelectedIndicator(indicator);
    if (indicator.questions.length > 1) {
      setIndicator({});
      return;
    }

    saveIndicator(indicator);
  };

  useEffect(() => {
    setSelectedIndicator({});
  }, [theme, themeIndicators]);

  const saveIndicator = (indicator, question) => {
    setIndicator({
      indicator_id: indicator.indicator,
      question_id: question?.id,
      question_name: question?.name,
      main_question: question?.main,
      indicator_type: indicator.indicator_type,
      indicator_name: indicator.indicator_name,
      indicator_vtype: indicator.value_type,
      iso_section: indicator.iso_section,
      theme: theme.name,
      vis_chart_note: question ? question.vis_chart_note : indicator.questions[0]?.vis_chart_note,
    });
    getCities(indicator.indicator, question?.id, groupSelection, user);

    if (indicator.indicator === 223 && selectGrouping === "age_tiers") {
      setGrouping("city");
    }
  };

  return (
    <Fragment>
      {themeIndicators.length !== 0 ? (
        <Panel
          theme="success"
          className="height-300 overflow-auto scrollbar valign-middle bg-wccd-darker"
        >
          <PanelHeader>
            <div className="text-center clearfix width-full">
              <div
                className="text-left f-s-20 p-t-20"
                style={{
                  color: renderThemeColour(name),
                }}
              >
                {name}
              </div>
            </div>
          </PanelHeader>
          <PanelBody className="bg-wccd-darker">
            <RadioGroup
              name={`text_value${id}`}
              key={id}
              selectedValue={selectedIndicator.indicator_id}
            >
              {themeIndicators.sort((a, b) => a.display_order - b.display_order).map((indicator) => {
                if (!indicator.indicator && indicator.indicator_id) {
                  indicator.indicator = indicator.indicator_id;
                }

                return (
                  <div
                    key={indicator.indicator ? indicator.indicator : 0}
                    className="pull-left pointer-cursor width-full b-b-1-c9 p-b-15 valign-middle"
                  >
                    <Tooltip
                      content={`${indicator.indicator_name}`}
                      direction="right"
                    >
                      <div className="radio radio-css  radio-success left">
                        <Radio
                          value={indicator.indicator}
                          name={`radio_css_inline_${indicator.indicator}`}
                          id={`inlineCssRadio1${indicator.indicator}`}
                          readOnly
                          onClick={() => handleClick(indicator)}
                        />
                        <label
                          htmlFor={`inlineCssRadio1${indicator.indicator}`}>
                          <div
                            className={
                              selectedIndicator.indicator === indicator.indicator
                                ? "f-s-10 pointer-cursor text-white"
                                : "f-s-10 pointer-cursor text-grey-lighter"
                            }
                            onClick={() => handleClick(indicator)}
                          >
                            {indicator.iso_section}
                            {" "}
                            {Truncate(
                              `${indicator.indicator_name} ${
                                indicator.indicator_type === "3"
                                  ? " (Profile Data)"
                                  : ""
                              }`,
                            )}
                          </div>
                        </label>
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </RadioGroup>
          </PanelBody>
        </Panel>
      ) : (
        // <Spinner />
        <div />
      )}

      {selectedIndicator.questions?.length > 1 && (
        <Panel
          theme="success"
          className="height-300 overflow-auto scrollbar valign-middle bg-wccd-darker"
        >
          <PanelHeader>
            <div className="text-center clearfix width-full">
              <div
                className="text-left f-s-20 p-t-20"
                style={{
                  color: renderThemeColour(name),
                }}
              >
                Questions
              </div>
            </div>
          </PanelHeader>
          <PanelBody className="bg-wccd-darker">
            <RadioGroup
              selectedValue={currIndicator.question_id}
            >
              {selectedIndicator.questions.map((question) => {
                const { id, name } = question;
                return (
                  <div
                    key={id}
                    className="pull-left pointer-cursor width-full b-b-1-c9 p-b-15 valign-middle"
                  >
                    <Tooltip
                      content={name}
                      direction="right"
                    >
                      <div className="radio radio-css  radio-success left">
                        <Radio
                          value={id}
                          name={`radio_css_inline_${id}`}
                          id={`inlineCssRadio1_${id}`}
                          readOnly
                          onClick={() => handleQuestionClick(question)}
                        />
                        <label
                          htmlFor={`inlineCssRadio1_${id}`}>
                          <div
                            className={
                              currIndicator.question_id === id
                                ? "f-s-10 pointer-cursor text-white"
                                : "f-s-10 pointer-cursor text-grey-lighter"
                            }
                            onClick={() => handleQuestionClick(question)}
                          >
                            {/*TODO question ids here*/}
                            {/* TODO: this is really not the way to do this... */}
                            {([443, 441, 514, 515].includes(id)) ? (
                              <i className="fa fa-long-arrow-alt-right" />
                            ) : (
                              ""
                            )}{" "}
                            {Truncate(name)}
                          </div>
                        </label>
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </RadioGroup>
          </PanelBody>
        </Panel>
      )}
    </Fragment>
  );
};
VisIndicators.propTypes = {
  themeIndicators: PropTypes.array,
  getCities: PropTypes.func,
  loading: PropTypes.bool,
};
export default VisIndicators;
