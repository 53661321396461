import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import UserForm from "./UserForm";

const UserAdd = ({ cities_list, addUser }) => {
  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    password: uuid.v4()
  });

  const onSubmit = async () => {
    setLoading(true);
    const success = await addUser(user);
    if (success) {
      setModalState(false);
    }

    setLoading(false);
  };

  const onSubmitAndNew = async () => {
    setLoading(true);
    const success = await addUser(user);
    if (success) {
      const {
        name,
        email,
        phone,
        ...rest
      } = user;

      setUser({
        ...rest,
        password: uuid.v4()
      })
    }

    setLoading(false);
  };

  useEffect(() => {
    setUser({
      password: uuid.v4()
    });
  }, [modalState]);

  const onChange = (key, value) => {
    setUser({
      ...user,
      [key]: value
    })
  };

  return (
    <Fragment>
      <div className='width-200'>
        <button
          className='btn bg-black-transparent-8 btn-lg m-r-5 m-b-5 width-200'
          onClick={() => setModalState(!modalState)}
        >
          <i className='fa fa-plus text-white m-r-5'> </i>
          <i className='fa fa-user text-white m-r-5'> </i>
        </button>
      </div>
      <Modal isOpen={modalState} toggle={() => setModalState(!modalState)}>
        <ModalHeader toggle={() => setModalState(!modalState)}>
          Add New User
        </ModalHeader>
        <ModalBody>
          <UserForm user={user} onChange={onChange} cities={cities_list}
                    loading={loading} />
        </ModalBody>
        <ModalFooter>
          <div className={"d-flex flex-row justify-content-between width-full"}>
            <button
              className='btn btn-white'
              onClick={() => setModalState(!modalState)}
            >
              Close
            </button>
            <div>
              <button className='btn btn-success m-r-10' onClick={onSubmit}>
                Save
              </button>
              <button className='btn btn-success' onClick={onSubmitAndNew}>
                Save and New
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
UserAdd.propTypes = {
  cities_list: PropTypes.array,
  addUser: PropTypes.func
};

export default UserAdd;
