import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CityWorkbookItem from './CityWorkbookItem';
import Spinner from '../layout/Spinner';

const CityWorkbookList = ({ cities, filtered, loading, isos }) => {
  if (cities !== null && cities.length === 0 && !loading) {
    return <h4>No data was loaded.</h4>;
  }

  return (
    <Fragment>
      {cities !== null && !loading ? (
        <div>
          {filtered !== null
            ? filtered.map(city => (
                <CityWorkbookItem
                  key={city.id}
                  city={city}
                  isos={isos}
                  loading={loading}
                />
              ))
            : cities.map(city => (
                <CityWorkbookItem
                  key={city.id}
                  city={city}
                  isos={isos}
                  loading={loading}
                />
              ))}
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};
CityWorkbookList.propTypes = {
  cities: PropTypes.array,
  isos: PropTypes.array,
  filter: PropTypes.array,
  loading: PropTypes.bool
};
export default CityWorkbookList;
