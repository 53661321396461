import React, { useState } from 'react';
import IsoQuestionItem from './IsoQuestionItem';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { questionTypes } from "../../utils/constants";

const IsoQuestion = ({ questions, setQuestion, deleteQuestion, setAlert }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState(null);

  const onDeleteQuestion = async (question) => {
    setShowDeleteModal(true);
    setDeleteModalData({ ...question });
  };

  return (
    <>
      {!!deleteModalData && (
        <Modal isOpen={showDeleteModal}>
          <ModalHeader>
            Are you sure you want to delete this question?
          </ModalHeader>
          <ModalBody>
            <div className="d-flex">
              <div className="text-dark h4 width-60">Id:</div>
              <div className="h4">{deleteModalData.id}</div>
            </div>
            <div className="d-flex">
              <div className="text-dark h4 width-60">Type:</div>
              <div className="h4">
                {questionTypes.find(type => type.value === deleteModalData.qtype).text}
              </div>
            </div>
            <div className="d-flex">
              <div className="text-dark h4 width-60">Code:</div>
              <div className="h4">{deleteModalData.code}</div>
            </div>
            <div className="font-weight-bold h4 p-t-20 text-danger">
              This cannot be undone! All of the question values will be deleted!
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className='btn btn-white'
              id='addwb_submit'
              onClick={() => {
                setDeleteModalData(null);
                setShowDeleteModal(false);
              }}
            >
              Close
            </button>
            <button
              className='btn btn-danger'
              onClick={async () => {
                if (await deleteQuestion(deleteModalData.id)) {
                  setAlert("Question deleted", "success");
                  setShowDeleteModal(false);
                  setDeleteModalData(null);
                } else {
                  setAlert("Error deleting the question", "danger");
                }
              }}
            >
              Delete
            </button>
          </ModalFooter>
        </Modal>
      )}
      {questions
        .map((question) => (
          <IsoQuestionItem
            setAlert={setAlert}
            question={question}
            setQuestion={setQuestion}
            onDeleteQuestion={onDeleteQuestion}
            key={question.id}
          />
        ))}
    </>
  );
};

export default IsoQuestion;
