import React, { Fragment, useContext, useEffect } from 'react';
import PageContext from '../../config/pageContext';
import AuthContext from '../../context/auth/authContext';
import LandingDash from '../landing/LandingDash';
import CityWorkbookState from '../../context/cityworkbook/CityWorkbookState';

const Landing = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { vis } = user;
  const pageContext = useContext(PageContext);
  const { handleSetPageSidebar, handleSetPageHeader } = pageContext;

  useEffect(() => {
    authContext.loadUser();
    handleSetPageSidebar(false);
    handleSetPageHeader(false);
    return () => {
      handleSetPageHeader(true);
      handleSetPageSidebar(true);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <CityWorkbookState>
        <LandingDash vis={vis} user={user} />
      </CityWorkbookState>
    </Fragment>
  );
};

export default Landing;
