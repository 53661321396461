import React, { useEffect, useContext } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import routes from './../../config/page-route.jsx';
import proutes from './../../config/page-proute.jsx';
import PageContext from './../../config/pageContext';
import PrivateRoute from './../../components/routing/PrivateRoute';
import { setTitle } from '../../utils/generalHelpers';
import AuthContext from '../../context/auth/authContext';
import Alerts from './../../components/layout/Alerts';

const Content = props => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;

  useEffect(() => {
    if (isAuthenticated && props.history.location.pathname === '/login') {
      props.history.push('/');
      setTitle(props.history.location.pathname, proutes);
    } else {
      setTitle(props.history.location.pathname, proutes);
    }
    return () => {
      props.history.listen(() => {
        setTitle(props.history.location.pathname, proutes);
      });
    };
  }, [isAuthenticated, props.history]);

  return (
    <PageContext.Consumer>
      {({ pageContentFullWidth, pageContentClass }) => (
        <div
          className={
            'content ' +
            (pageContentFullWidth
              ? 'content-full-width '
              : '' + pageContentClass)
          }
        >
          <Alerts />
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            {proutes.map((proute, index) => (
              <PrivateRoute
                key={index}
                path={proute.path}
                exact={proute.exact}
                component={proute.component}
              />
            ))}
          </Switch>
        </div>
      )}
    </PageContext.Consumer>
  );
};

export default withRouter(Content);
