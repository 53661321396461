import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Panel, PanelBody, PanelHeader } from "../../layout/panel/panel";
import IndicatorAuditParametersSwitch from "./IndicatorAuditParametersSwitch";
import Spinner from "../../layout/Spinner";

const IndicatorAuditParameters = (props) => {
  const {
    user,
    indicatorObject,
    loading,
    updateValue,
    indicatorStatus,
  } = props;

  return (
    <Fragment>
      <Panel theme="default" className="bg-silver-transparent-4">
        <PanelHeader>
          <span className="f-s-14"> Audit Questions </span>
        </PanelHeader>
        <PanelBody>
          <Fragment>
            {indicatorObject !== null && !loading ? (
              <div>
                {indicatorObject
                  .filter(question => question.is_visible)
                  .map(question => (
                    <IndicatorAuditParametersSwitch
                      key={question.id}
                      question={question}
                      updateValue={updateValue}
                      indicatorStatus={indicatorStatus}
                      user={user}
                    />
                  ))}
              </div>
            ) : (
              <Spinner />
            )}
          </Fragment>
        </PanelBody>
      </Panel>
    </Fragment>
  );
};

IndicatorAuditParameters.propTypes = {
  user: PropTypes.object,
  indicatorObject: PropTypes.array,
  indicatorStatus: PropTypes.array,
  updateValue: PropTypes.func,
  loading: PropTypes.bool,
};

export default IndicatorAuditParameters;
