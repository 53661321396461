import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import userFields from "./userFields";

const UserForm = ({ user, onChange, cities, editMode, loading }) => {
  const fields = Object.entries(userFields)
    .filter(([, value]) => editMode || !value.hideOnCreate)
    .map(([key, value]) => {
      let input;
      switch (value.type) {
        case "boolean":
          input = <input
            className="form-check-input"
            style={{
              marginLeft: 10
            }}
            type="checkbox"
            checked={!!user[key]}
            onClick={() => onChange(key, !user[key])}
            id={key}
            disabled={loading}
          />;
          break;
        case "select":
        case "multiselect":
          const options = Object.entries(value.options(cities) || {})
            .map(([value, label]) => ({ value, label }));

          input = <Select
            defaultValue={options.filter((item) =>
              (user[key] || []).includes(item.value)
            )}
            onChange={val => onChange(key, val.value || val.map(({ value }) => value))}
            options={options}
            name={key}
            isMulti={value.type === "multiselect"}
            isSearchable={value.type === "multiselect"}
            isDisabled={loading}
            // className='width-200'
          />;
          break;
        default:
          input = <input
            type='text'
            name={key}
            value={user[key] || ""}
            onChange={e => onChange(key, e.target.value)}
            className='form-control'
            disabled={loading}
          />;
      }

      return (
        <div className='form-group' key={key}>
          <label htmlFor={key}
                 style={{ marginBottom: 0 }}>{value.label}: </label>
          {input}
        </div>
      );
    });

  return (
    <form>
      {fields}
    </form>
  );
};

UserForm.propTypes = {
  user: PropTypes.object,
  cities: PropTypes.arrayOf(PropTypes.object),
  editMode: PropTypes.bool,
  loading: PropTypes.bool
};
export default UserForm;