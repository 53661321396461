const renderUploads = (uploadObjects) => {
  return uploadObjects.map((uploadObject) => {
    const items = uploadObject;
    //  var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    var patt1 = /\.([0-9a-z]+)(?:[?#]|$)/i;

    const renderType = type => {
      switch (type) {
        case "msg":
          return "fa fa-file-alt fa-2x";
        case "xlsx":
          return "fa fa-file-excel fa-2x";
        case "doc":
          return "fa fa-file-word fa-2x";
        case "docx":
          return "fa fa-file-word fa-2x";
        case "pdf":
          return "fa fa-file-pdf fa-2x";
        default:
          return "fa fa-file fa-2x";
      }
    };

    const renderFileName = filePath => {
      var filename = unescape(
        filePath
          .split("?")[0]
          .split("/")
          .pop(),
      );
      return filename;
    };

    var attachmentItem = {};
    attachmentItem["id"] = items.id;
    attachmentItem["date"] = items.uploaded_at;
    // attachmentItem["code"] = code;
    var filePath = items.upload;
    attachmentItem["file"] = renderFileName(filePath);
    attachmentItem["download"] = filePath;
    var type = items.upload.match(patt1)[1];
    attachmentItem["type"] = type;
    attachmentItem["icon"] = renderType(type);

    return attachmentItem;
  });
};

export default renderUploads;
