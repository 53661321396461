import React from 'react';

const IsoSelect = ({ isos, setIso }) => {
  const onChangeIso = (e) => {
    setIso(parseInt(e.target.value));
  };

  return (
    <div className='m-t-20 width-full'>
      <label htmlFor='isos'>
        Please select one of the ISO standards to start editing below:{' '}
      </label>
      <select
        className='form-control'
        onChange={onChangeIso}
        name='isos'
        id='isos'
        required
      >
        <option>Please select one of the options below:</option>
        {!!isos && isos.map((iso) => (
          <option key={iso.id} value={iso.id}>
            {iso.code} ({iso.year}) - {iso.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default IsoSelect;
