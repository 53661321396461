import React, { Fragment } from 'react';
import styled from "styled-components";

const Banner = styled.div`
  position: absolute;
  top: 170px;
  width: 100%;
  z-index: 999;
  left: 0;
  color: #fff;
  font-size: 30px;
  background: #FF5722;
  text-align: center;
  padding: 20px 0;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 998;
  width: 100%;
  height: 100%;
  background: #242a30cc;
  left: 0;
  top: 0;
`;

const WorkbookClosedBanner = ({ text }) =>
  <Fragment>
    <Overlay />
    <Banner>
      {text}
    </Banner>
  </Fragment>;

export default WorkbookClosedBanner;