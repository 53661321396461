import React, { useContext, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PageContext from '../../config/pageContext';
import IndicatorState from './../../context/indicator/IndicatorState';
import ValueState from './../../context/indicator/value/ValueState';
import AuthContext from '../../context/auth/authContext';
import IndicatorDash from '../indicator/dash/IndicatorDash';

const Indicator = props => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  const workbookId = props.match.params.workbookId;
  const indicatorId = props.match.params.indicatorId;
  const themeId = props.match.params.themeId;
  const isoId = props.match.params.isoId;

  useEffect(() => {
    authContext.loadUser();
    pageContext.handleSetPageSidebar(true);
    return () => {
      pageContext.handleSetPageSidebar(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <IndicatorState>
      <ValueState>
        <Fragment>
          <IndicatorDash
            workbookId={workbookId}
            indicatorId={indicatorId}
            isoId={isoId}
            themeId={themeId}
          />
        </Fragment>
      </ValueState>
    </IndicatorState>
  );
};
export default withRouter(Indicator);
