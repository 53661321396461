import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getWBStatusString } from '../../utils/generalHelpers';

const WorkbookGridHeader = ({ cityObject, isoLabel, workbookObject }) => {
  return (
    <Fragment>
      <ol className='breadcrumb pull-right'>
        <li className='breadcrumb-item'>{cityObject ? cityObject.name : ''}</li>

        {workbookObject ? (
          <li className='breadcrumb-item'>
            {isoLabel} / {workbookObject.year} /{' '}
            {getWBStatusString(workbookObject.status)}
          </li>
        ) : (
          ''
        )}
      </ol>
      <h1 className='page-header'>
        {cityObject.name} - {isoLabel}, {workbookObject.year}{' '}
        <small>Workbook Dashboard</small>
      </h1>
    </Fragment>
  );
};

WorkbookGridHeader.propTypes = {
  cityObject: PropTypes.object,
  isoLabel: PropTypes.string.isRequired,
  workbookObject: PropTypes.object
};

export default withRouter(WorkbookGridHeader);
