import React from 'react';

const WorkbookLegend = () => {
  return (
    <div className='widget-chart-info m-b-30'>
      <h4 className='widget-chart-info-title'>Legend</h4>
      <p className='widget-chart-info-desc'>Indicator Status</p>
      <div className='row'>
        <div className='col-6 my-1'>
          <span className='fa fa-pencil-alt fa-1x text-red' />
          <span className='f-s-12'> Draft</span>
        </div>
        <div className='col-6 my-1'>
          <span className='fa fa-eye fa-1x text-yellow' />
          <span className='f-s-12'> Submitted for WCCD Internal Review</span>
        </div>
        <div className='col-6 my-1'>
          <span className='fa fa-bullseye fa-1x text-orange' />
          <span className='f-s-12'> Submitted for Verification</span>
        </div>
        <div className='col-6 my-1'>
          <span className='fa fa-check fa-1x text-green' />
          <span className='f-s-12'> Verified</span>
        </div>
      </div>
    </div>
  );
};

export default WorkbookLegend;
