import React, { useReducer } from 'react';
import axios from 'axios';
import { baseUrl } from '../../config/const';
import VisContext from './visContext';
import visReducer from './visReducer';
import { setAuthToken } from '../../utils/generalHelpers';
import { renderMapData, updateFiltered, } from '../../utils/renderHelpers';

import {
  GET_CITIES,
  GET_INDICATORS,
  GET_THEMES,
  GET_YEAR_OVER_YEAR,
  SET_CITIES,
  SET_GROUP_DATA,
  SET_GROUP_SELECTION,
  SET_GROUPING,
  SET_INDICATOR,
  SET_LOADING,
  VIZ_ERROR,
} from '../types';

const VisualizationState = (props) => {
  const initialState = {
    selectGrouping: null,
    groupData: {},
    groupSelection: [],
    themes: null,
    indicatorCities: null,
    filteredCities: [],
    themeIndicators: {
      id: 0,
      name: '',
      inds: [],
    },
    error: null,
    currIndicator: {
      indicator_id: 0,
      indicator_name: '',
      indicator_vtype: '',
      iso_section: null,
      theme: null,
    },
    loading: true,
  };
  const [state, dispatch] = useReducer(visReducer, initialState);

  // Get all themes
  const getThemes = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/iso/theme/`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_THEMES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: VIZ_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Get all indicators
  const getIndicators = async (themeId, isoId) => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/vis/indicators/?theme=${themeId}&iso=${isoId}`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_INDICATORS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: VIZ_ERROR,
        payload: err.response.data,
      });
    }
  };

  const getIndicatorsByTheme = async (themeId, isoId) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    try {
      const res = await axios.get(
        `${baseUrl}/api/vis/indicators/?theme=${themeId}&iso=${isoId}`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_INDICATORS,
        payload: res.data
      });
      dispatch({
        type: GET_YEAR_OVER_YEAR,
        payload: [],
      });
      dispatch({
        type: GET_CITIES,
        payload: [],
        payload3: [],
      });
    } catch (err) {
      dispatch({
        type: VIZ_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Get year over year results for a single city
  const getYearOverYearData = async (indicatorId, cityId, questionId) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    try {
      let url = `${baseUrl}/api/vis/visualisations/?indicator=${indicatorId}&cities=${cityId}`;
      if (questionId) {
        url += "&question=" + questionId;
      }

      const res = await axios.get(url, setAuthToken(localStorage.token));

      dispatch({
        type: GET_YEAR_OVER_YEAR,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: VIZ_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Get city results per indicator
  const getCities = async (indicatorId, questionId, selected, user) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    try {
      let url = `${baseUrl}/api/vis/visualisations/?indicator=${indicatorId}&latest=true`;
      if (questionId) {
        url += "&question=" + questionId;
      }
      const res = await axios.get(
        url,
        setAuthToken(localStorage.token)
      );

      const cohorts = await axios.get(
        `${baseUrl}/api/vis/cohorts/`,
        setAuthToken(localStorage.token)
      );

      const rendered = renderMapData(res.data, cohorts.data, user);
      dispatch({
        type: GET_CITIES,
        payload: rendered,
        payload3: updateFiltered(rendered, selected),
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: VIZ_ERROR,
        payload: err.response?.data,
      });
    }
  };

  // Get city results per indicator
  const setCities = async (cities) => {
    try {
      dispatch({
        type: SET_CITIES,
        payload: cities,
      });
    } catch (err) {
      dispatch({
        type: VIZ_ERROR,
        payload: err.response.data,
      });
    }
  };

  const setIndicator = async (currIndicator) => {
    dispatch({
      type: SET_INDICATOR,
      payload: currIndicator,
    });
  };
  const setGrouping = async (selected) => {
    dispatch({
      type: SET_GROUPING,
      payload: selected,
    });
  };

  const setGroupSelection = async (selected) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    dispatch({
      type: SET_GROUP_SELECTION,
      payload: selected,
    });
  };

  const setGroupData = async (selected) => {
    dispatch({
      type: SET_LOADING,
      payload: true,
    });
    setTimeout(() => {
      dispatch({
        type: SET_GROUP_DATA,
        payload: selected,
      });
    }, 500);
  };
  return (
    <VisContext.Provider
      value={{
        themes: state.themes,
        loading: state.loading,
        themeIndicators: state.themeIndicators,
        currIndicator: state.currIndicator,
        indicatorCities: state.indicatorCities,
        filteredCities: state.filteredCities,
        selectGrouping: state.selectGrouping,
        groupData: state.groupData,
        groupSelection: state.groupSelection,
        yearOverYearData: state.yearOverYearData,
        error: state.error,
        setGrouping,
        getThemes,
        getIndicators,
        getIndicatorsByTheme,
        getCities,
        setCities,
        setIndicator,
        setGroupData,
        setGroupSelection,
        getYearOverYearData,
      }}
    >
      {props.children}
    </VisContext.Provider>
  );
};

export default VisualizationState;
