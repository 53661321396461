import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner2';
import { renderThemeColour, renderThemeIcon } from '../../utils/renderIcon';
import Tooltip from 'react-tooltip-lite';

const VisThemes = (props) => {
  const {
    themes,
    setSelectedTheme,
    setIndicator,
    filteredCities
  } = props;
  const [curTheme, setCurTheme] = useState();
  const Truncate = (str) => {
    return str.length > 15 ? str.substring(0, 10) + '...' : str;
  };
  const handleClick = (theme) => {
    setSelectedTheme(theme);
    setCurTheme(theme.id);
    if (filteredCities && filteredCities.length === 0) {
      setIndicator({
        indicator_id: 0,
        indicator_name: '',
        indicator_vtype: '',
        iso_section: null,
        theme: null,
      });
    }
  };
  return (
    <Fragment>
      {themes !== null ? (
        <div className='row'>
          <div className='col-sm-12 col-md'>
            {themes.map((theme) =>
              theme.id !== 6 ? (
                <div
                  key={theme.id}
                  id={theme.id}
                  className='pull-left m-10 pointer-cursor ro-vis-theme-item'
                  onClick={() => handleClick(theme)}
                >
                  {' '}
                  <Tooltip content={theme.name} direction='right'>
                    <div className='ro-vis-theme-item-icon'>
                      <i
                        className={`fa fa-${renderThemeIcon(
                          theme.name
                        )} fa-2x text-gray-darker`}
                        style={{
                          color:
                            curTheme === theme.id
                              ? renderThemeColour(theme.name)
                              : curTheme
                                ? '#ededed'
                                : renderThemeColour(theme.name),
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color:
                          curTheme === theme.id
                            ? renderThemeColour(theme.name)
                            : curTheme
                              ? '#ededed'
                              : renderThemeColour(theme.name),
                      }}
                      className='f-s-10 text-center'
                    >
                      {Truncate(theme.name)}
                    </div>
                  </Tooltip>
                </div>
              ) : (
                ''
              )
            )}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      {/* <div className='text-center width-full'>
        <div className='text-center f-s-20 text-black bold'>{themeTitle}</div>
      </div> */}
    </Fragment>
  );
};
VisThemes.propTypes = {
  themes: PropTypes.array,
  getIndicators: PropTypes.func,
  setIndicator: PropTypes.func,
  filteredCities: PropTypes.array,
};
export default VisThemes;
