import React, { useContext, Fragment } from 'react';
import WorkbookItem from '../cityworkbook/WorkbookItem';
import CityContext from '../../context/city/cityContext';
import Spinner from '../layout/Spinner';

const CityWorkbookInfo = () => {
  const cityContext = useContext(CityContext);
  const { city, loading } = cityContext;
  const { name, region, country, workbooks } = city;

  return (
    <Fragment>
      <div className='col-lg-3 col-md-6 col-sm-12 pull-right'>
        <div className='card border-0 bg-default text-dark text-truncate mb-3  height-250'>
          <div className='card-body'>
            <div className='d-flex align-items-center mb-1'>
              <h3 className='text-dark mb-0'>{name}</h3>
            </div>
            <div className='mb-4 text-grey'>
              <i className='fa fa-map-marker-alt'></i> {region}, {country}
            </div>
            <div className='m-r-15'>
              {workbooks !== null && !loading ? (
                <div>
                  {workbooks.map(workbook => (
                    <WorkbookItem key={workbook.id} workbook={workbook} />
                  ))}
                </div>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CityWorkbookInfo;
