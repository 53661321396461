import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from '../layout/Spinner2';

import { renderThemeColour } from '../../utils/renderIcon';
import dlcloud from '../../assets/img/icons/data-export.png';
import logowm from '../../assets/img/logos/WCCD_Vertical_White.png';
import brandlogo from '../../assets/img/logos/WCCD_Horizontal_White.png';
import canadian from '../../assets/img/logos/Canadian_Logo_white.png';
import menasa from '../../assets/img/logos/Dubai_WCCD_white.png';
import dubai from '../../assets/img/logos/Dubai_Council_Logo.png';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/dataviz';
import { drawAgeCohortLine } from "../../utils/renderHelpers";
import { addFootNotes } from "./utils";
// import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

// am4core.useTheme(am4themes_dataviz);
am4core.addLicense('CH200325900523135');

const Styles = styled.div`
  .amcharts-amexport-item {
    border: 0px solid #777;
    background: '../../assets/img/icons/data-export.png';
  }

  .amcharts-amexport-top .amcharts-amexport-item > .amcharts-amexport-menu {
    top: -30px !important;
    right: 2px;
    cursor: pointer;
  }
`;

const getMax = (filtered) => {
  let max = -99999;
  filtered.forEach(item => {
    max = item.main_value > max ? item.main_value : max;
  });
  if (max === 0) {
    return 1;
  }
  return max + Math.abs(max * 0.2);
};

const getMin = (filtered) => {
  let min = 99999;
  filtered.forEach(item => {
    min = item.main_value < min ? item.main_value : min;
  });
  if (min === 0) {
    return 0;
  }
  return min - Math.abs(min * 0.1);
};

const VisChartMapPlus = (props) => {
  const {
    isoName,
    indicatorCities,
    filteredCities,
    currIndicator,
    loading,
    user,
  } = props;
  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    let chart = am4core.create('chartdiv1', am4charts.XYChart);
    chart.maskBullets = false;
    chart.paddingTop = 30;
    chart.paddingBottom = 70;
    chart.cursor = new am4charts.XYCursor();
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.minZoomed = 20;
    chart.scrollbarY.exportable = false;
    chart.zoomOutButton.disabled = true;

    chart.data = filteredCities;

    // cap percentage type to 100%
    if (currIndicator.indicator_vtype === "%") {
      chart.data.forEach(data => {
        if (data.main_value > 100) {
          data.main_value = Math.min(data.main_value, 100);
          if (user.role === 1) {
            data.customColor = "#ff0000";
          }
        }
      });
    }

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'title';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10; // for label spacing
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.grid.template.strokeDasharray = '1,3';
    categoryAxis.renderer.labels.template.rotation = -75;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.fill = am4core.color('#fff');

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    drawAgeCohortLine(valueAxis, currIndicator.indicator_id);
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.minGridDistance = 20;
    // Set up axis title
    if (currIndicator.indicator_vtype && currIndicator.indicator_vtype !== ' ') {
      valueAxis.title.text = `(${currIndicator.indicator_vtype})`;
    }
    valueAxis.title.rotation = 0;
    valueAxis.title.align = 'center';
    valueAxis.title.valign = 'top';
    valueAxis.title.dy = 160;
    valueAxis.title.dx = 0;
    valueAxis.title.fontSize = 12;
    valueAxis.title.fill = am4core.color('#999');
    valueAxis.renderer.labels.template.fill = am4core.color('#fff');

    // valueAxis.title.fontWeight = 600;
    valueAxis.min = 0;
    // let axisBreak = valueAxis.axisBreaks.create();
    // axisBreak.startValue = 300;
    // axisBreak.endValue = 1200;
    // axisBreak.breakSize = 0.05;
    // valueAxis.renderer.ticks.template.length = 10;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = 'title';
    series.dataFields.valueY = 'main_value';
    series.dataFields.Label = 'label';
    series.tooltipText = `{Label}\n{valueY}${currIndicator.indicator_vtype}`;
    series.sequencedInterpolation = true;
    series.fillOpacity = 0;
    series.strokeOpacity = 1;
    series.strokeDashArray = '1,3';
    series.columns.template.width = 10;
    series.tooltip.pointerOrientation = 'horizontal';
    series.stroke = am4core.color('#08afdd');
    series.columns.template.propertyFields.stroke = "customColor";

    let title = chart.titles.create();
    if (filteredCities) {
      valueAxis.min = getMin(filteredCities);
      valueAxis.max = getMax(filteredCities);
      if (currIndicator.indicator_vtype === "%") {
        valueAxis.max = Math.min(valueAxis.max, 100);
      }

      if (currIndicator.indicator_type === '3') {
        title.text = `${isoName} Profile Data ${currIndicator.iso_section}`;
      } else {
        title.text = `${isoName} Indicator ${currIndicator.iso_section}`;
      }

      title.fontSize = 12;
      title.marginBottom = 50;
      title.align = 'left';
    }
    title.fill = am4core.color('white');

    let title2 = chart.titles.create();
    title2.text = currIndicator.indicator_name;
    if (currIndicator.question_name && !currIndicator.main_question) {
      title2.text += " - " + currIndicator.question_name;
    }
    title2.fontSize = 22;
    title2.marginBottom = 15;
    title2.align = 'left';
    title2.wrap = true;
    title2.maxWidth = 1000;
    let title3 = chart.titles.create();
    let title4 = chart.titles.create();
    title2.fill = am4core.color(renderThemeColour(currIndicator.theme));

    if (!indicatorCities) {
      title4.text = 'Welcome';
      title4.fontSize = 30;
      title4.marginBottom = 15;
      title4.align = 'left';
      title3.text = 'Please select a Theme and Indicator';
      title3.fontSize = 20;
      title3.marginBottom = 15;
      title3.align = 'left';
    }
    // switch between bullet and bar
    if (indicatorCities) {
      // let chartSwitch = chart.createChild(am4core.SwitchButton);
      // chartSwitch.align = 'right';
      // chartSwitch.y = 15;
      // chartSwitch.leftLabel.text = 'Bar';
      // chartSwitch.leftLabel.fill = am4core.color('#fff');
      // chartSwitch.rightLabel.fill = am4core.color('#fff');
      // chartSwitch.rightLabel.text = 'Bubble';
      // chartSwitch.verticalCenter = 'top';
      series.columns.template.propertyFields.fill = 'color';
      series.columns.template.fillOpacity = 8;
      series.columns.template.width = 10;
      // series.columns.template.fill = am4core.color('#fff');
      // series.columns.template.propertyFields.fill = 'color';
      //   // series.columns.template.stroke = am4core.color('#4ebcdb');

      //   chartSwitch.events.on('toggled', function () {
      //     if (chartSwitch.isActive) {
      //       series.columns.template.fillOpacity = 0.8;
      //       series.columns.template.width = 0.01;
      //       var bullet = series.bullets.create(am4charts.CircleBullet);
      //       // bullet.circle.propertyFields.fill = 'color';
      //       bullet.radius = 3;
      //       // bullet.propertyFields.fill = 'color';
      //       // bullet.fill = am4core.color('#000066');
      //       bullet.fill = am4core.color('#fff');

      //       bullet.stroke = am4core.color('#08afdd');
      //     } else {
      //       bullet.hide();
      //       series.columns.template.fillOpacity = 8;
      //       series.columns.template.width = 10;
      //       // series.columns.template.fill = am4core.color('#fff');
      //       // series.columns.template.propertyFields.fill = 'color';
      //       // series.columns.template.stroke = am4core.color('#4ebcdb');
      //     }
      //   });
    }
    series.columns.template.fillOpacity = 8;
    series.columns.template.width = 10;
    // function customizeGrip(grip) {
    //   grip.icon.disabled = true;
    //   // grip.background.fill = am4core.color('#c00');
    //   grip.background.fillOpacity = 0.5;
    // }

    // customizeGrip(chart.scrollbarY.startGrip);
    // customizeGrip(chart.scrollbarY.endGrip);

    // chart.events.on('ready', function () {
    //   valueAxis.zoomToValues(0, 119, true);
    // });

    const onExportEvents = [];
    addFootNotes(currIndicator, chart, onExportEvents);

    let watermark = new am4core.Image();
    watermark.href = logowm;
    chart.plotContainer.children.push(watermark);
    watermark.align = 'center';
    watermark.valign = 'top';
    watermark.opacity = 0.1;
    watermark.marginBottom = 35;
    watermark.width = 300;
    watermark.height = 300;

    let watermarkContainer = chart.createChild(am4core.Container);
    watermarkContainer.layout = "horizontal";
    watermarkContainer.height = 80;
    watermarkContainer.align = "center";

    let watermark1 = watermarkContainer.createChild(am4core.Image);
    watermark1.href = brandlogo;
    watermark1.opacity = 1;
    watermark1.width = 300;
    watermark1.height = 100;

    switch (user.vis) {
      case 2:
        let watermark2 = watermarkContainer.createChild(am4core.Image);
        watermark2.href = canadian;
        watermark2.opacity = 1;
        watermark2.width = 200;
        watermark2.height = 80;
        break;
      case 3:
        let watermark3 = watermarkContainer.createChild(am4core.Image);
        watermark3.href = menasa;
        watermark3.opacity = 1;
        watermark3.width = 200;
        watermark3.height = 80;

        let watermark4 = watermarkContainer.createChild(am4core.Image);
        watermark4.href = dubai;
        watermark4.opacity = 1;
        watermark4.width = 200;
        watermark4.height = 80;
        break;
      default:
        break;
    }
    // Add bullets
    let bullet = series.bullets.push(new am4charts.Bullet());
    let image = bullet.createChild(am4core.Image);
    image.horizontalCenter = 'middle';
    image.verticalCenter = 'bottom';
    image.dy = 10;
    image.y = am4core.percent(100);
    image.width = 20;
    image.propertyFields.href = 'bullet';
    image.tooltipText = series.columns.template.tooltipText;
    // image.propertyFields.fill = 'color';
    image.filters.push(new am4core.DropShadowFilter());
    if (filteredCities && filteredCities.length !== 0) {
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.menu.items = [
        {
          label: '...',
          menu: [
            { type: 'png', label: 'PNG' },
            { type: 'jpg', label: 'JPG' },
            { type: 'pdf', label: 'PDF' },
            { label: 'Print', type: 'print' },
          ],
        },
      ];
      chart.exporting.filePrefix = `WCCD - ${isoName} Indicator ${currIndicator.iso_section}`;
      chart.exporting.menu.items[0].icon = dlcloud;
      chart.exporting.menu.background = am4core.color('#f00', 0);
      chart.exporting.menu.align = 'right';
      chart.exporting.menu.verticalAlign = 'top';
      chart.exporting.events.on("exportstarted", ev => {
        onExportEvents.forEach(callable => callable());
      }, this);

      chart.exporting.getFormatOptions('pdf').addURL = false;
    }

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
    // eslint-disable-next-line
  }, [refresh, indicatorCities, currIndicator]);

  useEffect(() => {
    if (filteredCities?.length !== refresh.filteredCities) {
      setRefresh({
        ...refresh,
        filteredCities: filteredCities?.length
      });
    }
    // eslint-disable-next-line
  }, [filteredCities]);

  return (
    <Styles>
      <div>
        {filteredCities && filteredCities.length !== 0 && !loading ? (
          <div
            id='chartdiv1'
            style={{ width: '100%', height: '800px' }}
            className='bg-wccd-darker'
          />
        ) : loading ? (
          <Fragment>
            <div
              id='chartdiv1'
              className='hidden'
              style={{ width: '0%', height: '0px', hide: true }}
            />
            <Spinner />
          </Fragment>
        ) : (
          <div
            id='chartdiv1'
            className='hidden'
            style={{ width: '0%', height: '0px', hide: true }}
          />
        )}
      </div>
    </Styles>
  );
};

VisChartMapPlus.propTypes = {
  indicatorCities: PropTypes.array,
  loading: PropTypes.bool,
  // getIndicators: PropTypes.func
};

export default VisChartMapPlus;
