import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IndicatorMainValueQuestion from './IndicatorMainValueQuestion';
import IndicatorMainValueNumerator from './IndicatorMainValueNumerator';
import IndicatorMainValueDenominator from './IndicatorMainValueDenominator';
import IndicatorMainValueSource from './IndicatorMainValueSource';
import IndicatorMainValueParent from './IndicatorMainValueParent';
import Tooltip from 'react-tooltip-lite';

const IndicatorMainValueSwitch = (props) => {
  const {
    user,
    question,
    loading,
    indicatorStatus,
    indicatorValueTypeObject,
    updateAndGetChildIndicators,
    updateIndicatorStatus,
    resetTable,
    updateValue,
    setCurrentNum,
    setCurrentDen,
    setCalcNum,
    setCalcDen,
    currentNum,
    currentDen,
    setCurrentMainValue,
    childIndicators,
    calcNum,
    calcDen,
    setValueObject,
    isParent,
    hasButton,
    grabTotals,
    workbookId,
    indicatorId,
  } = props;
  const {
    text,
    requirements,
    min_text,
    max_text,
    qtype,
    has_table_data,
    table_columns,
    table_temp_data,
    info,
    other_info,
    has_aggregate,
    is_visible
  } = question;
  const current = question.value;
  const renderSwitch = (type) => {
    switch (type) {
      case 'Question':
        return (
          <div className='col-lg-12 text-inverse p-t-15 b-t-1 b-b-1 p-b-10'>
            <div>
              {requirements ? (
                <Tooltip
                  content={<div className='width-200'>{requirements}</div>}
                >
                  <div>
                    {text} <i className='fa fa-info-circle' />
                  </div>
                </Tooltip>
              ) : (
                <span className='f-w-400'>{text}</span>
              )}
            </div>
            <div className='m-b-20 m-t-20'>
              <Fragment>
                {current !== null ? (
                  <div>
                    {current.map((value) => (
                      <IndicatorMainValueQuestion
                        key={value.id}
                        value={value}
                        setCurrentMainValue={setCurrentMainValue}
                        calcNum={calcNum}
                        calcDen={calcDen}
                        updateValue={updateValue}
                        indicatorValueTypeObject={indicatorValueTypeObject}
                        indicatorStatus={indicatorStatus}
                        setValueObject={setValueObject}
                        user={user}
                        isVisible={is_visible}
                      />
                    ))}
                  </div>
                ) : (
                  <div>...loading</div>
                )}
              </Fragment>
            </div>
          </div>
        );
      case 'Numerator':
        return (
          <div className='col-lg-6 text-inverse pull-left p-t-25 height-200'>
            <div className='p-b-5'>
              <strong>Numerator</strong>
            </div>
            <div className='height-70'>
              <div>
                {requirements ? (
                  <Tooltip
                    content={<div className='width-200'>{requirements}</div>}
                  >
                    <div>
                      {text} <i className='fa fa-info-circle' />
                    </div>
                  </Tooltip>
                ) : (
                  <span className='f-w-400'>{text}</span>
                )}
              </div>
            </div>
            <Fragment>
              {current !== null ? (
                <div>
                  {current.map((value) => (
                    <IndicatorMainValueNumerator
                      key={value.id}
                      value={value}
                      indicatorStatus={indicatorStatus}
                      updateValue={updateValue}
                      setCurrentNum={setCurrentNum}
                      setCalcNum={setCalcNum}
                      currentNum={currentNum}
                      user={user}
                      has_table_data={has_table_data}
                      table_columns={table_columns}
                      table_temp_data={table_temp_data}
                      calcNum={calcNum}
                      isParent={isParent}
                      hasButton={hasButton}
                    />
                  ))}
                </div>
              ) : (
                <div>loading</div>
              )}
            </Fragment>
            {hasButton && indicatorStatus[0].status === 1 ? (
              <div className='p-t-10 pull-right m-b-10'>
                <button
                  className='btn btn-xs btn-success width-200 m-r-2'
                  onClick={() => grabTotals(calcNum, workbookId, indicatorId)}
                >
                  <span className='f-s-12'>use totals from table</span>
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        );
      case 'Denominator':
        return (
          <div className='col-lg-6 text-inverse pull-right p-t-25 height-200'>
            {' '}
            <div className='p-b-5'>
              <strong>Denominator</strong>
            </div>
            <div className='height-70'>
              <div>
                {requirements ? (
                  <Tooltip
                    content={<div className='width-200'>{requirements}</div>}
                  >
                    <div>
                      {text} <i className='fa fa-info-circle' />
                    </div>
                  </Tooltip>
                ) : (
                  <span className='f-w-400'>{text}</span>
                )}
              </div>
            </div>
            <Fragment>
              {current !== null ? (
                <div>
                  {current.map((value) => (
                    <IndicatorMainValueDenominator
                      key={value.id}
                      value={value}
                      loading={loading}
                      indicatorStatus={indicatorStatus}
                      updateValue={updateValue}
                      setCurrentDen={setCurrentDen}
                      setCalcDen={setCalcDen}
                      currentDen={currentDen}
                      user={user}
                    />
                  ))}
                </div>
              ) : (
                <div>loading</div>
              )}
            </Fragment>
          </div>
        );
      case 'Source':
        return (
          <div
            className='col-lg-12 b-t-1 p-b-5 p-t-10 text-inverse'>
            <div className='p-b-5'>
              <div className='p-b-5'>
                <strong>Source Documentation</strong>
              </div>
              {requirements ? (
                <Tooltip
                  content={<div className='width-200'>{requirements}</div>}
                >
                  <div>
                    {text} <i className='fa fa-info-circle' />
                  </div>
                </Tooltip>
              ) : (
                <div className='mb-3'>
                  <span className='f-w-400'>{text}</span>
                </div>
              )}
            </div>
            <Fragment>
              {current !== null ? (
                <div>
                  {current.map((value) => (
                    <IndicatorMainValueSource
                      key={value.id}
                      value={value}
                      indicatorStatus={indicatorStatus}
                      updateValue={updateValue}
                      user={user}
                    />
                  ))}
                </div>
              ) : (
                <div>loading</div>
              )}
            </Fragment>
          </div>
        );
      case 'Parent':
        return (
          <div
            className='overflow-auto scrollbar col-lg-12 p-b-0 p-t-5 text-inverse p-l-25 p-r-25'>
            <div className='width-full'>
              <IndicatorMainValueParent
                indicatorStatus={indicatorStatus}
                user={user}
                min_text={min_text}
                max_text={max_text}
                childIndicators={childIndicators}
                table_columns={table_columns}
                updateAndGetChildIndicators={updateAndGetChildIndicators}
                info={info}
                other_info={other_info}
                has_aggregate={has_aggregate}
                updateIndicatorStatus={updateIndicatorStatus}
                resetTable={resetTable}
              />
            </div>
          </div>
        );
      default:
        break;
    }
  };
  return <Fragment>{renderSwitch(qtype)}</Fragment>;
};

IndicatorMainValueSwitch.propTypes = {
  user: PropTypes.object,
  indicatorStatus: PropTypes.array,
  childIndicators: PropTypes.array,
  updateAndGetChildIndicators: PropTypes.func,
  loading: PropTypes.bool,
  isParent: PropTypes.bool,
  question: PropTypes.object,
  updateValue: PropTypes.func,
  setValueObject: PropTypes.func,
  setCurrentNum: PropTypes.func,
  setCalcNum: PropTypes.func,
  currentNum: PropTypes.object,
  currentDen: PropTypes.object,
  setCurrentMainValue: PropTypes.func,
  calcNum: PropTypes.object,
  calcDen: PropTypes.object,
  setCalcDen: PropTypes.func,
  grabTotals: PropTypes.func,
  indicatorValueTypeObject: PropTypes.object,
};

export default IndicatorMainValueSwitch;
