import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
} from '../../layout/panel/panel';
import IndicatorCommentsItem from './IndicatorCommentsItem';
import Spinner from '../../layout/Spinner';

const IndicatorComments = (props) => {
  const {
    workbookId,
    addComment,
    deleteComment,
    commentsObject,
    mainValueCode,
    mainValueID,
    loading,
    user,
  } = props;
  const now = new Date();

  const [comment, setComment] = useState({
    id: '',
    value: '',
    text: '',
    code: '',
    user: '',
    role: '',
    date: '',
  });
  const inputRef = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();
    inputRef.current.style.height = "35px";

    if (comment.text !== '') {
      addComment(comment, workbookId);
      setComment({
        ...comment,
        text: '',
      });
    }
  };

  const onChange = (e) => {
    e.target.style.height = "5px";
    e.target.style.height = (Math.max(e.target.scrollHeight, 35)) + "px";

    setComment({
      ...comment,
      text: e.target.value,
      code: mainValueCode,
      value: mainValueID,
      date: now,
    });
  };

  useEffect(() => {
    if (commentsObject !== null && commentsObject.length !== 0) {
      setComment({
        ...comment,
        value: commentsObject[0].value,
        code: commentsObject[0].code,
        user: user.name,
        role: user.role,
      });
    }
    // eslint-disable-next-line
  }, [user, commentsObject]);

  return (
    <Fragment>
      <Panel theme='default' className='bg-silver-transparent-4 height-lg'>
        <PanelHeader>
          <span className='f-s-14'> Comments</span>
          {/* <span className='badge badge-danger m-l-5 t-minus-7'>4</span> */}
        </PanelHeader>
        <PanelBody className='bg-gradient-info' style={{ paddingRight: 0 }}>
          <div className='overflow-auto scrollbar'
               style={{
                 maxHeight: '523px',
                 minHeight: '523px',
                 paddingRight: '15px'
               }}>
            <div className='chats'>
              {commentsObject !== null && !loading ? (
                commentsObject.map((comment) => (
                  <IndicatorCommentsItem
                    comment={comment}
                    deleteComment={deleteComment}
                    workbookId={workbookId}
                    user={user}
                    key={comment.id}
                  />
                ))
              ) : (
                <Spinner />
              )}
              {commentsObject !== null &&
              commentsObject.length === 0 &&
              !loading && (
                <div>
                  <p>
                    No comments found for this indicator. Please leave a comment
                    below.
                  </p>
                </div>
              )}
            </div>
          </div>
        </PanelBody>
        <PanelFooter>
          <form onSubmit={onSubmit}>
            <div className='input-group'>
              <textarea
                ref={inputRef}
                className='form-control flex-stretch'
                name='text'
                placeholder='Enter your message here.'
                maxLength={1000}
                style={{
                  width: '80%',
                  resize: "none",
                  overflow: "hidden"
                }}
                onChange={onChange}
                value={comment.text}
              />
              <span className='input-group-append'>
                <button className='btn btn-success' type='submit'>
                  <i className='fa fa-comments' />
                </button>
              </span>
            </div>
          </form>
        </PanelFooter>
      </Panel>
    </Fragment>
  );
};

IndicatorComments.propTypes = {
  workbookId: PropTypes.string,
  addComment: PropTypes.func,
  deleteComment: PropTypes.func,
  commentsObject: PropTypes.array,
  mainValueCode: PropTypes.string,
  mainValueID: PropTypes.number,
  user: PropTypes.object,
  loading: PropTypes.bool,
};

export default IndicatorComments;
