import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import PageContext from '../../../config/pageContext';
import SidebarNav from './SidebarNav';

const Sidebar = ({ isotheme, getIndicator }) => {
  return (
    <PageContext.Consumer>
      {({
        toggleSidebarMinify,
        toggleMobileSidebar,
        pageSidebarTransparent,
      }) => (
        <Fragment>
          <div
            id='sidebar'
            className={
              'sidebar ' + (pageSidebarTransparent ? 'sidebar-transparent' : '')
            }
          >
            <div className='overflow-auto scrollbar height-full'>
              {/* <SidebarProfile cityObject={cityObject} /> */}
              <SidebarNav isotheme={isotheme} getIndicator={getIndicator} />
              <Link
                to='/'
                className='sidebar-minify-btn'
                onClick={(e) => {
                  if (window.innerWidth < 767) {
                    toggleMobileSidebar(e);
                  } else {
                    toggleSidebarMinify(e);
                  }
                }}
              >
                <i className='fa fa-angle-double-left' />
              </Link>
            </div>
          </div>
          <div className='sidebar-bg' />
          <div className='sidebar-mobile-dismiss'>
            <Link
              to='#'
              className='sidebar-minify-btn'
              onClick={toggleMobileSidebar}
            >
              <i className='fa fa-angle-double-left' />
            </Link>
          </div>
        </Fragment>
      )}
    </PageContext.Consumer>
  );
};

Sidebar.propTypes = {
  cityObject: PropTypes.object,
  isotheme: PropTypes.array,
};

export default withRouter(Sidebar);
