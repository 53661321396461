import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const UserPaginate = ({ count, previous, next, loadUsers, searchData }) => {
  const maxPage = Math.ceil(count / 25);
  const linkArray = Array.from({ length: maxPage }, (_, i) => i + 1);

  const handleClick = item => {
    loadUsers({
      page: item
    });
  };

  return (
    <Pagination>
      <PaginationItem disabled={!previous}>
        <PaginationLink first href='#' onClick={() => handleClick(1)} />
      </PaginationItem>
      <PaginationItem disabled={!previous}>
        <PaginationLink previous href='#'
                        onClick={() => handleClick(Math.max(searchData.page - 1, 1))} />
      </PaginationItem>

      {linkArray.map(item => (
        <PaginationItem key={item} active={item === searchData.page}>
          <PaginationLink href='#' onClick={() => handleClick(item)}>
            {item}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={!next}>
        <PaginationLink next href='#'
                        onClick={() => handleClick(Math.min(searchData.page + 1, maxPage))} />
      </PaginationItem>
      <PaginationItem disabled={!next}>
        <PaginationLink last href='#' onClick={() => handleClick(maxPage)} />
      </PaginationItem>
    </Pagination>
  );
};

UserPaginate.propTypes = {
  count: PropTypes.number,
  previous: PropTypes.string,
  next: PropTypes.string,
  getUsers: PropTypes.func
};

export default UserPaginate;
