import {
  ADD_CITY,
  ADD_WORKBOOK,
  CLEAR_FILTER,
  CLEAR_RESULTS,
  CLEAR_WORKBOOK,
  CLEAR_WORKBOOKS,
  DELETE_WORKBOOK,
  FILTER_WORKBOOKS,
  FIND_CITY,
  FIND_REGION,
  GET_CITY,
  GET_COUNTRIES,
  GET_ISOS,
  GET_WORKBOOKS,
  SET_LOADING,
  SET_WORKBOOK,
  UPDATE_WORKBOOK,
  WORKBOOK_ERROR
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        result_loading: action.payload
      };
    case FIND_CITY:
      return {
        ...state,
        city_results: action.payload,
        result_loading: false
      };
    case ADD_CITY:
      return {
        ...state,
        loading: false
      };
    case FIND_REGION:
      return {
        ...state,
        region_results: action.payload,
        loading: false
      };
    case CLEAR_RESULTS:
      return {
        ...state,
        city_results: null
      };
    case GET_CITY:
      return {
        ...state,
        city_workbook: action.payload,
        loading: false
      };
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
        loading: false
      };
    case GET_ISOS:
      return {
        ...state,
        isos: action.payload,
        loading: false
      };
    case GET_WORKBOOKS:
      return {
        ...state,
        cities: action.payload,
        loading: false
      };
    case ADD_WORKBOOK:
      return {
        ...state,
        cities: action.payload,
        loading: false
      };
    case UPDATE_WORKBOOK:
      const city = state.cities.find(city => city.id === action.payload.city);
      if (city) {
        city.workbooks = city.workbooks.map(workbook => workbook.id === action.payload.id ? action.payload : workbook);
      }

      return {
        ...state,
        cities: state.cities,
        loading: false
      };
    case DELETE_WORKBOOK:
      return {
        ...state,
        cities: state.cities.filter(city => city.id !== action.payload),
        loading: false
      };
    case CLEAR_WORKBOOKS:
      return {
        ...state,
        cities: null,
        filtered: null,
        error: null,
        current: null
      };
    case SET_WORKBOOK:
      return {
        ...state,
        current: action.payload
      };
    case CLEAR_WORKBOOK:
      return {
        ...state,
        current: null
      };
    case FILTER_WORKBOOKS:
      const regex = new RegExp(`${action.payload}`, 'gi');

      return {
        ...state,
        filtered: (state.cities || []).filter(city => {
          return (city.name_ascii && city.name_ascii.match(regex))
            || city.name.match(regex)
            || city.country.match(regex);
        })
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null
      };
    case WORKBOOK_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
