import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Linkify from 'react-linkify';
import { getRole, getStyle } from '../../../utils/generalHelpers';

const IndicatorCommentsItem = (props) => {
  const {
    comment,
    workbookId,
    user,
    deleteComment,
  } = props;
  const { text, date, code, role, id, user: commentUserName } = comment;

  const onDelete = () => {
    deleteComment(id, workbookId);
  };

  const getVisible = () => {
    let visible;
    if ((user.role === 1 && moment(date).add(60, "days").isAfter(moment())) || (commentUserName === user.name && moment(date).add(14, "days").isAfter(moment()))) {
      visible = true;
    }

    return visible ? "" : "invisible";
  };

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target='_blank' rel='noopener noreferrer'>
      {text}
    </a>
  );

  return (
    <div className={user.name === comment.user ? `right` : `left`}>
      <span className='date-time'>{moment(date).format('lll')}</span>
      <span className='name left'>
        {user.role === 1 || user.name === comment.user
          ? comment.user
          : ''}
        <span
          className={`label label-${getStyle(role)}`}
          style={{ textTransform: 'uppercase' }}
        >
          {getRole(role)}
        </span>
      </span>
      <div className='image image-icon text-grey-darker'>
        <i className='fa fa-user fa-3x' />
      </div>
      <div className='message m-b-20 p-b-15 text-wrap'>
        <Linkify componentDecorator={componentDecorator}> {text} </Linkify>
        <span className='text-grey-lighter'> - {code}</span>
        <button
          className={`btn btn-link btn-sm f-s-9 ${getVisible()}`}
          onClick={onDelete}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

IndicatorCommentsItem.propTypes = {
  workbookId: PropTypes.string,
  comment: PropTypes.object,
  user: PropTypes.object,
  deleteComment: PropTypes.func,
};

export default IndicatorCommentsItem;
