import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { isDisabled } from '../../../utils/generalHelpers';

function IndicatorMainValueParentChildRatio(props) {
  const {
    updateAndGetChildIndicators,
    user,
    indicatorStatus,
    question,
    is_totals,
    is_ratio,
  } = props;
  const [valueState, setValueState] = useState({});
  const [roleIdState, setRoleIdState] = useState(0);
  const [statusState, setStatusState] = useState(0);

  const onBlur = () => {
    if (valueState !== question.value[0]) {
      updateAndGetChildIndicators(
        indicatorStatus[0].indicator,
        indicatorStatus[0].workbook,
        valueState
      );
    }
  };

  useEffect(() => {
    setRoleIdState(user.role);
    const roleId = user.role;
    if (roleId !== 0) {
      setRoleIdState(roleId);
    }
    const status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      setStatusState(indicatorStatus[0].status);
    }
    setValueState(question.value[0]);
  }, [user, indicatorStatus, question]);

  return (
    <div className='col-lg-2'>
      {question.qtype === 'Question' ? (
        <Fragment>
          <div className='width-100'>
            <div className='width-80'>
              <div
                className={`${
                  question.qtype === 'Numerator' ||
                  question.qtype === 'Question'
                    ? ' width-40'
                    : ' width-80'
                }  float-left text-center`}
              >
                <NumberFormat
                  className={`form-control${
                    question.qtype === 'Numerator' ||
                    question.qtype === 'Question' ||
                    is_totals ||
                    is_ratio
                      ? '-plaintext f-s-14 '
                      : ''
                  } f-w-500  text-center`}
                  thousandSeparator=','
                  decimalSeparator='.'
                  displayType={
                    question.qtype === 'Numerator' ||
                    question.qtype === 'Question' ||
                    is_totals ||
                    is_ratio
                      ? 'text'
                      : 'input'
                  }
                  decimalScale='2'
                  disabled={isDisabled(statusState, roleIdState)}
                  name={question.id}
                  key={valueState.id}
                  value={valueState.main_value}
                  onBlur={onBlur}
                  placeholder='0.0'
                  onValueChange={(values) => {
                    let val = values.floatValue;
                    if (val === undefined) {
                      val = null;
                    }

                    setValueState({
                      ...valueState,
                      main_value: val,
                      calc_value: val,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        ''
      )}
    </div>
  );
}

IndicatorMainValueParentChildRatio.propTypes = {
  user: PropTypes.object,
  indicatorStatus: PropTypes.array,
  question: PropTypes.object,
  updateAndGetChildIndicators: PropTypes.func,
};
export default IndicatorMainValueParentChildRatio;
