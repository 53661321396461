import React, { Fragment, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CityWorkbookList from '../cityworkbook/CityWorkbookList';
import CityWorkbookHeader from '../cityworkbook/CityWorkbookHeader';
import CityWorkbookContext from '../../context/cityworkbook/cityWorkbookContext';
import AuthContext from '../../context/auth/authContext';

const CityWorkbookDash = (props) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const cityWorkbookContext = useContext(CityWorkbookContext);
  const {
    cities,
    isos,
    filtered,
    getWorkbooks,
    getCity,
    addWorkbook,
    getIsos,
    filterCities,
    countries,
    getCountries,
    getRegion,
    clearFilter,
    findCity,
    addCity,
    clearResults,
    city_workbook,
    city_results,
    loading,
    result_loading,
    region_results,
  } = cityWorkbookContext;

  const isDone = (cw) => {
    if (cw !== null) {
      props.history.push(
        `/iso/${cw.workbooks[0].iso}/workbook/${cw.workbooks[0].id}`
      );
    }
  };

  useEffect(() => {
    if (
      (user.role === 4 && user.cities.length === 1) ||
      (user.role === 5 && user.cities.length === 1)
    ) {
      getCity(user.cities[0]);
      if (city_workbook) {
        isDone(city_workbook);
      }
    } else {
      getWorkbooks();
      getIsos();
      getCountries();
    }
    // eslint-disable-next-line
  }, [city_workbook]);

  return (
    <Fragment>
      <CityWorkbookHeader
        filtered={filtered}
        filterCities={filterCities}
        clearFilter={clearFilter}
        isos={isos}
        cities={cities}
        countries={countries}
        addWorkbook={addWorkbook}
        findCity={findCity}
        addCity={addCity}
        city_results={city_results}
        result_loading={result_loading}
        clearResults={clearResults}
        getRegion={getRegion}
        region_results={region_results}
        user={user}
      />
      <CityWorkbookList
        cities={cities}
        filtered={filtered}
        loading={loading}
        isos={isos}
      />
    </Fragment>
  );
};
export default withRouter(CityWorkbookDash);
