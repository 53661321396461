import React, { useContext, useEffect, Fragment } from 'react';
import PageContext from '../../config/pageContext';
import AuthContext from '../../context/auth/authContext';
import MapState from '../../context/map/MapState';
import MapCanvas from './../../components/location/map';

const Map = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.handleSetPageSidebar(false);
    // eslint-disable-next-line
    return () => {
      pageContext.handleSetPageSidebar(true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <MapState>
        <MapCanvas />
      </MapState>
    </Fragment>
  );
};
export default Map;
