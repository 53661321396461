export const renderThemeIcon = (themeName) => {
  switch (themeName) {
    case 'Economy':
      return 'fa fa-chart-area';
    case 'Education':
      return 'fa fa-graduation-cap';
    case 'Energy':
      return 'fa fa-bolt';
    case 'Environment':
      return 'fa fa-leaf';
    case 'Finance':
      return 'fa fa-money-bill-alt';
    case 'Fire and Emergency Response':
      return 'fa fa-fire';
    case 'Governance':
      return 'fa fa-university';
    case 'Health':
      return 'fa fa-medkit';
    case 'Population And Social Condition':
      return 'fa fa-users';
    case 'Recreation':
      return 'fa fa-swimmer';
    case 'Safety':
      return 'fa fa-lock';
    case 'Shelter':
      return 'fa fa-bed';
    case 'Housing (Shelter)':
      return 'fa fa-home';
    case 'Solid Waste':
      return 'fa fa-recycle';
    case 'Sport And Culture':
      return 'fa fa-futbol';
    case 'Telecommunication and Innovation':
      return 'fa fa-signal';
    case 'Transportation':
      return 'fa fa-subway';
    case 'Urban Planning':
      return 'fa fa-map';
    case 'Urban/Local Agriculture And Food Security':
      return 'fa fa-tractor';
    case 'Wastewater':
      return 'fa fa-shower';
    case 'Water':
      return 'fa fa-tint';
    case 'Profile Indicators':
      return 'fa fa-list';
    default:
      return 'fa fa-th';
  }
};

export const renderThemeColour = (themeName) => {
  switch (themeName) {
    case 'Economy':
      return '#e65485';
    case 'Education':
      return '#77db0e';
    case 'Energy':
      return '#edc500';
    case 'Environment':
      return '#3fad2a';
    case 'Finance':
      return '#e97854';
    case 'Fire and Emergency Response':
      return '#00f485';
    case 'Governance':
      return '#00f485';
    case 'Health':
      return '#ed3000';
    case 'Population And Social Condition':
      return '#b18978';
    case 'Recreation':
      return '#8facbc';
    case 'Safety':
      return '#ba17a0';
    case 'Shelter':
      return '#b52477';
    case 'Housing (Shelter)':
      return '#70e7de';
    case 'Solid Waste':
      return '#b52477';
    case 'Sport And Culture':
      return '#f4a60e';
    case 'Telecommunication and Innovation':
      return '#df8fe9';
    case 'Transportation':
      return '#b54354';
    case 'Urban Planning':
      return '#ef5f22';
    case 'Urban/Local Agriculture And Food Security':
      return '#a2d45e';
    case 'Wastewater':
      return '#42e74d';
    case 'Water':
      return '#54d3fe';
    case 'Profile Indicators':
      return '#6b9199';
    default:
      return '#b54354v';
  }
};
