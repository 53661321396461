import React, { useContext, useEffect, Fragment } from 'react';
import PageContext from '../../config/pageContext';
import UserDash from '../user/UserDash';
import AuthContext from '../../context/auth/authContext';
import UserState from './../../context/user/UserState';

const Users = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.handleSetPageSidebar(false);
    // eslint-disable-next-line
    return () => {
      pageContext.handleSetPageSidebar(true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <UserState>
        <UserDash />
      </UserState>
    </Fragment>
  );
};

export default Users;
