import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isDisabled, yearsList } from '../../../utils/generalHelpers';
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorAuditParametersYear = ({
  user,
  value,
  indicatorStatus,
  updateValue
}) => {
  const [yearState, setYearState] = useState(value);
  const { id, text_value } = yearState;

  const onChange = e => {
    updateValue({ ...yearState, text_value: e.target.value });
    setYearState({ ...yearState, text_value: e.target.value });
  };

  if (indicatorStatus) {
    var status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      status = indicatorStatus[0].status;
    }
  }

  const years = yearsList();

  var roleId = user.role;

  return (
    <div className='input-group m-b-6'>
      <select
        disabled={isDisabled(status, roleId)}
        className='form-control'
        value={text_value}
        id={id}
        onChange={onChange}
      >
        {years.map(year => (
          <option key={year}>{year}</option>
        ))}
      </select>


      <div style={{ transform: "translate(0, 5px)", display: "inline-block" }}>
        <BackInTime
          disabled={(indicatorStatus[0].status !== 1)}
          user={user}
          id={value?.id}
          setValue={(value) => {
            updateValue({ ...yearState, text_value: value });
            setYearState({ ...yearState, text_value: value });
          }}
        />
      </div>
    </div>
  );
};
IndicatorAuditParametersYear.propTypes = {
  user: PropTypes.object,
  value: PropTypes.object,
  updateValue: PropTypes.func,
  indicatorStatus: PropTypes.array
};
export default IndicatorAuditParametersYear;
