import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';
import { isWorkbookClosed } from "../../utils/generalHelpers";

const format = (num) => {
  const numParts = num
    .toLocaleString(undefined, { maximumFractionDigits: 2 })
    .split('.');
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return numParts.join('.');
};

const getGroup = (indicator, v) => {
  function r(v, min, max) {
    return v > min && v < max;
  }

  function ageCohort(value, percentage) {
    if (value === 0) return "";
    return (value < percentage ? "< " : ">= ") + percentage + "% of total population";
  }

  let g;
  const n = undefined;
  switch (indicator) {
    case 143: // Land Area
    case 122:
      r(v, 0, 51)
        ? (g = '1 km² - 50 km²')
        : r(v, 50, 101)
          ? (g = '51 km² - 100 km²')
          : r(v, 100, 501)
            ? (g = '101 km² - 500 km²')
            : r(v, 500, 1001)
              ? (g = '501 km² - 1,000 km²')
              : r(v, 1000, 5001)
                ? (g = '1,001 km² - 5,000 km²')
                : r(v, 5000, 10001)
                  ? (g = '5,001 km² - 10,000 km²')
                  : r(v, 10000, 1000000000)
                    ? (g = '10,001 km² +')
                    : (g = n);
      return g;
    case 445: // "Total City Population"
    case 121:
      r(v, 0, 50001)
        ? (g = '1 -  50,000')
        : r(v, 50000, 100001)
          ? (g = '50,001 - 100,000')
          : r(v, 100000, 250001)
            ? (g = '100,001 - 250,000')
            : r(v, 250000, 500001)
              ? (g = '250,001 - 500,000')
              : r(v, 500000, 750001)
                ? (g = '500,001 - 750,000')
                : r(v, 750000, 1000001)
                  ? (g = '750,001 - 1,000,000')
                  : r(v, 1000000, 5000001)
                    ? (g = '1,000,001 - 5,000,000')
                    : r(v, 5000000, 10000001)
                      ? (g = '5,000,001 - 10,000,000')
                      : r(v, 10000000, 100000001)
                        ? (g = '10,000,001 +')
                        : (g = n);
      return g;
    case 271: // "Population density"
    case 123:
      r(v, 0, 501)
        ? (g = '1/km² - 500/km²')
        : r(v, 500, 1001)
          ? (g = '501/km² - 1,000/km²')
          : r(v, 1000, 2501)
            ? (g = '1,001/km² - 2,500/km²')
            : r(v, 2500, 5001)
              ? (g = '2,501/km² - 5,000/km²')
              : r(v, 5000, 10001)
                ? (g = '5,001/km² - 10,000/km²')
                : r(v, 10000, 20001)
                  ? (g = '10,001/km² - 20,000/km²')
                  : r(v, 20000, 2000000)
                    ? (g = '20,001/km² +')
                    : (g = n);
      return g;
    case 139: // "Country’s GDP per capita"
    case 124:
      r(v, 0, 1001)
        ? (g = '$1 USD - $1,000 USD')
        : r(v, 1000, 5001)
          ? (g = '$1,001 USD - $5,000 USD')
          : r(v, 5000, 12001)
            ? (g = '$5,001 USD - $12,000 USD')
            : r(v, 12000, 30001)
              ? (g = '$12,001 USD - $30,000 USD')
              : r(v, 30000, 3000000)
                ? (g = '$30,001 USD +')
                : (g = n);
      return g;
    case 197: // "Gross capital budget per capita"
    case 130:
      r(v, 0, 100)
        ? (g = '$1 USD - $100 USD/capita')
        : r(v, 100, 501)
          ? (g = '$101 USD - $500 USD/capita')
          : r(v, 500, 1001)
            ? (g = '$501 USD - $1,000 USD/capita')
            : r(v, 1000, 2501)
              ? (g = '$1,001 USD - $2,500 USD/capita')
              : r(v, 2500, 250000)
                ? (g = '$2,501 USD/capita + ')
                : (g = n);
      return g;
    case 196: // "Gross capital budget per capita"
    case 128:
      r(v, 0, 100)
        ? (g = '$1 USD - $100 USD/capita')
        : r(v, 100, 501)
          ? (g = '$101 USD - $500 USD/capita')
          : r(v, 500, 1001)
            ? (g = '$501 USD - $1,000 USD/capita')
            : r(v, 1000, 2501)
              ? (g = '$1,001 USD - $2,500 USD/capita')
              : r(v, 2500, 5001)
                ? (g = '$2,501 USD - $5,000 USD/capita')
                : r(v, 5000, 5000000)
                  ? (g = '$5,001 USD/capita +')
                  : (g = n);
      return g;
    case 585: // Youthful City (ages 0-24)
      return ageCohort(v, 35);
    case 443: // Child population aged 0-14
      return ageCohort(v, 20);
    case 441: // 15-24
      return ageCohort(v, 15);
    case 444: // 25-64
      return ageCohort(v, 55);
    case 514: // 25-49
      return ageCohort(v, 35);
    case 515: // 50-64
      return ageCohort(v, 20);
    case 442: // 65+
      return ageCohort(v, 20);
    default:
      break;
  }
};

const PeerItem = (props) => {
  const {
    indicator,
    mainValue,
    marginLeft = 0,
    link = undefined
  } = props;

  const content =
    <div className={"d-flex justify-content-between"}>
      <span
        className='text-white-transparent-9 f-s-12 font-weight-bold'
      >
        {indicator.label}
      </span>
      <span className='text-aqua-lighter font-weight-bold'>
        {mainValue ? format(mainValue) : ""}
        {' '}
        {mainValue ? (
            <span className='f-s-10'>
            {indicator.indicatorvaluetype}
          </span>
          ) :
          <span className='text-orange-darker'>{link ? "add +" : "N/A"}</span>}
      </span>
    </div>

  const inner = !link ? content : <Link to={link}>{content}</Link>;

  return (
    <div className='m-b-5' style={{ marginLeft }}>
      {indicator.name ? (
        <Tooltip
          direction='left'
          content={
            <Fragment>
              <div className='width-500'>
                <div
                  className='tip-heading text-white b-b-1 font-weight-bold f-s-14 d-flex justify-content-between'>
                  <div>
                    {`${indicator.name} `}
                  </div>
                  <span className='text-white-transparent-5'>
                    {getGroup(indicator.id, mainValue) || ""}
                  </span>
                </div>
                <div className='font-weight-bold m-b-5 m-t-5'>
                  {indicator.general}
                </div>
                <span>{indicator.data_interpretations}</span>
              </div>
            </Fragment>
          }
        >
          {inner}
        </Tooltip>
      ) : (inner)}
    </div>
  );
};

const WorkbookPeer = (props) => {
  const {
    peerIndicatorsObject,
    workbookObject,
    user,
    climate,
    country,
    region
  } = props;

  return (
    <div className='widget-chart-info m-b-30'>
      <div
        className={"d-flex justify-content-between text-white-transparent-9 m-b-10 f-s-14 font-weight-bold"}>
        <span>{"City Profile"}</span>
        <span>{"Value"}</span>
      </div>
      {peerIndicatorsObject.reduce((acc, indicator) => {
        const ageCohort = indicator.parent_id === 223; // Age cohorts are special...
        const subAgeCohort = [443, 441, 514, 515].includes(indicator.id);
        const margin = ageCohort ? subAgeCohort ? 20 : 10 : 0;
        const linkIndicatorId = ageCohort ? indicator.parent_id : indicator.id;
        const link = `/iso/${workbookObject.iso}/workbook/${workbookObject.id}/theme/${indicator.theme}/indicator/${linkIndicatorId}/`;

        const mainValue = (indicator.questions[0].value[0] || {}).main_value;
        acc.push(<PeerItem
          key={indicator.id}
          indicator={indicator}
          mainValue={mainValue}
          marginLeft={margin}
          link={isWorkbookClosed(user.role, workbookObject.status) ? null : link}
        />);

        // Need to insert climate, country and region right after GDP
        if (indicator.id === 139) {
          acc.push(<PeerItem
            key={"climate"}
            indicator={{ label: "Climate Type" }}
            mainValue={climate} />);
          acc.push(<PeerItem
            key={"country"}
            indicator={{ label: "Country" }}
            mainValue={country} />);
          acc.push(<PeerItem
            key={"region"}
            indicator={{ label: "Region" }}
            mainValue={region} />);
        }

        // Insert Age Cohort divider
        if (indicator.id === 197) {
          acc.push(
            <div
              key={"ageCohorts"}
              className='text-white-transparent-9 f-s-12 font-weight-bold m-b-5'>
              Age Cohorts
            </div>
          );
        }
        return acc;
      }, [])}

      <h4 className='widget-chart-info-title p-t-20'>NOTE: These are Required
        Profile Indicators</h4>
      <p className='widget-chart-info-desc'>
        These will be automatically populated as your city workbooks are
        completed. Please review and ensure all are completed before submitting
        the workbook for verification. These Profile Indicators/Statistics are
        needed to ensure accurate peer groupings for your city’s comparative
        graphs in the WCCD Visualization Portal.
      </p>
      <hr />
    </div>
  );
};

export default WorkbookPeer;
