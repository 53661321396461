import React, { useContext, useEffect } from 'react';
import IsoSelect from './IsoSelect';
import Sidebar from './sidebar/Sidebar';
import IsoContext from '../../context/iso/isoContext';
import IsoIndicator from './IsoIndicator';
import AlertContext from "../../context/alert/alertContext";

const IsoDash = ({ isoId }) => {
  const isoContext = useContext(IsoContext);
  const { setAlert } = useContext(AlertContext);

  const {
    getIsos,
    getIsoIndicators,
    isos,
    indicators,
    setIso,
    curr_iso,
    curr_ind,
    childIndicators,
    types,
    getIndicator,
    getUnitList,
    updateIndicator,
    updateQuestion,
    createQuestion,
    deleteQuestion
  } = isoContext;

  useEffect(() => {
    getIsos();
    getUnitList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (curr_iso) {
      getIsoIndicators(curr_iso);
    }
    // eslint-disable-next-line
  }, [curr_iso]);

  return (
    <div>
      {!!(curr_iso && indicators) && (
        <Sidebar isotheme={indicators} getIndicator={getIndicator} />
      )}
      {!!isos && <IsoSelect isos={isos} setIso={setIso} />}
      <hr />
      {!!curr_ind && (
        <IsoIndicator
          setAlert={setAlert}
          curr_ind={curr_ind}
          childIndicators={childIndicators}
          getIndicator={getIndicator}
          getUnitList={getUnitList}
          types={types}
          updateIndicator={async (indicator) => {
            const indName = indicator.iso_section || indicator.label;

            if (await updateIndicator(indicator)) {
              getIsoIndicators(curr_iso); // Update the sidebar

              setAlert("Changes for indicator " + indName + " were saved", "success");
            } else {
              setAlert("Error saving changes for indicator " + indName, "danger");
            }
          }}
          updateQuestion={updateQuestion}
          createQuestion={createQuestion}
          deleteQuestion={deleteQuestion}
        />
      )}
    </div>
  );
};

export default IsoDash;
