import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// import DropdownNotification from './dropdown/notification.jsx';
import DropdownProfile from './dropdown/profile.jsx';

const Header = () => {
  const location = useLocation();
  const visPortal = location.pathname === "/vis";

  return (
    <div id='header' className='header navbar-inverse'
         style={{ background: visPortal ? "#1a1839" : null }}>
      <div className='navbar-header pull-left flex-stretch'>
        <Link to='/' className='navbar-brand' />
      </div>
      <div className='navbar-nav pull-right'>
        {/* <DropdownNotification /> */}
        <DropdownProfile visPortal={visPortal}/>
      </div>
    </div>
  );
};

export default Header;
