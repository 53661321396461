import {
  CREATE_QUESTION,
  DELETE_QUESTION,
  GET_CHILD_INDICATORS,
  GET_INDICATOR,
  GET_INDICATORS,
  GET_ISOS,
  GET_TYPES,
  ISO_ERROR,
  SET_ISO,
  UPDATE_INDICATOR,
  UPDATE_QUESTION,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_ISOS:
      return {
        ...state,
        isos: action.payload,
        loading: false,
      };
    case SET_ISO:
      return {
        ...state,
        curr_iso: action.payload,
        loading: false,
      };
    case GET_INDICATORS:
      return {
        ...state,
        indicators: action.payload,
        loading: false,
      };
    case GET_INDICATOR:
      return {
        ...state,
        curr_ind: action.payload,
        loading: false,
      };
    case GET_TYPES:
      return {
        ...state,
        types: action.payload,
        loading: false,
      };
    case ISO_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_INDICATOR:
      return {
        ...state,
        curr_ind: {
          ...action.payload,
          questions: state.curr_ind.questions
        }
      };
    case UPDATE_QUESTION:
      const questions = state.curr_ind.questions.reduce((acc, stateQuestion) => {
        if (stateQuestion.id === action.payload.id) {
          return [...acc, action.payload];
        }

        return [...acc, stateQuestion];
      }, []);

      return {
        ...state,
        curr_ind: {
          ...state.curr_ind,
          questions
        }
      };
    case CREATE_QUESTION:
      return {
        ...state,
        curr_ind: {
          ...state.curr_ind,
          questions: [...state.curr_ind.questions, action.payload]
        }
      };
    case DELETE_QUESTION:
      return {
        ...state,
        curr_ind: {
          ...state.curr_ind,
          questions: state.curr_ind.questions
            .filter(q => q.id !== action.payload.id)
        }
      };
    case GET_CHILD_INDICATORS:
      return {
        ...state,
        childIndicators: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
